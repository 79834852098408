import { Flex, Modal } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import ImageUpload from "../ImageUpload";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSemAnalysisDataClear,
  fetchSemAnalysisDataRequest,
} from "src/store/actions/semAnalysis";
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";
import { imageAnalysisTypesTranslationMapper } from "../../SEMAnalysisWrapper";

const NewSingleImageAnalysisModal = ({
  showSingleImageAnalysisModal,
  setShowSingleImageAnalysisModal,
  analysisType,
}: any) => {
  const [t] = useTranslate();
  const [image, setImage] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();
  const { semAnalysisDataStatus } = useSelector(
    (state: any) => state.semAnalysis
  );

  useEffect(() => {
    if (semAnalysisDataStatus === AsyncStates.SUCCESS) {
      setShowSingleImageAnalysisModal(false);
      dispatch(fetchSemAnalysisDataClear());
    }
  }, [dispatch, semAnalysisDataStatus, setShowSingleImageAnalysisModal]);

  useEffect(() => {
    if (file) {
      const reader: any = new FileReader();
      reader.onload = (event: any) => {
        setImage(event?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleNewAnalysis = useCallback(() => {
    if (!file) return;

    const formData = new FormData();
    formData.append("sem_file", file, file?.name);
    formData.append("analysis_type", analysisType);

    dispatch(fetchSemAnalysisDataRequest(formData));
    setFile(null);
  }, [dispatch, file, analysisType]);

  return (
    <Modal
      title={<h3>{t("common.New")} {imageAnalysisTypesTranslationMapper(analysisType, t)}</h3>}
      open={showSingleImageAnalysisModal}
      onCancel={() => setShowSingleImageAnalysisModal(false)}
      footer={
        image && (
          <StyledButton
            type="primary"
            size="middle"
            onClick={() => handleNewAnalysis()}
            loading={semAnalysisDataStatus === AsyncStates.LOADING}
          >
            {t("sem.runAnalysis")}
          </StyledButton>
        )
      }
    >
      <Flex>
        <div style={{ flex: 1 }}>
          {!image ? (
            <ImageUpload setFile={setFile} />
          ) : (
            <img src={image} style={{ maxWidth: "100%" }} alt="" />
          )}
        </div>
      </Flex>
    </Modal>
  );
};

export default NewSingleImageAnalysisModal;

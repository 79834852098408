import { Input, Modal } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

export function CreateGroupModal({
  createGroupModal,
  setCreateGroupModal,
  handleCreateGroup,
}: any) {
  const [t] = useTranslate();
  const handleOk = () => {
    const input: any = document.getElementById("group-name");
    handleCreateGroup(input?.value);
    setCreateGroupModal(false);
  };

  return (
    <Modal
      title={<h3>{t("sem.createNewGroup")}</h3>}
      open={createGroupModal}
      onCancel={() => setCreateGroupModal(false)}
      footer={
        <>
          <StyledButton
            size="middle"
            onClick={() => setCreateGroupModal(false)}
          >
            {t("common.cancel")}
          </StyledButton>
          <StyledButton type="primary" size="middle" onClick={() => handleOk()}>
            {t("common.Ok")}
          </StyledButton>
        </>
      }
    >
      <Input id="group-name" type="text" placeholder={t("sem.groupName")} />
    </Modal>
  );
}

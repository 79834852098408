import { StoreState } from "src/store/configureStore";
import BboxTable from "../../Shared/BboxTable";
import KDEChart from "../../Shared/KDEChart";
import MaskTable from "../../Shared/MaskTable";
import PointsTable from "../../Shared/PointsTable";
import { useSelector } from "react-redux";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    showFixedArea: boolean;
    handleChartSelection : (eventData: any) => void;
    selections: any[];
};

const FlowabilityAnalysisAttributes = ({
    showFixedArea,
    selections,
    handleChartSelection
}: TProps) => {
    const [t] = useTranslate();
    const { semAnalysisMaskData, semAnalysisMaskHeightWidth } =
        useSelector((state: StoreState) => state.semAnalysis);

    return (
        <>
            {showFixedArea ? <BboxTable /> : (
                <>
                    {semAnalysisMaskData?.mask_results?.length ? (
                        <KDEChart
                            data={semAnalysisMaskData?.mask_results}
                            unit={semAnalysisMaskData?.scale.replace(/[0-9.]/g, "")}
                            selections={selections}
                            handleChartSelection={handleChartSelection}
                            showSelections={true}
                        />
                    ) : null}
                    <PointsTable data={semAnalysisMaskHeightWidth} />
                    <h3>{t("sem.totalMasks")} {"(" + semAnalysisMaskData?.mask_results?.length + ")"}</h3>
                    <MaskTable data={semAnalysisMaskData} />
                </>
            )}
        </>
    );
};

export default FlowabilityAnalysisAttributes;

import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, Flex, Typography } from "antd";
import { MenuProps } from "antd/lib";
import { formatTimestamp } from "src/utils/general/time";
import useTranslate from "src/utils/useTranslate";
import { imageAnalysisTypesTranslationMapper } from "../SEMAnalysisWrapper";
const { Text } = Typography;

const ThumbnailCard = ({
    item,
    handleCardClick,
    selectable = false,
    setSelectedIds,
    selectedIds = [],
    handleDelete
}: any) => {
    const [t] = useTranslate();

    const items: MenuProps["items"] = [
        {
            key: "delete",
            label: (
                <>
                    <DeleteOutlined /> {t("common.delete")}
                </>
            ),
            onClick: (e) => {
                e.domEvent.stopPropagation();
            },
            danger: true,
        },
    ];

    return (
        <div
            key={item.file_id}
            className="thumb-card"
            onClick={() => handleCardClick({ fileId: item.file_id })}
        >
            <div className="img-container">
                <img src={item.thumbnail_url} alt="thumbnail" />
                {selectable && (
                    <div className="selection">
                        <Checkbox
                            checked={selectedIds.includes(item.file_id)}
                            onClick={(e => e.stopPropagation())}
                            onChange={(e) => {
                                if (setSelectedIds) {
                                    if (e.target.checked)
                                        setSelectedIds((prev: string[]) => [...prev, item.file_id]);
                                    else
                                        setSelectedIds((prev: any) =>
                                            prev.filter((p: string) => p !== item.file_id)
                                        );
                                }
                            }}
                        />
                    </div>
                )}
            </div>
            <Flex align="start" justify="space-between">
                <div className="info">
                    <div>
                        <Text className="file-name" ellipsis={{ tooltip: item.file_name }}>
                            {item.file_name}
                        </Text>
                    </div>
                    <div>{imageAnalysisTypesTranslationMapper(item.analysis_type, t)}</div>
                    <div>{formatTimestamp(item.created_at)}</div>
                </div>
                {!selectable && <div className="dropdown">
                    <Dropdown
                        menu={{
                            items,
                            onClick: () =>
                                handleDelete && handleDelete({ fileId: item.file_id }),
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                    >
                        <MoreOutlined
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </Dropdown>
                </div>}
            </Flex>
        </div>
    );
};

export default ThumbnailCard;

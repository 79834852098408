import { CloudUploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload, message } from "antd";
import { FC } from "react";
import useTranslate from "src/utils/useTranslate";

const { Dragger } = Upload;

const ImageUpload: FC<any> = ({ setFile, title, ...uploadProps }: any) => {
  const [t] = useTranslate();
  const props: UploadProps = {
    name: "file",
    accept: "image/png, image/jpeg",
    customRequest: () => { },
    showUploadList: false,
    beforeUpload: (_, fileList) => {
      for (let i = 0; i < fileList.length; i++) {
        const currentFile = fileList[i];
        const isImgTypeOk = ["image/jpeg", "image/png"].includes(currentFile.type);
        if (!isImgTypeOk) {
          message.error(`${t("sem.imageUploadError")}`);
        }
        if (!isImgTypeOk) return false;
      }

      return true;
    },
    fileList: [],
    onChange: ({ file }) => {
      setFile(file.originFileObj);
    },
    ...uploadProps
  };

  return (
    <Dragger className="sem-analysis-image-upload" {...props}>
      <CloudUploadOutlined className="icon" />
      <h4>{title}</h4>
      <p>{t("sem.dragImageToUpload")}</p>
    </Dragger>
  );
};

export default ImageUpload;

import {
  createIngredientApi,
  createIngredientFamilyApi,
  createIngredientFromTrialApi,
  createInventoryMethodApi,
  createMethodParameterApi,
  createPropertyApi,
  createSupplierApi,
  deleteIngredientApi,
  deleteIngredientFamilyApi,
  deleteInventoryMethodApi,
  deleteMethodParameterApi,
  deletePropertyApi,
  deleteSupplierApi,
  getIngredientDetailsApi,
  getIngredientFamilyApi,
  getIngredientFamilyListApi,
  getIngredientsApis,
  getIngredientsMeta,
  getIngredientsSearchApis,
  getInventoryMethodsLookupApi,
  getInventoryMethodsApi,
  getPropertiesApi,
  getSupplierDetailsApi,
  getSuppliersApi,
  resetDataSmilesUploadApi,
  smilesFileUploadApi,
  updateIngredientApi,
  updateIngredientFamilyApi,
  updateInventoryMethodApi,
  updateMethodParameterApi,
  updatePropertyApi,
  updateSupplierApi,
  viewIngredientUsedInWOApi,
  addPropertyToIngredientApi,
  getIngredientPropertyValuesApi,
  getPropertyMethodsListApi,
  addPropertyValueToPropertyApi,
  updatePropertyValueToPropertyApi,
  getIngredientPropertiesDetailsApi,
  getIngredientPropertyApi,
  getPropertyMethodsApi,
  deleteIngredientPropertyApi,
  deleteIngredientPropertyValueApi,
  getIngredientAttachmentsApi,
  addIngredientAttachmentApi,
  editIngredientAttachmentApi,
  deleteIngredientAttachmentApi,
  getIngredientGradesApi,
  getInventoryPreferencesApi,
  updateInventoryPreferencesApi,
} from "src/services/inventory/v2";
import performApiCallSaga, { performApiCallSagaV2 } from "./utils";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  addPropertyToIngredientFailure,
  addPropertyToIngredientRequest,
  addPropertyToIngredientSuccess,
  addPropertyValueToPropertyFailure,
  addPropertyValueToPropertyRequest,
  addPropertyValueToPropertySuccess,
  createIngredientFailure,
  createIngredientFamilyFailure,
  createIngredientFamilyRequest,
  createIngredientFamilySuccess,
  createIngredientFromTrialFailure,
  createIngredientFromTrialRequest,
  createIngredientFromTrialSuccess,
  createIngredientRequest,
  createIngredientSuccess,
  createInventoryMethodFailure,
  createInventoryMethodRequest,
  createInventoryMethodSuccess,
  createInventoryPropertyFailure,
  createInventoryPropertyRequest,
  createInventoryPropertySuccess,
  createMethodParameterFailure,
  createMethodParameterRequest,
  createMethodParameterSuccess,
  createSupplierFailure,
  createSupplierRequest,
  createSupplierSuccess,
  deleteIngredientFailure,
  deleteIngredientFamilyFailure,
  deleteIngredientFamilyRequest,
  deleteIngredientFamilySuccess,
  deleteIngredientPropertyFailure,
  deleteIngredientPropertyRequest,
  deleteIngredientPropertySuccess,
  deleteIngredientPropertyValueFailure,
  deleteIngredientPropertyValueRequest,
  deleteIngredientPropertyValueSuccess,
  deleteIngredientRequest,
  deleteIngredientSuccess,
  deleteInventoryMethodFailure,
  deleteInventoryMethodRequest,
  deleteInventoryMethodSuccess,
  deleteInventoryPropertyRequest,
  deleteInventoryPropertySuccess,
  deleteMethodParameterFailure,
  deleteMethodParameterRequest,
  deleteMethodParameterSuccess,
  deleteSupplierFailure,
  deleteSupplierRequest,
  deleteSupplierSuccess,
  getIngredientDetailsFailure,
  getIngredientDetailsRequest,
  getIngredientDetailsSuccess,
  getIngredientFamilyFailure,
  getIngredientFamilyListFailure,
  getIngredientFamilyListRequest,
  getIngredientFamilyListSuccess,
  getIngredientFamilyRequest,
  getIngredientFamilySuccess,
  getIngredientPropertiesDetailsFailure,
  getIngredientPropertiesDetailsRequest,
  getIngredientPropertiesDetailsSuccess,
  getIngredientPropertiesFailure,
  getIngredientPropertiesRequest,
  getIngredientPropertiesSuccess,
  getIngredientPropertyValuesFailure,
  getIngredientPropertyValuesRequest,
  getIngredientPropertyValuesSuccess,
  getIngredientUsedInWoFailure,
  getIngredientUsedInWoRequest,
  getIngredientUsedInWoSuccess,
  getIngredientsFailure,
  getIngredientsMetaFailure,
  getIngredientsMetaRequest,
  getIngredientsMetaSuccess,
  getIngredientsRequest,
  getIngredientsSearchRequest,
  getIngredientsSuccess,
  getInventoryMethodsFailure,
  getInventoryMethodsLookupFailure,
  getInventoryMethodsLookupRequest,
  getInventoryMethodsLookupSuccess,
  getInventoryMethodsRequest,
  getInventoryMethodsSuccess,
  getInventoryPropertiesFailure,
  getInventoryPropertiesRequest,
  getInventoryPropertiesSuccess,
  getPropertyMethodsFailure,
  getPropertyMethodsRequest,
  getPropertyMethodsSuccess,
  getPropertyMethodsListFailure,
  getPropertyMethodsListRequest,
  getPropertyMethodsListSuccess,
  getSupplierDetailsFailure,
  getSupplierDetailsRequest,
  getSupplierDetailsSuccess,
  getSuppliersFailure,
  getSuppliersRequest,
  getSuppliersSuccess,
  resetDataSmilesFileFailure,
  resetDataSmilesFileRequest,
  resetDataSmilesFileSuccess,
  updateIngredientFailure,
  updateIngredientFamilyFailure,
  updateIngredientFamilyRequest,
  updateIngredientFamilySuccess,
  updateIngredientRequest,
  updateIngredientSuccess,
  updateInventoryMethodFailure,
  updateInventoryMethodRequest,
  updateInventoryMethodSuccess,
  updateInventoryPropertyFailure,
  updateInventoryPropertyRequest,
  updateInventoryPropertySuccess,
  updateMethodParameterFailure,
  updateMethodParameterRequest,
  updateMethodParameterSuccess,
  updatePropertyValueToPropertyFailure,
  updatePropertyValueToPropertyRequest,
  updatePropertyValueToPropertySuccess,
  updateSupplierFailure,
  updateSupplierRequest,
  updateSupplierSuccess,
  uploadSmilesFileFailure,
  uploadSmilesFileRequest,
  uploadSmilesFileSuccess,
  addIngredientAttachmentRequest,
  editIngredientAttachmentRequest,
  deleteIngredientAttachmentRequest,
  addIngredientAttachmentSuccess,
  addIngredientAttachmentFailure,
  deleteIngredientAttachmentSuccess,
  deleteIngredientAttachmentFailure,
  getIngredientAttachmentsSuccess,
  getIngredientAttachmentsFailure,
  getIngredientAttachmentsRequest,
  editIngredientAttachmentSuccess,
  editIngredientAttachmentFailure,
  getIngredientGradesRequest,
  getIngredientGradesSuccess,
  getIngredientGradesFailure,
  inventoryPreferencesRequest,
  updateInventoryPreferencesRequest,
  inventoryPreferencesSuccess,
  inventoryPreferencesFailure,
  updateInventoryPreferencesSuccess,
  updateInventoryPreferencesFailure,
} from "../actions/inventoryV2";
import { displayNamesRequest } from "../actions/displayNames";
import { notification, message } from "antd";
import { messages } from "src/utils/hooks";
import jwtManager from "src/utils/jwtManager";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import { APIError } from "src/typings";
import { history } from "src";

function* getSuppliersSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getSuppliersApi,
    getSuppliersSuccess,
    getSuppliersFailure,
    payload
  );
}

function* createSupplierSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    createSupplierApi,
    createSupplierSuccess,
    createSupplierFailure,
    payload
  );
}

function* getSupplierDetailsSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getSupplierDetailsApi(payload),
    getSupplierDetailsSuccess,
    getSupplierDetailsFailure,
    payload
  );
}

function* updateSupplierSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    updateSupplierApi(payload),
    updateSupplierSuccess,
    updateSupplierFailure,
    payload
  );
}

function* deleteSupplierSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    deleteSupplierApi(payload),
    deleteSupplierSuccess,
    deleteSupplierFailure,
    payload
  );
}

function* getInventoryPropertiesSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getPropertiesApi(payload),
    getInventoryPropertiesSuccess,
    getInventoryPropertiesFailure,
    payload
  );
}

function* createInventoryPropertySaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    createPropertyApi(payload),
    createInventoryPropertySuccess,
    createInventoryPropertyFailure,
    payload
  );
}

function* updateInventoryPropertySaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    updatePropertyApi(payload),
    updateInventoryPropertySuccess,
    updateInventoryPropertyFailure,
    payload
  );
}

function* deleteInventoryPropertySaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    deletePropertyApi(payload),
    deleteInventoryPropertySuccess,
    updateInventoryPropertyFailure,
    payload
  );
}

function* getInventoryMethodsLookupSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getInventoryMethodsLookupApi,
    getInventoryMethodsLookupSuccess,
    getInventoryMethodsLookupFailure,
    payload
  );
}

function* getInventoryMethodsSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getInventoryMethodsApi(payload),
    getInventoryMethodsSuccess,
    getInventoryMethodsFailure,
    payload
  );
}

function* createInventoryMethodSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    createInventoryMethodApi(payload),
    createInventoryMethodSuccess,
    createInventoryMethodFailure,
    payload
  );
}

function* updateInventoryMethodSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    updateInventoryMethodApi(payload),
    updateInventoryMethodSuccess,
    updateInventoryMethodFailure,
    payload
  );
}

function* deleteInventoryMethodSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    deleteInventoryMethodApi(payload),
    deleteInventoryMethodSuccess,
    deleteInventoryMethodFailure,
    payload
  );
}

function* createMethodParameterSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    createMethodParameterApi(payload),
    createMethodParameterSuccess,
    createMethodParameterFailure,
    payload
  );
}

function* updateMethodParameterSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    updateMethodParameterApi(payload),
    updateMethodParameterSuccess,
    updateMethodParameterFailure,
    payload
  );
}

function* deleteMethodParameterSaga({
  payload,
}: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    deleteMethodParameterApi(payload),
    deleteMethodParameterSuccess,
    deleteMethodParameterFailure,
    payload
  );
}

function* getIngredientsSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getIngredientsApis,
    getIngredientsSuccess,
    getIngredientsFailure,
    payload
  );
}

function* getIngredientsSearchSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getIngredientsSearchApis,
    getIngredientsSuccess,
    getIngredientsFailure,
    payload
  );
}

function* getIngredientsMetaSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getIngredientsMeta,
    getIngredientsMetaSuccess,
    getIngredientsMetaFailure,
    payload
  );
}

function* getIngredientDetailsSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getIngredientDetailsApi(payload),
    getIngredientDetailsSuccess,
    getIngredientDetailsFailure,
    payload
  );
}

function* createIngredientSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(createIngredientApi, payload, headers);
    if (status === "Success") {
      yield put(createIngredientSuccess(data));
      yield put(displayNamesRequest({ backgroundFetch: true }));
    } else {
      yield put(createIngredientFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(createIngredientFailure());
  }
}

function* createIngredientFromTrialSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(createIngredientFromTrialApi, payload, headers);
    if (status === "Success") {
      yield put(createIngredientFromTrialSuccess(data));
      yield put(displayNamesRequest({ backgroundFetch: true }));
      notification.success({ message })
    } else {
      yield put(createIngredientFromTrialFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(createIngredientFromTrialFailure());
  }
}

function* updateIngredientSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    updateIngredientApi(payload),
    updateIngredientSuccess,
    updateIngredientFailure,
    payload
  );
}

function* deleteIngredientSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    deleteIngredientApi(payload),
    deleteIngredientSuccess,
    deleteIngredientFailure,
    payload
  );
}

function* smilesFileUploadSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const defaultHeaders = yield select((state) => state.defaultHeaders);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };

    const { data: { status, message, data } } = yield call(smilesFileUploadApi(payload.inventory_id), payload.formData, headers);

    if (status === "Success") {
      yield put(uploadSmilesFileSuccess(data))
      const ingredientDetail = yield select((state) => state.inventoryV2.getIngredientDetails.data);
      if (ingredientDetail.inventory_id === payload?.inventory_id) {
        yield put(getIngredientDetailsSuccess({ ...ingredientDetail, chemical_information: data?.chemical_information ?? null }));
      }
      notification.success({ message })
    }
    else {
      yield put(uploadSmilesFileFailure());
      notification.error({ message });
    }
  } catch (err) {
    if (err instanceof Error) {
      const error = err as unknown as APIError
      yield put(uploadSmilesFileFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
      message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
    } else {
      const error = err as APIError
      if (error.response.status === 500) {
        yield put(uploadSmilesFileFailure(messages[ln].internal_server_error))
        message.error(messages[ln].internal_server_error)
      } else {
        yield put(uploadSmilesFileFailure(error.response.data.detail ?? messages[ln].internal_server_error))
        message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
      }
    }
  }
}

function* resetDataSmilesFileSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const defaultHeaders = yield select((state) => state.defaultHeaders);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { status, message } } = yield call(resetDataSmilesUploadApi(payload.inventory_id), {}, headers);

    if (status === "Success") {
      yield put(resetDataSmilesFileSuccess())
      notification.success({ message })
    }
    else {
      yield put(resetDataSmilesFileFailure(message ?? messages[ln].internal_server_error));
      notification.error({ message: message ?? messages[ln].internal_server_error });
    }
  } catch (err) {
    if (err instanceof Error) {
      const error = err as unknown as APIError
      yield put(resetDataSmilesFileFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
      message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
    } else {
      const error = err as APIError
      if (error.response.status === 500) {
        yield put(resetDataSmilesFileFailure(messages[ln].internal_server_error))
        message.error(messages[ln].internal_server_error)
      } else {
        yield put(resetDataSmilesFileFailure(error.response.data.detail ?? messages[ln].internal_server_error))
        message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
      }
    }
  }
}

function* getIngredientUsedInWoSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(viewIngredientUsedInWOApi(payload.inventory_id), undefined, headers);
    if (status === "Success") {
      yield put(getIngredientUsedInWoSuccess({ [payload.inventory_id]: data }));
    } else {
      yield put(getIngredientUsedInWoFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientUsedInWoFailure(messages[ln].internal_server_error));
  }
}

function* getIngredientFamilyListSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getIngredientFamilyListApi(), undefined, headers);
    if (status === "Success") {
      yield put(getIngredientFamilyListSuccess(data));
    } else {
      yield put(getIngredientFamilyListFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientFamilyListFailure(messages[ln].internal_server_error));
  }
}

function* getIngredientFamilySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getIngredientFamilyApi(payload.family_id), undefined, headers);
    if (status === "Success") {
      yield put(getIngredientFamilySuccess(data));
    } else {
      yield put(getIngredientFamilyFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientFamilyFailure(messages[ln].internal_server_error));
  }
}

function* updateIngredientFamilySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(updateIngredientFamilyApi(payload.family_id), payload, headers);
    if (status === "Success") {
      yield put(updateIngredientFamilySuccess(data));
      yield put(getIngredientFamilyListRequest())
    } else {
      yield put(updateIngredientFamilyFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(updateIngredientFamilyFailure(messages[ln].internal_server_error));
  }
}

function* deleteIngredientFamilySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(deleteIngredientFamilyApi(payload.family_id), undefined, headers);
    if (status === "Success") {
      yield put(deleteIngredientFamilySuccess(payload.family_id));
      yield call(history.push, "/inventory/family");
    } else {
      yield put(deleteIngredientFamilyFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(deleteIngredientFamilyFailure(messages[ln].internal_server_error));
  }
}


function* createIngredientFamilySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(createIngredientFamilyApi(), payload, headers);
    if (status === "Success") {
      yield put(createIngredientFamilySuccess(data));
      yield put(getIngredientFamilyListRequest())
    } else {
      yield put(createIngredientFamilyFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(createIngredientFamilyFailure(messages[ln].internal_server_error));
  }
}


function* getIngredientPropertiesSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getIngredientPropertyApi(payload.inventory_id), undefined, headers);
    if (status === "Success") {
      const propertyData = {
        inventory_id: payload.inventory_id,
        data: data ?? [],
      }
      yield put(getIngredientPropertiesSuccess(propertyData));
    } else {
      const propertyErrorData = {
        inventory_id: payload.inventory_id,
        message: message,
      }
      yield put(getIngredientPropertiesFailure(propertyErrorData));
      notification.error({ message });
    }
  } catch (error) {
    const propertyErrorData = {
      inventory_id: payload.inventory_id,
      message: messages[ln].internal_server_error,
    }
    message.error(propertyErrorData.message);
    yield put(getIngredientPropertiesFailure(propertyErrorData));
  }
}

function* getPropertyMethods({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(getPropertyMethodsApi, payload, headers);

    yield put(getPropertyMethodsSuccess(result.data));
  } catch (error) {
    const err = messages[ln].internal_server_error;
    yield put(getPropertyMethodsFailure(err));
    notification.error({ message: err });
  }
}

function* addPropertyToIngredientSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(addPropertyToIngredientApi(payload.inventory_id), payload, headers);
    if (status === "Success") {
      yield put(addPropertyToIngredientSuccess(data));

    } else {
      yield put(addPropertyToIngredientFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(addPropertyToIngredientFailure(messages[ln].internal_server_error));
  }
}


function* getIngredientPropertyValuesSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getIngredientPropertyValuesApi(payload.inventory_id, payload.inventory_property_id), undefined, headers);
    if (status === "Success") {
      yield put(getIngredientPropertyValuesSuccess({ apiPayload: payload, data }));

    } else {
      yield put(getIngredientPropertyValuesFailure({ apiPayload: payload, message }));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientPropertyValuesFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
  }
}

function* getPropertyMethodsListSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getPropertyMethodsListApi(payload.property_id), undefined, headers);
    if (status === "Success") {
      yield put(getPropertyMethodsListSuccess({ apiPayload: payload, data }));

    } else {
      yield put(getPropertyMethodsListFailure({ apiPayload: payload, message }));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getPropertyMethodsListFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
  }
}


function* addPropertyValueToPropertyRequestSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(addPropertyValueToPropertyApi(payload.inventory_id, payload.inventory_property_id), payload, headers);
    if (status === "Success") {
      yield put(addPropertyValueToPropertySuccess({ apiPayload: payload, data }));

    } else {
      if (data?.existing_method_name) {
        notification.info({ message: `${message}, please proceed to save data.` });
      } else {
        notification.error({ message });
      }
      yield put(addPropertyValueToPropertyFailure({ apiPayload: payload, message, existing_method_name: data?.existing_method_name }));
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(addPropertyValueToPropertyFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
  }
}

function* updatePropertyValueToPropertyRequestSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(updatePropertyValueToPropertyApi(payload.inventory_id, payload.inventory_property_id, payload.inventory_property_value_id), payload, headers);
    if (status === "Success") {
      yield put(updatePropertyValueToPropertySuccess({ apiPayload: payload, data }));

    } else {
      yield put(updatePropertyValueToPropertyFailure({ apiPayload: payload, message }));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(updatePropertyValueToPropertyFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
  }
}


function* getIngredientPropertiesDetailsSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getIngredientPropertiesDetailsApi(payload.inventory_id), undefined, headers);
    if (status === "Success") {
      yield put(getIngredientPropertiesDetailsSuccess(data));

    } else {
      yield put(getIngredientPropertiesDetailsFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientPropertiesDetailsFailure(messages[ln].internal_server_error));
  }
}

function* deleteIngredientPropertySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message } } } = yield call(deleteIngredientPropertyApi(payload.inventory_id, payload.inventory_property_id), undefined, headers);
    if (status === "Success") {
      yield put(deleteIngredientPropertySuccess({ apiPayload: payload }));
    } else {
      yield put(deleteIngredientPropertyFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(deleteIngredientPropertyFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
  }
}

function* deleteIngredientPropertyValueSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message } } } = yield call(deleteIngredientPropertyValueApi(payload.inventory_id, payload.inventory_property_id, payload.inventory_property_value_id), undefined, headers);
    if (status === "Success") {
      yield put(deleteIngredientPropertyValueSuccess({ apiPayload: payload }));
    } else {
      yield put(deleteIngredientPropertyValueFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(deleteIngredientPropertyValueFailure({ apiPayload: payload, message: messages[ln].internal_server_error }));
  }
}

function* addIngredientAttachmentSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message, data } } } = yield call(addIngredientAttachmentApi(payload.inventory_id), payload.formData, headers);
    if (status === "Success") {
      yield put(addIngredientAttachmentSuccess(data));
    } else {
      yield put(addIngredientAttachmentFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(addIngredientAttachmentFailure(messages[ln].internal_server_error));
  }
}

function* editIngredientAttachmentSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message, data } } } = yield call(editIngredientAttachmentApi(payload.inventory_id, payload.attachment_id), payload.formData, headers);
    if (status === "Success") {
      yield put(editIngredientAttachmentSuccess(data));
    } else {
      yield put(editIngredientAttachmentFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(editIngredientAttachmentFailure(messages[ln].internal_server_error));
  }
}

function* deleteIngredientAttachmentSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message } } } = yield call(deleteIngredientAttachmentApi(payload.inventory_id, payload.attachment_id), undefined, headers);
    if (status === "Success") {
      yield put(deleteIngredientAttachmentSuccess(payload.attachment_id));
    } else {
      yield put(deleteIngredientAttachmentFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(deleteIngredientAttachmentFailure(messages[ln].internal_server_error));
  }
}

function* getIngredientAttachmentSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message, data } } } = yield call(getIngredientAttachmentsApi(payload.inventory_id), undefined, headers);
    if (status === "Success") {
      yield put(getIngredientAttachmentsSuccess(data));
    } else {
      yield put(getIngredientAttachmentsFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientAttachmentsFailure(messages[ln].internal_server_error));
  }
}

function* getIngredientGradesSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message, data } } } = yield call(getIngredientGradesApi, undefined, headers);
    if (status === "Success") {
      yield put(getIngredientGradesSuccess(data));
    } else {
      yield put(getIngredientGradesFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getIngredientGradesFailure(messages[ln].internal_server_error));
  }
}


function* fetchInventoryPreferences(action: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message, data } } } = yield call(getInventoryPreferencesApi, action.payload, headers);

    if (status === "Success") {
      yield put(inventoryPreferencesSuccess(data))
    } else {
      yield put(inventoryPreferencesFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    const err = messages[ln].internal_server_error;
    yield put(inventoryPreferencesFailure(err));
    notification.error({ message: err });
  }
}

function* updateInventoryPreferences(action: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data: { result: { status, message, data } } } = yield call(updateInventoryPreferencesApi, action.payload, headers);

    if (status === "Success") {
      yield put(updateInventoryPreferencesSuccess(data));
    } else {
      yield put(updateInventoryPreferencesFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    const err = messages[ln].internal_server_error;
    yield put(updateInventoryPreferencesFailure(err));
    notification.error({ message: err });
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(getSuppliersRequest, getSuppliersSaga);
  yield takeLatest(createSupplierRequest, createSupplierSaga);
  yield takeLatest(getSupplierDetailsRequest, getSupplierDetailsSaga);
  yield takeLatest(updateSupplierRequest, updateSupplierSaga);
  yield takeLatest(deleteSupplierRequest, deleteSupplierSaga);

  yield takeLatest(getInventoryPropertiesRequest, getInventoryPropertiesSaga);
  yield takeLatest(createInventoryPropertyRequest, createInventoryPropertySaga);
  yield takeLatest(updateInventoryPropertyRequest, updateInventoryPropertySaga);
  yield takeLatest(deleteInventoryPropertyRequest, deleteInventoryPropertySaga);

  yield takeLatest(getInventoryMethodsRequest, getInventoryMethodsSaga);
  yield takeLatest(getInventoryMethodsLookupRequest, getInventoryMethodsLookupSaga);
  yield takeLatest(createInventoryMethodRequest, createInventoryMethodSaga);
  yield takeLatest(updateInventoryMethodRequest, updateInventoryMethodSaga);
  yield takeLatest(deleteInventoryMethodRequest, deleteInventoryMethodSaga);

  yield takeLatest(createMethodParameterRequest, createMethodParameterSaga);
  yield takeLatest(updateMethodParameterRequest, updateMethodParameterSaga);
  yield takeLatest(deleteMethodParameterRequest, deleteMethodParameterSaga);

  yield takeLatest(getIngredientsRequest, getIngredientsSaga);
  yield takeLatest(getIngredientsSearchRequest, getIngredientsSearchSaga);
  yield takeLatest(getIngredientsMetaRequest, getIngredientsMetaSaga);
  yield takeLatest(getIngredientDetailsRequest, getIngredientDetailsSaga);
  yield takeLatest(createIngredientRequest, createIngredientSaga);
  yield takeLatest(updateIngredientRequest, updateIngredientSaga);
  yield takeLatest(deleteIngredientRequest, deleteIngredientSaga);
  yield takeLatest(createIngredientFromTrialRequest, createIngredientFromTrialSaga);

  yield takeLatest(uploadSmilesFileRequest, smilesFileUploadSaga);
  yield takeLatest(resetDataSmilesFileRequest, resetDataSmilesFileSaga);
  yield takeLatest(getIngredientUsedInWoRequest, getIngredientUsedInWoSaga);

  yield takeLatest(getIngredientFamilyListRequest, getIngredientFamilyListSaga);
  yield takeLatest(getIngredientFamilyRequest, getIngredientFamilySaga);
  yield takeLatest(updateIngredientFamilyRequest, updateIngredientFamilySaga);
  yield takeLatest(deleteIngredientFamilyRequest, deleteIngredientFamilySaga);
  yield takeLatest(createIngredientFamilyRequest, createIngredientFamilySaga);
  yield takeLatest(getIngredientPropertiesRequest, getIngredientPropertiesSaga);

  yield takeLatest(getPropertyMethodsRequest, getPropertyMethods);

  yield takeLatest(addPropertyToIngredientRequest, addPropertyToIngredientSaga);
  yield takeLatest(getIngredientPropertyValuesRequest, getIngredientPropertyValuesSaga);
  yield takeLatest(getPropertyMethodsListRequest, getPropertyMethodsListSaga);
  yield takeLatest(addPropertyValueToPropertyRequest, addPropertyValueToPropertyRequestSaga);
  yield takeLatest(updatePropertyValueToPropertyRequest, updatePropertyValueToPropertyRequestSaga);
  yield takeLatest(getIngredientPropertiesDetailsRequest, getIngredientPropertiesDetailsSaga);
  yield takeLatest(deleteIngredientPropertyRequest, deleteIngredientPropertySaga);
  yield takeLatest(deleteIngredientPropertyValueRequest, deleteIngredientPropertyValueSaga);
  yield takeLatest(addIngredientAttachmentRequest, addIngredientAttachmentSaga);
  yield takeLatest(editIngredientAttachmentRequest, editIngredientAttachmentSaga);
  yield takeLatest(deleteIngredientAttachmentRequest, deleteIngredientAttachmentSaga);
  yield takeLatest(getIngredientAttachmentsRequest, getIngredientAttachmentSaga);
  yield takeLatest(getIngredientGradesRequest, getIngredientGradesSaga);
  yield takeLatest(inventoryPreferencesRequest, fetchInventoryPreferences)
  yield takeLatest(updateInventoryPreferencesRequest, updateInventoryPreferences)
}

import { createActions } from "redux-actions";

export enum FetchSemAnalysisDataActionTypes {
  REQUEST = "FETCH_SEM_ANALYSIS_DATA_REQUEST",
  SUCCESS = "FETCH_SEM_ANALYSIS_DATA_SUCCESS",
  FAILURE = "FETCH_SEM_ANALYSIS_DATA_FAILURE",
  CLEAR = "FETCH_SEM_ANALYSIS_DATA_CLEAR",
}

export enum FetchClassificationAnalysisActionTypes {
  REQUEST = "FETCH_CLASSIFICATION_ANALYSIS_REQUEST",
  SUCCESS = "FETCH_CLASSIFICATION_ANALYSIS_SUCCESS",
  FAILURE = "FETCH_CLASSIFICATION_ANALYSIS_FAILURE",
  CLEAR = "FETCH_CLASSIFICATION_ANALYSIS_CLEAR"
}

export enum FetchSemAnalysisMaskDataActionTypes {
  REQUEST = "FETCH_SEM_ANALYSIS_MASK_DATA_REQUEST",
  SUCCESS = "FETCH_SEM_ANALYSIS_MASK_DATA_SUCCESS",
  FAILURE = "FETCH_SEM_ANALYSIS_MASK_DATA_FAILURE",
  CLEAR = "FETCH_SEM_ANALYSIS_MASK_DATA_CLEAR"
}

export enum FetchSemAnalysisListActionTypes {
  REQUEST = "FETCH_SEM_ANALYSIS_LIST_REQUEST",
  SUCCESS = "FETCH_SEM_ANALYSIS_LIST_SUCCESS",
  FAILURE = "FETCH_SEM_ANALYSIS_LIST_FAILURE",
  CLEAR = "FETCH_SEM_ANALYSIS_LIST_CLEAR"
}

export enum FetchSemAnalysisScaleActionTypes {
  REQUEST = "FETCH_SEM_ANALYSIS_SCALE_REQUEST",
  SUCCESS = "FETCH_SEM_ANALYSIS_SCALE_SUCCESS",
  FAILURE = "FETCH_SEM_ANALYSIS_SCALE_FAILURE",
}

export enum DeleteSemAnalysisFilesActionTypes {
  REQUEST = "DELETE_SEM_ANALYSIS_FILES_REQUEST",
  SUCCESS = "DELETE_SEM_ANALYSIS_FILES_SUCCESS",
  FAILURE = "DELETE_SEM_ANALYSIS_FILES_FAILURE",
  CLEAR = "DELETE_SEM_ANALYSIS_FILES_CLEAR"
}

export enum FetchImageSubtractionActionTypes {
  REQUEST = "FETCH_IMAGE_SUBTRACTION_REQUEST",
  SUCCESS = "FETCH_IMAGE_SUBTRACTION_SUCCESS",
  FAILURE = "FETCH_IMAGE_SUBTRACTION_FAILURE",
}

export enum SetImageSubtractionActionTypes {
  REQUEST = "SET_IMAGE_SUBTRACTION_REQUEST",
  SUCCESS = "SET_IMAGE_SUBTRACTION_SUCCESS",
  FAILURE = "SET_IMAGE_SUBTRACTION_FAILURE",
  CLEAR = "SET_IMAGE_SUBTRACTION_CLEAR",
}

export enum FetchInstanceDetectionActionTypes {
  REQUEST = "FETCH_INSTANCE_DETECTION_REQUEST",
  SUCCESS = "FETCH_INSTANCE_DETECTION_SUCCESS",
  FAILURE = "FETCH_INSTANCE_DETECTION_FAILURE",
  CLEAR = "FETCH_INSTANCE_DETECTION_CLEAR",
}

export enum SetSemAnalysisMaskHeightWidthActionTypes {
  REQUEST = "SET_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_REQUEST",
  SUCCESS = "SET_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_SUCCESS",
  FAILURE = "SET_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_FAILURE",
}

export enum FetchSemAnalysisMaskHeightWidthActionTypes {
  REQUEST = "FETCH_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_REQUEST",
  SUCCESS = "FETCH_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_SUCCESS",
  FAILURE = "FETCH_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_FAILURE",
  CLEAR = "FETCH_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_CLEAR",
}

export enum DeleteSemAnalysisMaskHeightWidthActionTypes {
  REQUEST = "DELETE_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_REQUEST",
  SUCCESS = "DELETE_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_SUCCESS",
  FAILURE = "DELETE_SEM_ANALYSIS_MASK_HEIGHT_WIDTH_FAILURE",
}

export enum DownloadSemAnalysisReportActionTypes {
  REQUEST = "DOWNLOAD_SEM_ANALYSIS_REPORT_REQUEST",
  SUCCESS = "DOWNLOAD_SEM_ANALYSIS_REPORT_SUCCESS",
  FAILURE = "DOWNLOAD_SEM_ANALYSIS_REPORT_FAILURE",
}

export enum DeleteMasksActionTypes {
  REQUEST = "DELETE_MASKS_REQUEST",
  SUCCESS = "DELETE_MASKS_SUCCESS",
  FAILURE = "DELETE_MASKS_FAILURE",
}

export enum UpdateClassificationActionTypes {
  REQUEST = "UPDATE_CLASSIFICATION_REQUEST",
  SUCCESS = "UPDATE_CLASSIFICATION_SUCCESS",
  FAILURE = "UPDATE_CLASSIFICATION_FAILURE",
}

export enum ResetMasksActionTypes {
  REQUEST = "RESET_MASKS_REQUEST",
  SUCCESS = "RESET_MASKS_SUCCESS",
  FAILURE = "RESET_MASKS_FAILURE",
}

export enum ProcessPaintFilmAnalysisActionTypes {
  REQUEST = "PROCESS_PAINT_FILM_ANALYSIS_REQUEST",
  SUCCESS = "PROCESS_PAINT_FILM_ANALYSIS_SUCCESS",
  FAILURE = "PROCESS_PAINT_FILM_ANALYSIS_FAILURE",
  CLEAR = "PROCESS_PAINT_FILM_ANALYSIS_CLEAR"
}

export enum FetchPaintFilmBatchInfoActionTypes {
  REQUEST = "FETCH_PAINT_FILM_BATCH_INFO_REQUEST",
  SUCCESS = "FETCH_PAINT_FILM_BATCH_INFO_SUCCESS",
  FAILURE = "FETCH_PAINT_FILM_BATCH_INFO_FAILURE",
  CLEAR = "FETCH_PAINT_FILM_BATCH_INFO_CLEAR",
}

export enum DownloadBatchReportActionTypes {
  REQUEST = "DOWNLOAD_BATCH_REPORT_REQUEST",
  SUCCESS = "DOWNLOAD_BATCH_REPORT_SUCCESS",
  FAILURE = "DOWNLOAD_BATCH_REPORT_FAILURE",
}

export enum DeletePaintFilmBatchActionTypes {
  REQUEST = "DELETE_PAINT_FILM_BATCH_REQUEST",
  SUCCESS = "DELETE_PAINT_FILM_BATCH_SUCCESS",
  FAILURE = "DELETE_PAINT_FILM_BATCH_FAILURE",
  CLEAR = "DELETE_PAINT_FILM_BATCH_CLEAR"
}

export enum PaintFilmRecalibrateScaleActionTypes {
  REQUEST = "PAINT_FILM_RECALIBRATE_SCALE_REQUEST",
  SUCCESS = "PAINT_FILM_RECALIBRATE_SCALE_SUCCESS",
  FAILURE = "PAINT_FILM_RECALIBRATE_SCALE_FAILURE",
  CLEAR = "PAINT_FILM_RECALIBRATE_SCALE_CLEAR"
}

export enum AnalysisTypesActionTypes {
  REQUEST = "ANALYSIS_TYPES_REQUEST",
  SUCCESS = "ANALYSIS_TYPES_SUCCESS",
  FAILURE = "ANALYSIS_TYPES_FAILURE"
}

export enum FetchResidualBatchInfoActionTypes {
  REQUEST = "FETCH_RESIDUAL_BATCH_INFO_REQUEST",
  SUCCESS = "FETCH_RESIDUAL_BATCH_INFO_SUCCESS",
  FAILURE = "FETCH_RESIDUAL_BATCH_INFO_FAILURE",
  CLEAR = "FETCH_RESIDUAL_BATCH_INFO_CLEAR",
}

export enum DeleteResidualBatchActionTypes {
  REQUEST = "DELETE_RESIDUAL_BATCH_REQUEST",
  SUCCESS = "DELETE_RESIDUAL_BATCH_SUCCESS",
  FAILURE = "DELETE_RESIDUAL_BATCH_FAILURE",
  CLEAR = "DELETE_RESIDUAL_BATCH_CLEAR"
}

export enum ResidualRecalibrateScaleActionTypes {
  REQUEST = "RESIDUAL_RECALIBRATE_SCALE_REQUEST",
  SUCCESS = "RESIDUAL_RECALIBRATE_SCALE_SUCCESS",
  FAILURE = "RESIDUAL_RECALIBRATE_SCALE_FAILURE",
  CLEAR = "RESIDUAL_RECALIBRATE_SCALE_CLEAR"
}

export enum ModifyPaintFilmActionTypes {
  REQUEST = "MODIFY_PAINT_FILM_REQUEST",
  SUCCESS = "MODIFY_PAINT_FILM_SUCCESS",
  FAILURE = "MODIFY_PAINT_FILM_FAILURE",
}

export enum ModifyPaintFilmBatchActionTypes {
  REQUEST = "MODIFY_PAINT_FILM_BATCH_REQUEST",
  SUCCESS = "MODIFY_PAINT_FILM_BATCH_SUCCESS",
  FAILURE = "MODIFY_PAINT_FILM_BATCH_FAILURE",
  CLEAR = "MODIFY_PAINT_FILM_BATCH_CLEAR"
}

export enum ProcessFiberAnalysisActionTypes {
  REQUEST = "PROCESS_FIBER_ANALYSIS_REQUEST",
  SUCCESS = "PROCESS_FIBER_ANALYSIS_SUCCESS",
  FAILURE = "PROCESS_FIBER_ANALYSIS_FAILURE",
  CLEAR = "PROCESS_FIBER_ANALYSIS_CLEAR"
}

export enum FetchFiberBatchInfoActionTypes {
  REQUEST = "FETCH_FIBER_BATCH_INFO_REQUEST",
  SUCCESS = "FETCH_FIBER_BATCH_INFO_SUCCESS",
  FAILURE = "FETCH_FIBER_BATCH_INFO_FAILURE",
  CLEAR = "FETCH_FIBER_BATCH_INFO_CLEAR",
}

export enum DeleteFiberBatchActionTypes {
  REQUEST = "DELETE_FIBER_BATCH_REQUEST",
  SUCCESS = "DELETE_FIBER_BATCH_SUCCESS",
  FAILURE = "DELETE_FIBER_BATCH_FAILURE",
  CLEAR = "DELETE_FIBER_BATCH_CLEAR"
}

export enum FiberRecalibrateScaleActionTypes {
  REQUEST = "FIBER_RECALIBRATE_SCALE_REQUEST",
  SUCCESS = "FIBER_RECALIBRATE_SCALE_SUCCESS",
  FAILURE = "FIBER_RECALIBRATE_SCALE_FAILURE",
  CLEAR = "FIBER_RECALIBRATE_SCALE_CLEAR"
}

export enum ProcessWhiteObjectsActionTypes {
  REQUEST = "PROCESS_WHITE_OBJECTS_REQUEST",
  SUCCESS = "PROCESS_WHITE_OBJECTS_SUCCESS",
  FAILURE = "PROCESS_WHITE_OBJECTS_FAILURE",
  CLEAR = "PROCESS_WHITE_OBJECTS_CLEAR"
}

export enum ModifyWhiteObjectsActionTypes {
  REQUEST = "MODIFY_WHITE_OBJECTS_REQUEST",
  SUCCESS = "MODIFY_WHITE_OBJECTS_SUCCESS",
  FAILURE = "MODIFY_WHITE_OBJECTS_FAILURE",
  CLEAR = "MODIFY_WHITE_OBJECTS_CLEAR"
}

export enum ModifyFiberActionTypes {
  REQUEST = "MODIFY_FIBER_REQUEST",
  SUCCESS = "MODIFY_FIBER_SUCCESS",
  FAILURE = "MODIFY_FIBER_FAILURE",
}

export enum DownloadSegmentedImagesTypes {
  REQUEST = "DOWNLOAD_SEGMENTED_IMAGES_REQUEST",
  SUCCESS = "DOWNLOAD_SEGMENTED_IMAGES_SUCCESS",
  FAILURE = "DOWNLOAD_SEGMENTED_IMAGES_FAILURE",
}

export const {
  fetchSemAnalysisDataRequest,
  fetchSemAnalysisDataSuccess,
  fetchSemAnalysisDataFailure,
  fetchSemAnalysisDataClear,

  fetchClassificationAnalysisRequest,
  fetchClassificationAnalysisSuccess,
  fetchClassificationAnalysisFailure,
  fetchClassificationAnalysisClear,

  fetchClassificationAnalysisDataRequest,
  fetchClassificationAnalysisDataSuccess,
  fetchClassificationAnalysisDataFailure,

  fetchSemAnalysisMaskDataRequest,
  fetchSemAnalysisMaskDataSuccess,
  fetchSemAnalysisMaskDataFailure,
  fetchSemAnalysisMaskDataClear,

  fetchSemAnalysisListRequest,
  fetchSemAnalysisListSuccess,
  fetchSemAnalysisListFailure,
  fetchSemAnalysisListClear,

  fetchSemAnalysisScaleRequest,
  fetchSemAnalysisScaleSuccess,
  fetchSemAnalysisScaleFailure,

  deleteSemAnalysisFilesRequest,
  deleteSemAnalysisFilesSuccess,
  deleteSemAnalysisFilesFailure,
  deleteSemAnalysisFilesClear,

  fetchImageSubtractionRequest,
  fetchImageSubtractionSuccess,
  fetchImageSubtractionFailure,

  setImageSubtractionRequest,
  setImageSubtractionSuccess,
  setImageSubtractionFailure,
  setImageSubtractionClear,

  fetchInstanceDetectionRequest,
  fetchInstanceDetectionSuccess,
  fetchInstanceDetectionFailure,
  fetchInstanceDetectionClear,

  setSemAnalysisMaskHeightWidthRequest,
  setSemAnalysisMaskHeightWidthSuccess,
  setSemAnalysisMaskHeightWidthFailure,

  fetchSemAnalysisMaskHeightWidthRequest,
  fetchSemAnalysisMaskHeightWidthSuccess,
  fetchSemAnalysisMaskHeightWidthFailure,
  fetchSemAnalysisMaskHeightWidthClear,

  deleteSemAnalysisMaskHeightWidthRequest,
  deleteSemAnalysisMaskHeightWidthSuccess,
  deleteSemAnalysisMaskHeightWidthFailure,

  downloadSemAnalysisReportRequest,
  downloadSemAnalysisReportSuccess,
  downloadSemAnalysisReportFailure,

  deleteMasksRequest,
  deleteMasksSuccess,
  deleteMasksFailure,

  updateClassificationRequest,
  updateClassificationSuccess,
  updateClassificationFailure,

  resetMasksRequest,
  resetMasksSuccess,
  resetMasksFailure,

  processPaintFilmAnalysisRequest,
  processPaintFilmAnalysisSuccess,
  processPaintFilmAnalysisFailure,
  processPaintFilmAnalysisClear,

  fetchPaintFilmBatchInfoRequest,
  fetchPaintFilmBatchInfoSuccess,
  fetchPaintFilmBatchInfoFailure,
  fetchPaintFilmBatchInfoClear,

  downloadBatchReportRequest,
  downloadBatchReportSuccess,
  downloadBatchReportFailure,

  deletePaintFilmBatchRequest,
  deletePaintFilmBatchSuccess,
  deletePaintFilmBatchFailure,
  deletePaintFilmBatchClear,

  paintFilmRecalibrateScaleRequest,
  paintFilmRecalibrateScaleSuccess,
  paintFilmRecalibrateScaleFailure,
  paintFilmRecalibrateScaleClear,

  analysisTypesRequest,
  analysisTypesSuccess,
  analysisTypesFailure,

  fetchResidualBatchInfoRequest,
  fetchResidualBatchInfoSuccess,
  fetchResidualBatchInfoFailure,
  fetchResidualBatchInfoClear,

  deleteResidualBatchRequest,
  deleteResidualBatchSuccess,
  deleteResidualBatchFailure,
  deleteResidualBatchClear,

  residualRecalibrateScaleRequest,
  residualRecalibrateScaleSuccess,
  residualRecalibrateScaleFailure,
  residualRecalibrateScaleClear,

  modifyPaintFilmRequest,
  modifyPaintFilmSuccess,
  modifyPaintFilmFailure,

  modifyPaintFilmBatchRequest,
  modifyPaintFilmBatchSuccess,
  modifyPaintFilmBatchFailure,
  modifyPaintFilmBatchClear,

  processFiberAnalysisRequest,
  processFiberAnalysisSuccess,
  processFiberAnalysisFailure,
  processFiberAnalysisClear,

  fetchFiberBatchInfoRequest,
  fetchFiberBatchInfoSuccess,
  fetchFiberBatchInfoFailure,
  fetchFiberBatchInfoClear,

  deleteFiberBatchRequest,
  deleteFiberBatchSuccess,
  deleteFiberBatchFailure,
  deleteFiberBatchClear,

  fiberRecalibrateScaleRequest,
  fiberRecalibrateScaleSuccess,
  fiberRecalibrateScaleFailure,
  fiberRecalibrateScaleClear,

  processWhiteObjectsRequest,
  processWhiteObjectsSuccess,
  processWhiteObjectsFailure,
  processWhiteObjectsClear,

  modifyWhiteObjectsRequest,
  modifyWhiteObjectsSuccess,
  modifyWhiteObjectsFailure,
  modifyWhiteObjectsClear,

  modifyFiberRequest,
  modifyFiberSuccess,
  modifyFiberFailure,

  downloadSegmentedImagesRequest,
  downloadSegmentedImagesSuccess,
  downloadSegmentedImagesFailure
} = createActions({
  [FetchSemAnalysisDataActionTypes.REQUEST]: (payload) => payload,
  [FetchSemAnalysisDataActionTypes.SUCCESS]: (payload) => payload,
  [FetchSemAnalysisDataActionTypes.FAILURE]: (error: any) => error,
  [FetchSemAnalysisDataActionTypes.CLEAR]: (error: any) => error,

  [FetchClassificationAnalysisActionTypes.REQUEST]: (payload) => payload,
  [FetchClassificationAnalysisActionTypes.SUCCESS]: (payload) => payload,
  [FetchClassificationAnalysisActionTypes.FAILURE]: (error: any) => error,
  [FetchClassificationAnalysisActionTypes.CLEAR]: () => { },

  [FetchSemAnalysisMaskDataActionTypes.REQUEST]: (payload) => payload,
  [FetchSemAnalysisMaskDataActionTypes.SUCCESS]: (payload) => payload,
  [FetchSemAnalysisMaskDataActionTypes.FAILURE]: (error: any) => error,
  [FetchSemAnalysisMaskDataActionTypes.CLEAR]: () => { },

  [FetchSemAnalysisListActionTypes.REQUEST]: (payload) => payload,
  [FetchSemAnalysisListActionTypes.SUCCESS]: (payload) => payload,
  [FetchSemAnalysisListActionTypes.FAILURE]: (error: any) => error,
  [FetchSemAnalysisListActionTypes.CLEAR]: () => { },

  [FetchSemAnalysisScaleActionTypes.REQUEST]: (payload) => payload,
  [FetchSemAnalysisScaleActionTypes.SUCCESS]: (payload) => payload,
  [FetchSemAnalysisScaleActionTypes.FAILURE]: (error: any) => error,

  [DeleteSemAnalysisFilesActionTypes.REQUEST]: (payload) => payload,
  [DeleteSemAnalysisFilesActionTypes.SUCCESS]: (payload) => payload,
  [DeleteSemAnalysisFilesActionTypes.FAILURE]: (error: any) => error,
  [DeleteSemAnalysisFilesActionTypes.CLEAR]: () => { },

  [FetchImageSubtractionActionTypes.REQUEST]: (payload) => payload,
  [FetchImageSubtractionActionTypes.SUCCESS]: (payload) => payload,
  [FetchImageSubtractionActionTypes.FAILURE]: (error: any) => error,

  [FetchInstanceDetectionActionTypes.REQUEST]: (payload) => payload,
  [FetchInstanceDetectionActionTypes.SUCCESS]: (payload) => payload,
  [FetchInstanceDetectionActionTypes.FAILURE]: (error: any) => error,
  [FetchInstanceDetectionActionTypes.CLEAR]: () => { },

  [SetImageSubtractionActionTypes.REQUEST]: (payload) => payload,
  [SetImageSubtractionActionTypes.SUCCESS]: (payload) => payload,
  [SetImageSubtractionActionTypes.FAILURE]: (error: any) => error,
  [SetImageSubtractionActionTypes.CLEAR]: () => { },

  [SetSemAnalysisMaskHeightWidthActionTypes.REQUEST]: (payload) => payload,
  [SetSemAnalysisMaskHeightWidthActionTypes.SUCCESS]: (payload) => payload,
  [SetSemAnalysisMaskHeightWidthActionTypes.FAILURE]: (error: any) => error,

  [FetchSemAnalysisMaskHeightWidthActionTypes.REQUEST]: (payload) => payload,
  [FetchSemAnalysisMaskHeightWidthActionTypes.SUCCESS]: (payload) => payload,
  [FetchSemAnalysisMaskHeightWidthActionTypes.FAILURE]: (error: any) => error,
  [FetchSemAnalysisMaskHeightWidthActionTypes.CLEAR]: () => { },

  [DeleteSemAnalysisMaskHeightWidthActionTypes.REQUEST]: (payload) => payload,
  [DeleteSemAnalysisMaskHeightWidthActionTypes.SUCCESS]: (payload) => payload,
  [DeleteSemAnalysisMaskHeightWidthActionTypes.FAILURE]: (error: any) => error,

  [DownloadSemAnalysisReportActionTypes.REQUEST]: (payload) => payload,
  [DownloadSemAnalysisReportActionTypes.SUCCESS]: (payload) => payload,
  [DownloadSemAnalysisReportActionTypes.FAILURE]: (error: any) => error,

  [DeleteMasksActionTypes.REQUEST]: (payload) => payload,
  [DeleteMasksActionTypes.SUCCESS]: (payload) => payload,
  [DeleteMasksActionTypes.FAILURE]: (error: any) => error,

  [UpdateClassificationActionTypes.REQUEST]: (payload) => payload,
  [UpdateClassificationActionTypes.SUCCESS]: (payload) => payload,
  [UpdateClassificationActionTypes.FAILURE]: (error: any) => error,

  [ResetMasksActionTypes.REQUEST]: (payload) => payload,
  [ResetMasksActionTypes.SUCCESS]: (payload) => payload,
  [ResetMasksActionTypes.FAILURE]: (error: any) => error,

  [ProcessPaintFilmAnalysisActionTypes.REQUEST]: (payload) => payload,
  [ProcessPaintFilmAnalysisActionTypes.SUCCESS]: (payload) => payload,
  [ProcessPaintFilmAnalysisActionTypes.FAILURE]: (error: any) => error,
  [ProcessPaintFilmAnalysisActionTypes.CLEAR]: () => { },

  [FetchPaintFilmBatchInfoActionTypes.REQUEST]: (payload) => payload,
  [FetchPaintFilmBatchInfoActionTypes.SUCCESS]: (payload) => payload,
  [FetchPaintFilmBatchInfoActionTypes.FAILURE]: (error: any) => error,
  [FetchPaintFilmBatchInfoActionTypes.CLEAR]: () => { },

  [DownloadBatchReportActionTypes.REQUEST]: (payload) => payload,
  [DownloadBatchReportActionTypes.SUCCESS]: (payload) => payload,
  [DownloadBatchReportActionTypes.FAILURE]: (error: any) => error,

  [DeletePaintFilmBatchActionTypes.REQUEST]: (payload) => payload,
  [DeletePaintFilmBatchActionTypes.SUCCESS]: (payload) => payload,
  [DeletePaintFilmBatchActionTypes.FAILURE]: (error: any) => error,
  [DeletePaintFilmBatchActionTypes.CLEAR]: () => { },

  [PaintFilmRecalibrateScaleActionTypes.REQUEST]: (payload) => payload,
  [PaintFilmRecalibrateScaleActionTypes.SUCCESS]: (payload) => payload,
  [PaintFilmRecalibrateScaleActionTypes.FAILURE]: (error: any) => error,
  [PaintFilmRecalibrateScaleActionTypes.CLEAR]: () => { },

  [AnalysisTypesActionTypes.REQUEST]: (payload) => payload,
  [AnalysisTypesActionTypes.SUCCESS]: (payload) => payload,
  [AnalysisTypesActionTypes.FAILURE]: (error: any) => error,

  [FetchResidualBatchInfoActionTypes.REQUEST]: (payload) => payload,
  [FetchResidualBatchInfoActionTypes.SUCCESS]: (payload) => payload,
  [FetchResidualBatchInfoActionTypes.FAILURE]: (error: any) => error,
  [FetchResidualBatchInfoActionTypes.CLEAR]: () => { },

  [DeleteResidualBatchActionTypes.REQUEST]: (payload) => payload,
  [DeleteResidualBatchActionTypes.SUCCESS]: (payload) => payload,
  [DeleteResidualBatchActionTypes.FAILURE]: (error: any) => error,
  [DeleteResidualBatchActionTypes.CLEAR]: () => { },

  [ResidualRecalibrateScaleActionTypes.REQUEST]: (payload) => payload,
  [ResidualRecalibrateScaleActionTypes.SUCCESS]: (payload) => payload,
  [ResidualRecalibrateScaleActionTypes.FAILURE]: (error: any) => error,
  [ResidualRecalibrateScaleActionTypes.CLEAR]: () => { },

  [ModifyPaintFilmActionTypes.REQUEST]: (payload) => payload,
  [ModifyPaintFilmActionTypes.SUCCESS]: (payload) => payload,
  [ModifyPaintFilmActionTypes.FAILURE]: (error: any) => error,

  [ModifyPaintFilmBatchActionTypes.REQUEST]: (payload) => payload,
  [ModifyPaintFilmBatchActionTypes.SUCCESS]: (payload) => payload,
  [ModifyPaintFilmBatchActionTypes.FAILURE]: (error: any) => error,
  [ModifyPaintFilmBatchActionTypes.CLEAR]: () => { },

  [ProcessFiberAnalysisActionTypes.REQUEST]: (payload) => payload,
  [ProcessFiberAnalysisActionTypes.SUCCESS]: (payload) => payload,
  [ProcessFiberAnalysisActionTypes.FAILURE]: (error: any) => error,
  [ProcessFiberAnalysisActionTypes.CLEAR]: () => { },

  [FetchFiberBatchInfoActionTypes.REQUEST]: (payload) => payload,
  [FetchFiberBatchInfoActionTypes.SUCCESS]: (payload) => payload,
  [FetchFiberBatchInfoActionTypes.FAILURE]: (error: any) => error,
  [FetchFiberBatchInfoActionTypes.CLEAR]: () => { },

  [DeleteFiberBatchActionTypes.REQUEST]: (payload) => payload,
  [DeleteFiberBatchActionTypes.SUCCESS]: (payload) => payload,
  [DeleteFiberBatchActionTypes.FAILURE]: (error: any) => error,
  [DeleteFiberBatchActionTypes.CLEAR]: () => { },

  [FiberRecalibrateScaleActionTypes.REQUEST]: (payload) => payload,
  [FiberRecalibrateScaleActionTypes.SUCCESS]: (payload) => payload,
  [FiberRecalibrateScaleActionTypes.FAILURE]: (error: any) => error,
  [FiberRecalibrateScaleActionTypes.CLEAR]: () => { },

  [ProcessWhiteObjectsActionTypes.REQUEST]: (payload) => payload,
  [ProcessWhiteObjectsActionTypes.SUCCESS]: (payload) => payload,
  [ProcessWhiteObjectsActionTypes.FAILURE]: (error: any) => error,
  [ProcessWhiteObjectsActionTypes.CLEAR]: () => { },

  [ModifyWhiteObjectsActionTypes.REQUEST]: (payload) => payload,
  [ModifyWhiteObjectsActionTypes.SUCCESS]: (payload) => payload,
  [ModifyWhiteObjectsActionTypes.FAILURE]: (error: any) => error,
  [ModifyWhiteObjectsActionTypes.CLEAR]: () => { },

  [ModifyFiberActionTypes.REQUEST]: (payload) => payload,
  [ModifyFiberActionTypes.SUCCESS]: (payload) => payload,
  [ModifyFiberActionTypes.FAILURE]: (error: any) => error,

  [DownloadSegmentedImagesTypes.REQUEST]: (payload) => payload,
  [DownloadSegmentedImagesTypes.SUCCESS]: (payload) => payload,
  [DownloadSegmentedImagesTypes.FAILURE]: (error: any) => error,
});

import { Drawer } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../Shared/SEMAnalysis.scss";
import ResidualAnalysisDetailsContent from "./ResidualAnalysisDetailsContent";
import ResidualAnalysisDetailsSidebar from "./ResidualAnalysisDetailsSidebar";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMasksRequest,
  downloadSemAnalysisReportRequest,
  fetchImageSubtractionRequest,
  fetchResidualBatchInfoRequest,
  resetMasksRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export enum RESIDUAL_IMAGE_OPTIONS {
  BEFORE = "Before",
  AFTER = "After",
  NONE = "None",
}

type LocationState = {
  masksSettings?: Record<string, any>;
  imgToShow?: RESIDUAL_IMAGE_OPTIONS;
  imgOpacity?: number;
};

const ResidualAnalysisDetails = () => {
  const location = useLocation<LocationState>();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const {
    residualBatchInfo,
    imageSubtraction,
    resetMasksStatus,
    deleteMasksStatus,
    imageSubtractionStatus,
    analysisTypes,
    downloadSemAnalysisReportStatus,
  } = useSelector((state: StoreState) => state.semAnalysis);
  const [selectedMasks, setSelectedMasks] = useState<string[]>([]);
  const [minArea, setMinArea] = useState(0);
  const [maxArea, setMaxArea] = useState(100);
  const [masksSettings, setMasksSettings] = useState(
    location.state?.masksSettings || {
      [RESIDUAL_IMAGE_OPTIONS.BEFORE]: {
        showBBox: false,
        showAllMasks: false,
      },
      [RESIDUAL_IMAGE_OPTIONS.AFTER]: {
        showBBox: true,
        showAllMasks: true,
      },
      [RESIDUAL_IMAGE_OPTIONS.NONE]: {
        showBBox: true,
        showAllMasks: true,
      },
    }
  );
  const [maskData, setMaskData] = useState<any>();
  const [imgToShow, setImgToShow] = useState<RESIDUAL_IMAGE_OPTIONS>(
    location.state?.imgToShow || RESIDUAL_IMAGE_OPTIONS.AFTER
  );
  const [imgOpacity, setImgOpacity] = useState(
    location.state?.imgOpacity || 100
  );

  const fetchImageSubstractionMasks = useCallback(
    (parameters?: Record<string, any>) => {
      dispatch(
        fetchImageSubtractionRequest({
          file_id: params.fileId,
          batch_id: params.batchId,
          parameters,
        })
      );
    },
    [dispatch, params.batchId, params.fileId]
  );

  useLayoutEffect(() => {
    fetchImageSubstractionMasks();
  }, [fetchImageSubstractionMasks]);

  useEffect(() => {
    if (
      !residualBatchInfo ||
      (Array.isArray(residualBatchInfo) && residualBatchInfo.length === 0)
    ) {
      dispatch(
        fetchResidualBatchInfoRequest({
          batch_id: params?.batchId,
          analysis_type:
            analysisTypes[
            IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS as keyof typeof analysisTypes
            ],
        })
      );
    }
  }, [residualBatchInfo, dispatch, params?.batchId, analysisTypes]);

  useEffect(() => {
    if (deleteMasksStatus === AsyncStates.SUCCESS) {
      setSelectedMasks([]);
    }
  }, [deleteMasksStatus, setSelectedMasks]);

  const handleImageSubtraction = useCallback((data: any) => {
    let userContext = data?.masks;
    let array = userContext?.flatMap((item: any) =>
      Object.keys(item).map((key: any) => ({
        id: key,
        mask_polygon: item[key].mask_polygon,
        area: item[key].area,
        display_flag: item[key].display_flag,
      }))
    );

    setMaskData(array);
  }, []);

  useEffect(() => {
    if (Object.keys(imageSubtraction).length > 0) {
      handleImageSubtraction(imageSubtraction);
    }
  }, [handleImageSubtraction, imageSubtraction]);

  useEffect(() => {
    if (imageSubtractionStatus === AsyncStates.SUCCESS) {
      setMinArea(imageSubtraction?.default_parameters?.area_range[0]);
      setMaxArea(imageSubtraction?.default_parameters?.area_range[1]);
    }
  }, [imageSubtraction?.default_parameters?.area_range, imageSubtractionStatus, maskData]);

  const chosenImage = useMemo(() => {
    if (imgToShow === RESIDUAL_IMAGE_OPTIONS.AFTER)
      return imageSubtraction?.image_url_A;
    if (imgToShow === RESIDUAL_IMAGE_OPTIONS.BEFORE)
      return imageSubtraction?.image_url_B;
    if (imgToShow === RESIDUAL_IMAGE_OPTIONS.NONE)
      return imageSubtraction?.image_url_A_grey;

    return null;
  }, [imageSubtraction, imgToShow]);

  const filteredData = useMemo(() => {
    const returnData = maskData
      ?.filter((mask: any) => mask?.display_flag);

    return returnData;
  }, [maskData]);

  const imageQuality: string | undefined = useMemo(() => {
    return imageSubtraction?.image_quality
  }, [imageSubtraction?.image_quality]);

  const downloadReport = useCallback(() => {
    const payload: any = {
      file_id: params.fileId,
      batch_id: params.batchId,
      analysis_type:
        analysisTypes[
        IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS as keyof typeof analysisTypes
        ],
    };

    payload.additional_info = {
      mask_ids: filteredData?.map((mask: any) => mask.id),
      quality: imageQuality,
    };

    dispatch(downloadSemAnalysisReportRequest(payload));
  }, [params.fileId, params.batchId, analysisTypes, filteredData, imageQuality, dispatch]);

  const showResetMask = useMemo(() => {
    if (
      maskData &&
      maskData.length > 0 &&
      maskData.filter((c: any) => !c.display_flag).length > 0
    )
      return true;

    return false;
  }, [maskData]);

  const showResetParameters = useMemo(() => {
    let show = false;
    if (
      imageSubtraction?.default_parameters &&
      imageSubtraction?.parameters &&
      Object.keys(imageSubtraction?.default_parameters).length > 0 &&
      Object.keys(imageSubtraction?.parameters)
    ) {
      if (
        +imageSubtraction.default_parameters?.detection_threshold !==
        +imageSubtraction.parameters?.detection_threshold
      )
        show = true;
      if (
        imageSubtraction.parameters?.morphological_adjustment != null
      )
        show = true;
      if (
        imageSubtraction.default_parameters?.remove_lines !==
        imageSubtraction.parameters?.remove_lines
      )
        show = true;
      if (
        (imageSubtraction.default_parameters?.area_range[0] !==
          imageSubtraction.parameters?.area_range[0]) ||
        (imageSubtraction.default_parameters?.area_range[1] !==
          imageSubtraction.parameters?.area_range[1])
      )
        show = true;
    }

    return show;
  }, [imageSubtraction]);

  const updateShowAllMasksSettings = useCallback(
    (val: boolean) => {
      setMasksSettings((prev) => ({
        ...prev,
        [imgToShow]: {
          ...prev[imgToShow],
          showAllMasks: val,
        },
      }));
    },
    [imgToShow]
  );

  const updateShowBBoxSettings = useCallback(
    (val: boolean) => {
      setMasksSettings((prev) => ({
        ...prev,
        [imgToShow]: {
          ...prev[imgToShow],
          showBBox: val,
        },
      }));
    },
    [imgToShow]
  );

  const onDrawerClose = () =>
    history.push(
      `/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}/batch/${params.batchId}`
    );

  const resetMasks = () => {
    dispatch(
      resetMasksRequest({ file_id: params.fileId, batch_id: params.batchId })
    );
  };

  const deleteMasks = () => {
    dispatch(
      deleteMasksRequest({
        file_id: params.fileId,
        mask_ids: selectedMasks,
        batch_id: params.batchId,
      })
    );
  };

  const files = useMemo(() => {
    if (
      residualBatchInfo &&
      Array.isArray(residualBatchInfo) &&
      residualBatchInfo.length > 0
    ) {
      const currentBatch = residualBatchInfo.find((batch) => batch?.batch_id);

      return currentBatch?.thumbnails || [];
    }

    return [];
  }, [residualBatchInfo]);

  const goNext = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    const nextFileId = files[currentFileIndex + 1]?.file_id;

    history.push({
      pathname: `/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}/batch/${params.batchId}/files/${nextFileId}`,
      state: { masksSettings, imgToShow, imgOpacity },
    });
  };

  const goPrevious = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    const prevFileId = files[currentFileIndex - 1]?.file_id;

    history.push({
      pathname: `/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}/batch/${params.batchId}/files/${prevFileId}`,
      state: { masksSettings, imgToShow, imgOpacity },
    });
  };

  const hasPreviousFile = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    return currentFileIndex <= 0 ? false : true;
  };

  const hasNextFile = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    return currentFileIndex >= files.length - 1 ? false : true;
  };

  return (
    <Drawer
      title={t("sem.residualAnalysis")}
      placement="bottom"
      open={true}
      height="100%"
      styles={{
        body: { padding: 0 },
      }}
      onClose={onDrawerClose}
      className="paint-film-analysis-drawer"
      extra={
        <>
          {showResetParameters && (
            <StyledButton
              type="default"
              onClick={() =>
                fetchImageSubstractionMasks({
                  ...imageSubtraction?.default_parameters,
                  morphological_adjustment: null
                })
              }
              loading={imageSubtractionStatus === AsyncStates.LOADING}
              style={{ marginRight: 10 }}
            >
              {t("sem.resetAll")}
            </StyledButton>
          )}
          {showResetMask && (
            <StyledButton
              type="default"
              onClick={resetMasks}
              loading={resetMasksStatus === AsyncStates.LOADING}
              style={{ marginRight: 10 }}
            >
              {t("sem.resetDeletedMasks")}
            </StyledButton>
          )}
          {selectedMasks && selectedMasks.length > 0 && (
            <StyledButton
              type="primary"
              onClick={deleteMasks}
              loading={deleteMasksStatus === AsyncStates.LOADING}
              style={{ marginRight: 10 }}
            >
              {t("sem.deleteMasks")}
            </StyledButton>
          )}
          <StyledButton
            type="primary"
            onClick={downloadReport}
            loading={downloadSemAnalysisReportStatus === AsyncStates.LOADING}
            style={{ marginRight: 10 }}
          >
            {t("report.downloadReport")}
          </StyledButton>
          {files && files.length > 0 && (
            <>
              <StyledButton
                type="link"
                disabled={!hasPreviousFile()}
                onClick={goPrevious}
              >
                <LeftOutlined /> {t("sem.previousImage")}
              </StyledButton>
              <StyledButton type="link" disabled={!hasNextFile()} onClick={goNext}>
                {t("sem.nextImage")} <RightOutlined />
              </StyledButton>
            </>
          )}
        </>
      }
    >
      <div className="container">
        <ResidualAnalysisDetailsSidebar
          filteredData={filteredData}
          imgToShow={imgToShow}
          setImgToShow={setImgToShow}
          minArea={minArea}
          maxArea={maxArea}
          showAllMasks={masksSettings[imgToShow].showAllMasks}
          updateShowAllMasksSettings={updateShowAllMasksSettings}
          showBBox={masksSettings[imgToShow].showBBox}
          updateShowBBoxSettings={updateShowBBoxSettings}
          imgOpacity={imgOpacity}
          setImgOpacity={setImgOpacity}
          fetchImageSubstractionMasks={fetchImageSubstractionMasks}
        />
        <ResidualAnalysisDetailsContent
          showAllMasks={masksSettings[imgToShow].showAllMasks}
          filteredData={filteredData}
          image={chosenImage}
          showBBox={masksSettings[imgToShow].showBBox}
          selectedMasks={selectedMasks}
          setSelectedMasks={setSelectedMasks}
          imgOpacity={imgOpacity}
        />
      </div>
    </Drawer>
  );
};

export default ResidualAnalysisDetails;

import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  customTrainFailure,
  customTrainRequest,
  customTrainSuccess,
  filterDataFailure,
  filterDataRequest,
  filterDataSuccess,
  deleteModelFailure,
  deleteModelRequest,
  deleteModelSuccess,
  getCustomTraingsRequest,
  getCustomTraingsSuccess,
  getCustomTraingsFailure,
  filterDataFormulationSuccess,
  equationModelRequest,
  equationModelSuccess,
  equationModelFailure,
  equationModelListFailure,
  equationModelListSuccess,
  equationModelListRequest,
  equationSuccess,
  equationFailure,
  equationRequest,
  compareEquationSuccess,
  compareEquationFailure,
  compareEquationRequest,
  downloadEquationModelReportRequest,
  downloadEquationModelReportFailure,
  downloadEquationModelReportSuccess,
  deleteEquationRequest,
  deleteEquationSuccess,
  deleteEquationFailure,
} from "../actions/customML";
import {
  customDataTrainApi,
  filterDataApi,
  deleteCustomModelsApi,
  getCustomTraingsApi,
  customDataTrainMultistageApi,
  multiStageFilterDataApi,
  equationModelApi,
  equationModelListApi,
  equationApi,
  compareEquationApi,
  downloadEquationModelReportApi,
  deleteEquationApi,
} from "../../services/customML";
import { message, notification } from "antd";
import { modelsConfigListRequest } from "src/store/actions/formulate";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import jwtManager from "src/utils/jwtManager";
import { history } from "src";

function* filterDataSaga({
  payload: payloadData,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  const configs = yield select((state: StoreState) => state.configs.features);

  const { payload, isTrialBased = false } = payloadData;

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };

    const fetchDataApi = payload.is_multi_stage ? multiStageFilterDataApi : filterDataApi;

    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(
      fetchDataApi,
      { ...payload, use_processing_profiles: configs?.nestle_configs },
      headers
    );
    if (status === "Success") {
      if (!!data?.dataframe?.length) {
        if (isTrialBased) {
          yield put(filterDataFormulationSuccess(data));
        } else yield put(filterDataSuccess(data));
      } else {
        yield put(filterDataFailure());
        notification.error({
          message: messages[ln].trial_not_found_given_inputs,
        });
      }
    } else {
      yield put(filterDataFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(filterDataFailure());
    notification.error({
      message: messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* getCustomTrainingsSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message, total_count },
      },
    } = yield call(getCustomTraingsApi, payload, headers);
    if (status === "Success") {
      yield put(getCustomTraingsSuccess({ data, total_count }));
    } else {
      yield put(getCustomTraingsFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(filterDataFailure());
    notification.error({
      message: messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* equationModelListSaga(): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const res = yield call(equationModelListApi, headers);
    const {
      data,
      status, statusText: message
    } = res
    if (status === 200) {
      yield put(equationModelListSuccess(data));
    } else {
      yield put(equationModelListFailure());
      notification.error({
        message,
        duration: 10,
      });
    }
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notification.error({
        message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
      });
    } else {
      notification.error({
        message: messages[ln].internal_server_error,
        duration: 4,
      });
    }
    yield put(equationModelListFailure());
  }
}

function* equationModelSaga({ payload: { isMultiStage, ...restPayload } }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const res = yield call(isMultiStage ? customDataTrainMultistageApi : equationModelApi, restPayload, headers);
    const {
      data: {
        status, firebase_id, message, title, Type, project_name, project_id, created_by, created, run_id, work_orders },
    } = res
    if (status === "Success") {
      yield put(equationModelSuccess(firebase_id));
      history.push(`/ai-engine/new_model/eq_model/${firebase_id.split("|")[1]}`, { title, Type, project_name, project_id, created_by, created, status, run_id, work_orders })
    } else {
      yield put(equationModelFailure());
      notification.error({
        message,
        duration: 10,
      });
    }
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notification.error({
        message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
      });
    } else {
      notification.error({
        message: messages[ln].internal_server_error,
        duration: 4,
      });
    }
    yield put(equationModelFailure());
  }
}

function* equationSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const res = yield call(equationApi, payload, headers);
    const {
      data: {
        status, equation_result, message },
    } = res
    if (status === "Success") {
      yield put(equationSuccess(equation_result));
    } else {
      yield put(equationFailure());
      notification.error({
        message,
        duration: 10,
      });
    }
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notification.error({
        message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
      });
    } else {
      notification.error({
        message: messages[ln].internal_server_error,
        duration: 4,
      });
    }
    yield put(equationFailure());
  }
}

function* compareEquationSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const res = yield call(compareEquationApi, payload, headers);
    const {
      data: {
        status, result, message },
    } = res
    if (status === "Completed") {
      yield put(compareEquationSuccess(result));
    } else {
      yield put(compareEquationFailure());
      notification.error({
        message,
        duration: 10,
      });
    }
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notification.error({
        message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
      });
    } else {
      notification.error({
        message: messages[ln].internal_server_error,
        duration: 4,
      });
    }
    yield put(compareEquationFailure());
  }
}

function* deleteEquationSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const res = yield call(deleteEquationApi, payload, headers);
    const {
      data: {
        status, result, message },
    } = res
    if (status === "Success") {
      yield put(deleteEquationSuccess(result));
      yield put(equationModelListRequest());
      notification.success({
        message,
        duration: 10,
      });
    } else {
      yield put(deleteEquationFailure());
      notification.error({
        message,
        duration: 10,
      });
    }
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notification.error({
        message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
      });
    } else {
      notification.error({
        message: messages[ln].internal_server_error,
        duration: 4,
      });
    }
    yield put(deleteEquationFailure());
  }
}

function* customTrainSaga({ payload: { isMultiStage, ...restPayload } }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message, description },
      },
    } = yield call(isMultiStage ? customDataTrainMultistageApi : customDataTrainApi, restPayload, headers);
    if (status === "Success") {
      yield put(customTrainSuccess(data));
      notification.success({
        message,
        description,
        duration: 10,
      });
    } else {
      yield put(customTrainFailure());
      notification.error({
        message,
        duration: 10,
      });
    }
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notification.error({
        message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
      });
    } else {
      notification.error({
        message: messages[ln].internal_server_error,
        duration: 4,
      });
    }
    yield put(customTrainFailure());
  }
}

function* deleteCustomModelSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { message, status },
    } = yield call(deleteCustomModelsApi, payload, headers);

    if (status === "Success") {
      yield put(deleteModelSuccess());
      if (history?.location?.pathname?.includes("model-details")) {
        history.push("/ai-engine/model_analysis");
      }
      notification.success({ message, duration: 4 });
      yield put(modelsConfigListRequest());
      yield put(
        getCustomTraingsRequest({
          filter_name: "",
          page_num: 1,
          page_size: 10,
        })
      );
    } else {
      yield put(deleteModelFailure());
      notification.error({
        message: message,
        duration: 4,
      });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(deleteModelFailure());
  }
}

function* downloadEquationModelReportSaga({ payload }: any): Generator<any, any, any> {

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { download_link, status },
    } = yield call(downloadEquationModelReportApi, payload, headers);

    if (status === "Success") {
      yield put(downloadEquationModelReportSuccess(download_link));
    } else {
      yield put(downloadEquationModelReportFailure());
    }
  } catch (error) {
    yield put(downloadEquationModelReportFailure());
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(filterDataRequest, filterDataSaga);
  yield takeLatest(getCustomTraingsRequest, getCustomTrainingsSaga);
  yield takeLatest(customTrainRequest, customTrainSaga);
  yield takeLatest(compareEquationRequest, compareEquationSaga);
  yield takeLatest(deleteEquationRequest, deleteEquationSaga);
  yield takeLatest(equationRequest, equationSaga);
  yield takeLatest(equationModelListRequest, equationModelListSaga);
  yield takeLatest(equationModelRequest, equationModelSaga);
  yield takeLatest(downloadEquationModelReportRequest, downloadEquationModelReportSaga);
  yield takeLatest(deleteModelRequest, deleteCustomModelSaga);
}

import { Tooltip } from "antd"
import React from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"

type P = {
	value: string
	category?: string
	style?: React.CSSProperties
}

export const ParameterTooltip = ({ value, category }: P) => {
	const [t] = useTranslate()

	const ingredientsLables = useSelector((state: StoreState) => state.displayNames.data?.ingredients ?? {})
	const processingLables = useSelector((state: StoreState) => state.displayNames.data?.processing ?? {})
	const propertiesLables = useSelector((state: StoreState) => state.displayNames.data?.properties ?? {})

	return <Tooltip
		title={
			!!ingredientsLables?.[value]?.lot_no?.trim() ||
				!!ingredientsLables?.[value]?.supplier?.trim() ||
				!!ingredientsLables?.[value]?.sub_category?.trim() ||
				category ? (
				<>
					{category && (
						<div>
							{`${t('common.category')} : `}
							{category}
						</div>
					)}
					{!!ingredientsLables?.[value]?.sub_category?.trim() && (
						<div>
							{"Sub Category : "}
							{ingredientsLables?.[value]?.sub_category}
						</div>
					)}
					{!!ingredientsLables?.[value]?.lot_no?.trim() && (
						<div>
							{`${t("inventory.lot_no")}: `} {ingredientsLables?.[value]?.lot_no}
						</div>
					)}
					{!!ingredientsLables?.[value]?.supplier?.trim() && (
						<div>
							{"Supplier : "} {ingredientsLables?.[value]?.supplier}
						</div>
					)}
				</>
			) : (
				ingredientsLables?.[value]?.name ?? processingLables?.[value]?.name ?? propertiesLables?.[value]?.name ?? value
			)
		}
	>
		{ingredientsLables?.[value]?.name ?? processingLables?.[value]?.name ?? propertiesLables?.[value]?.name ?? value}
	</Tooltip>
}

import React, { useCallback, useMemo } from "react";
import { Tabs } from "antd";
import "mathlive";
import "./CustomML.scss";
import { Variables } from "./Variables";
import { Constraints } from "./Constraints";

export const Where = ({ extractedVariables, variables, constraints, updateConstraints, updateVariables }: {
  extractedVariables: string[],
  variables: {
    key: `${string}-${string}-${string}-${string}-${string}`;
    variable: string;
    type: string;
    value: string;
  }[],
  constraints: {
    key: `${string}-${string}-${string}-${string}-${string}`;
    variable: string;
    operator: string;
    value: string;
    valueType: string;
  }[],
  updateConstraints: React.Dispatch<React.SetStateAction<{
    key: `${string}-${string}-${string}-${string}-${string}`;
    variable: string;
    operator: string;
    value: string;
    valueType: string;
  }[]>>,
  updateVariables: React.Dispatch<React.SetStateAction<{
    key: `${string}-${string}-${string}-${string}-${string}`;
    variable: string;
    type: string;
    value: string;
  }[]>>
}) => {

  const autoDetect = useCallback(() => {
    const varItems = extractedVariables
      .filter(v => !variables.map(_v => _v.variable).includes(v))
      .map(v => ({
        key: crypto.randomUUID(),
        variable: v,
        type: "unknown",
        value: `Unknown`,
      }))
    updateVariables(state => [...varItems, ...state])
  }, [extractedVariables, updateVariables, variables])

  const disableAutoDetect = useMemo(() => extractedVariables.every(v => variables.map(_v => _v.variable).includes(v)), [extractedVariables, variables])

  const addVariable = useCallback(() => {
    updateVariables(state => [...state, {
      key: crypto.randomUUID(),
      variable: "",
      type: "unknown",
      value: `Unknown`,
    }])
  }, [updateVariables])

  const addConstraint = useCallback(() => {
    updateConstraints(state => [...state, {
      key: crypto.randomUUID(),
      variable: ``,
      operator: "=",
      value: ``,
      valueType: `constant`,
    }])
  }, [updateConstraints])

  const removeVariable = useCallback((key) => {
    updateVariables(state => state.filter(variable => variable.key !== key))
  }, [updateVariables])

  const removeConstraint = useCallback((key) => {
    updateConstraints(state => state.filter(constraint => constraint.key !== key))
  }, [updateConstraints])

  const onVarsChange = useCallback((change: any) => {
    updateVariables(change)
  }, [updateVariables])

  const onConstraintsChange = useCallback((change: any) => {
    updateConstraints(change)
  }, [updateConstraints])

  return (
    <Tabs
      className="eq-where"
      items={[
        {
          label: "Variables",
          key: "variables",
          children: <Variables autoDetect={autoDetect} data={variables} add={addVariable} remove={removeVariable} update={onVarsChange} disableAutoDetect={disableAutoDetect} />
        },
        {
          label: "Constraints",
          key: "constraints",
          children: <Constraints data={constraints} varsData={variables} add={addConstraint} remove={removeConstraint} update={onConstraintsChange} />
        }
      ]} />
  );
};

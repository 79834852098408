import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  Collapse,
  Empty,
  Flex,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
  // Typography,
  Col,
  Descriptions,
  message,
  DescriptionsProps,
  Dropdown,
  MenuProps,
  Typography,
  Modal,
} from "antd";
import { antdTheme, AsyncStates, defaultHeaders } from "src/constants";
import { CloseOutlined, DownloadOutlined, DownOutlined, LeftOutlined, LoadingOutlined, MinusSquareOutlined, RightOutlined } from "@ant-design/icons";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  compareEquationClear,
  customTrainClear,
  downloadEquationModelReportRequest,
  equationClear,
  equationModelListRequest,
  equationModelRequest,
} from "src/store/actions/customML";
import { setIsEditing } from "src/store/actions/isEditing";
import { StyledCard } from "src/styled_components/StyledCard";
import "mathlive";
import "./CustomML.scss";
import { MathfieldElement, renderMathInDocument } from "mathlive";
import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { EQ_MODEL_RUNS, firestoreDb } from "src/utils/firebase";
import jwtManager from "src/utils/jwtManager";
import { MathJax } from "better-react-mathjax"
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { StyledInput } from "src/styled_components/StyledInput";
import { useLocation, useParams } from "react-router-dom";
import { Where } from "./Where";
import { CompareDrawer } from "./Compare";
import { EquationModelViewData } from "./EquationModelViewData";
import dayjs from "dayjs"
import { equationsDB } from "src/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useValue } from "src/utils/useValue";

export const EQ_TYPES = [
  { value: "linear", label: "Linear" },
  { value: "polynomial", label: "Polynomial" },
  { value: "gaussian", label: "Gaussian" },
  { value: "generalised_nonlinear", label: "Generalised Non-linear" },
]

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>;
    }
  }
}

// const { Text } = Typography;

export const EquationModelResult = ({
  filterData,
  createModalPayload,
  filters,
  submitDataFilter,
  pagination,
  dataCount,
  isMultiStage,
  ignoredData,
  selectedWorkOrders,
  workOrders,
  filterDataStatus
}: any) => {
  const { runid } = useParams<{ runid: string }>()
  const { state } = useLocation<any>()

  const [form] = Form.useForm();
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { current } = useSelector(
    (state: StoreState) => state.projects
  );

  const { equationModelStatus, equationModelData: firebase_id } = useSelector(
    (state: StoreState) => state.CustomML
  );

  const runId = useMemo(() => firebase_id.split("|")[1], [firebase_id])
  const _runid = useMemo(() => runid || runId, [runId, runid])
  const configs = useSelector((state: StoreState) => state.configs.features);
  const [type, setType] = useState("ingredients");
  const [, setModalVisible] = useState<boolean>(false);
  const [userIgnoredData, setUserIgnoredData] = ignoredData
  const [activeWorkOrderId, setActiveWorkOrderId] = useState(selectedWorkOrders[0]);
  const [stage, setStage] = useState();
  const initial = useRef(true);
  const [availableStages, setAvailableStages] = useState([]);
  const [
    // totalTrials
    , setTotalTrials] = useState();
  const [equationModelData, setEquationModelData] = useState<any>(undefined);

  useEffect(() => {
    if (filterData?.total_formulation_ids_count) setTotalTrials(filterData?.total_formulation_ids_count)
  }, [filterData]);

  useEffect(() => {
    if (equationModelStatus === AsyncStates.SUCCESS) {
      setModalVisible(false);
      form.resetFields();
      dispatch(customTrainClear());
    }
  }, [equationModelStatus, dispatch, form]);

  useEffect(() => {
    if (current) setUserIgnoredData({ ingredients: [], properties: [], processing: [], formulations: [] })
  }, [current, setUserIgnoredData])

  useEffect(() => {
    if (Array.isArray(filterData?.available_stages)) {
      setAvailableStages(filterData.available_stages);

      if (initial.current) {
        setStage(filterData.available_stages[0]);
      }
    }
  }, [filterData.available_stages]);

  useEffect(() => {
    if (!isMultiStage) submitDataFilter(1, 10, activeWorkOrderId);
    else submitDataFilter(1, 10, activeWorkOrderId, stage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkOrderId]);
  // Seperated to check which dep changed
  useEffect(() => {
    // Prevent duplicate api call on 1st stage set
    if (!stage) return;
    if (initial.current) {
      initial.current = false;
      return;
    }

    if (!isMultiStage) submitDataFilter(1, 10, activeWorkOrderId);
    else submitDataFilter(1, 10, activeWorkOrderId, stage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  const updateStage = (add: number) => {
    const stages = availableStages as Array<string>;
    const currentStageIndex = stages.findIndex(s => s === stage);
    const newIndex = currentStageIndex + add;

    if (newIndex > (stages.length - 1)) setStage(availableStages[0]);
    else if (newIndex < 0) setStage(availableStages[(stages.length - 1)]);
    else setStage(availableStages[newIndex]);
  }

  const { equationModelListData } = useSelector(
    (state: StoreState) => state.CustomML
  );

  const [runTitle, setRunTitle] = useState(equationModelListData.find(eq => eq.run_id === _runid)?.title)

  const customDataTrain = () => {
    if (!runTitle) {
      message.warning({ content: "Please enter Model Name!" })
      return
    }

    const payload = {
      ...createModalPayload,
      ignore_ingredients: userIgnoredData?.ingredients || {},
      ignore_properties: userIgnoredData?.properties || {},
      ignore_processings: userIgnoredData?.processing || {},
      ignore_formulation_ids: userIgnoredData?.formulations || {},
      ...(Boolean(configs?.processing_profiles) && {
        use_processing_profiles: true,
      }),
      custom_equation: false
    };
    if (isMultiStage) {
      delete payload['input_types']
      delete payload['output_types']
      delete payload['page_num']
      delete payload['page_size']
    }
    dispatch(equationModelRequest({ ...payload, "run_title": runTitle, isMultiStage }));
    dispatch(setIsEditing(false));
  };

  const formatTabName = (name: string, woId: string) => {
    const formattedName = name ? name : woId;
    return formattedName.length < 11 ? formattedName : <Tooltip title={formattedName}>{formattedName.substring(0, 10)}...</Tooltip>
  }

  const onEquationChange = useCallback((key: string, latex: string) => {
    setCustomEqs(state => state.map(eq => eq.key === key ? { ...eq, latex } : eq))
  }, [])

  const [eqType, setEqType] = useState("linear");
  const [
    loadingRun
    , setLoadingRun
  ] = useState<any>(undefined);

  const [captureProgress, setCaptureProgress] = useState(false)

  const listenToProcessingStatus = useCallback((doc_id) => {
    setCaptureProgress(true)
    let unsub: Unsubscribe;
    const taskDocRef = doc(
      firestoreDb,
      `${EQ_MODEL_RUNS}/${doc_id}`
    );

    unsub = onSnapshot(taskDocRef, (doc) => {
      const taskDoc = doc.data();
      if (taskDoc) {
        console.log("🚀 ~ unsub=onSnapshot ~ taskDoc:", taskDoc)
        setLoadingRun(taskDoc);

        if (taskDoc?.["status"] === "Failed" || taskDoc?.["status"] === "Completed") {
          unsub && unsub();
          setCaptureProgress(false)
          fetch(process.env.REACT_APP_ML_URL + "get_equations_models_result", {
            method: "POST",
            headers: {
              ...defaultHeaders,
              token: jwtManager.getToken() ?? "",
            },
            body: JSON.stringify({ run_ids: [_runid] })
          })
            .then(res => res.json()).then(res => {
              setisCustomEqsEnabled(res.custom_equation)
              if (res.status === "Completed") {
                setEquationModelData(res.result)
                setUserIgnoredData((state: any) => ({
                  "ingredients": res.ignore_ingredients,
                  "properties": res.ignore_properties,
                  "processing": res.ignore_processings,
                  "formulations": res.ignore_formulation_ids
                }))
                if (res.custom_equation) {
                  setCustomEqs(res.result?.input_equations?.equations?.map((eq: string) => ({ key: crypto.randomUUID(), latex: eq, final: false })))
                  setVariables(res.result?.input_equations?._variables)
                  setConstraints(res.result?.input_equations?._constraints)
                }
              } else {
                message.warning({ content: res.warnings.error })
              }
              dispatch(equationModelListRequest())
            })
            .catch(err => {
              console.log(err)
            })
        }
      }
    });
  }, [_runid, dispatch, setUserIgnoredData]);

  useEffect(() => {
    firebase_id.length && listenToProcessingStatus(firebase_id);
  }, [listenToProcessingStatus, firebase_id]);

  useLayoutEffect(() => {
    renderMathInDocument()
  }, [])

  const [isCustomEqsEnabled, setisCustomEqsEnabled] = useState(false)
  console.log("🚀 ~ isCustomEqsEnabled:", isCustomEqsEnabled)
  const [customEqs, setCustomEqs] = useState<{ key: string, latex: string, final: boolean }[]>([
    { key: crypto.randomUUID(), latex: "", final: true },
  ])

  const extractedVariables = useMemo(() => {
    const eqs = customEqs?.map(eq => eq.latex)
    const latexSymbols = [
      "cdot", "exp", "frac", "left", "right", "times", "sum", "prod", "coprod", "int", "oint", "iint", "biguplus", "bigcap", "bigcup", "bigoplus", "bigotimes", "bigodot", "bigvee", "bigwedge", "bigsqcup", "arccos", "arcsin", "arctan", "arg", "cos", "cosh", "cot", "coth", "csc", "deg", "det", "dim", "gcd", "hom", "inf", "ker", "lg", "lim", "liminf", "limsup", "ln", "log", "max", "min", "Pr", "sec", "sin", "sinh", "sup", "tan", "tanh",
    ]
    const vars: string[] = []
    const variableRegex = /\\([a-zA-Z]+)|([a-zA-Z_](?:\^\\circ\s*)*[a-zA-Z0-9_]*(?:\{[a-zA-Z0-9_]+\})?)/g;
    eqs?.forEach(eq => {
      vars.push(...(eq.match(variableRegex) || []))
    })
    return Array.from(new Set(vars.filter(v => !latexSymbols.some(s => v.includes(s)))))
  }, [customEqs])

  const setFinal = (key: string, final: boolean) => {
    setCustomEqs(state => final ?
      state.map(eq => eq.key === key ? { ...eq, final } : { ...eq, final: false })
      : state
    )
  }


  const [variables, setVariables] = useState<any[]>([])

  const [constraints, setConstraints] = useState<any[]>([])

  const updateVariables = useCallback((newState) => {
    setVariables(newState)
  }, [])

  const updateConstraints = useCallback((newState) => {
    setConstraints(newState)
  }, [])

  const customDataTrain2 = () => {
    if (!runTitle) {
      message.warning({ content: "Please enter Model Name!" })
      return
    }
    const payload = {
      "latex_eq": {
        "equations": customEqs.map(eq => eq.latex),
        "unknowns": variables.filter(_var => _var.type === "unknown").map(_var => _var.variable),
        "input_var_mappings": variables.filter(_var => _var.type !== "unknown" && _var.variable !== customEqs.find(eq => eq.final)?.latex.split("=")[0]).reduce((acc, _var) => ({ ...acc, [_var.variable]: _var.value }), {}),
        "output_var_mappings": variables.filter(_var => _var.variable === customEqs.find(eq => eq.final)?.latex.split("=")[0]).reduce((acc, _var) => ({ ...acc, [_var.variable]: _var.value }), {}),
        "constraints": constraints.map(constraint => {
          if (constraint.operator === "range")
            return [constraint.variable, constraint.operator, constraint.valueMin, constraint.valueMax]
          return [constraint.variable, constraint.operator, constraint.value]
        }),
        _variables: variables,
        _constraints: constraints,
        _final_index: customEqs.findIndex((eq, idx) => eq.final)
      },
      "run_title": runTitle,
      "custom_equation": true,
      "work_order_ids": selectedWorkOrders,
      ignore_ingredients: userIgnoredData?.ingredients || {},
      ignore_properties: userIgnoredData?.properties || {},
      ignore_processings: userIgnoredData?.processing || {},
      ignore_formulation_ids: userIgnoredData?.formulations || {},
    }
    dispatch(equationModelRequest({ ...payload, isMultiStage }));
    dispatch(setIsEditing(false));
  };

  const [showCompare, setShowCompare] = useState(false)

  useEffect(() => {
    if (_runid && _runid !== "new") {
      fetch(process.env.REACT_APP_ML_URL + "get_equations_models_result", {
        method: "POST",
        headers: {
          ...defaultHeaders,
          token: jwtManager.getToken() ?? "",
        },
        body: JSON.stringify({ run_ids: [_runid] })
      })
        .then(res => res.json()).then(res => {
          setisCustomEqsEnabled(res.custom_equation)
          if (res.custom_equation) {
            setCustomEqs(res.result?.input_equations?.equations?.map((eq: string) => ({ key: crypto.randomUUID(), latex: eq, final: false })))
            setVariables(res.result?.input_equations?._variables)
            setConstraints(res.result?.input_equations?._constraints)
          }
          setEquationModelData(res.result)
          setUserIgnoredData((state: any) => ({
            "ingredients": res.ignore_ingredients,
            "properties": res.ignore_properties,
            "processing": res.ignore_processings,
            "formulations": res.ignore_formulation_ids
          }))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [_runid, setUserIgnoredData])

  const members = useSelector((state: StoreState) => state.teams.data)
  const downloadLink = useSelector((state: StoreState) => state.CustomML.downloadEquationModelReportData)

  const details = useMemo(() => {
    const items: DescriptionsProps['items'] = [
      // {
      //   key: '1',
      //   label: 'Model Name',
      //   children: state.title,
      // },
      {
        key: '2',
        label: 'Type',
        children: state?.Type ? "Custom" : "Default",
      },
      {
        key: '3',
        label: 'Project',
        children: state?.project_name,
      },
      {
        key: '4',
        label: 'Created by',
        children: members.find(member => member.user_id === state?.created_by)?.user_name,
      },
      {
        key: '5',
        label: 'Created on',
        children: dayjs(state?.created).format("DD MMM YYYY HH:mm"),
      },
      {
        key: '6',
        label: 'Status',
        children: (captureProgress ? (loadingRun?.status?.toLowerCase?.()?.includes?.("progress") ? (
          <Space>
            <LoadingOutlined />
            {loadingRun?.percent_completed}
          </Space>
        ) : loadingRun?.status) : state?.status),
      },
    ];
    return items
  }, [state?.Type, state?.project_name, state?.created, state?.status, state?.created_by, members, captureProgress, loadingRun?.status, loadingRun?.percent_completed])

  useEffect(() => {
    return () => {
      dispatch(equationClear())
      dispatch(compareEquationClear())
    }
  }, [dispatch])

  const [precision, setPrecision] = useState(3)

  const withPrecision = useCallback((latex: string) => {
    const newLatex = latex?.replaceAll(/([\d|.]+)/g, match => match.includes(".") ? Number(match).toFixed(precision) : match)
    return newLatex
  }, [precision])

  useEffect(() => {
    if (state?.Type) {
      dispatch(downloadEquationModelReportRequest({ run_id: _runid }))
    }
  }, [dispatch, _runid, state?.Type])

  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    const eqs = await equationsDB.equations.get(Number(e.key))
    if (eqs) {
      setCustomEqs(eqs.equations)
      setVariables(eqs.variables)
      setConstraints(eqs.constraints)
    }
  };

  const savedEqs = useLiveQuery(
    () => equationsDB.equations.toArray()
  );

  const items = useMemo(() => savedEqs?.map(eq => ({
    key: eq.id,
    label: <Space size={"large"} align="baseline"><Typography.Text>{eq.name}</Typography.Text><CloseOutlined onClick={async () => { await equationsDB.equations.delete(Number(eq.id)) }} /></Space>
  })), [savedEqs]);

  const menuProps = {
    items,
    selectable: true,
    onClick: handleMenuClick,
  };

  const [name, setName] = useState("Equation_" + crypto.randomUUID())

  const handleButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true)
  };

  const [open, setOpen] = useState(false)

  const saveEq = async () => {
    await equationsDB.equations.put({ equations: customEqs, variables, constraints, name })
    setOpen(false)
  };

  const renderedEqs = useMemo(() => {
    return (
      equationModelData?.input_equations?.equations
        ?.map((eq: string) => {
          let newEq = eq;
          Object.entries(equationModelData?.esimated_parameter_values || {})
            ?.forEach(([est, val]) => {
              newEq = newEq.replace(est, `{\\color{teal}(${val})}`)
            })
          return newEq
        })
    )
  }, [equationModelData?.esimated_parameter_values, equationModelData?.input_equations?.equations])

  const whereVariables = useMemo(() => Object.entries(
    Object.values(
      equationModelData?.[activeWorkOrderId]?.[eqType] || {})
      ?.reduce((a, o) => ({ ...a, ...o?.dummy }), {}) || {}
  )
    ?.map(([parameter, variable]) => ({ key: variable, variable, parameter })),
    [activeWorkOrderId, eqType, equationModelData])

  return (
    <>

      {/* Save Eq. Modal */}
      <Modal title="Name" open={open} onOk={saveEq} onCancel={() => { setOpen(false) }}>
        <StyledInput placeholder="Name" onChange={e => { setName(e.target.value) }} />
      </Modal>

      {/* Results */}
      <Space direction="vertical" style={{ width: "100%" }}>

        {/* Model Description */}
        {(!!_runid && _runid !== "new") && <Descriptions size="small" colon={false} column={5} title="Model Info" layout="vertical" items={details} />}

        {/* Equations */}
        <StyledCard title={"Equation(s)"}>

          {/* Model Name */}
          {_runid === "new" && (
            <Form.Item
              label="Model Name"
              name="modelName"
            >
              <StyledInput placeholder="Model name" onChange={(e) => { setRunTitle(e.target.value) }} defaultValue={runTitle} value={runTitle} />
            </Form.Item>
          )}<br />

          {/* Custom Eqs. */}
          {isCustomEqsEnabled && <Flex vertical gap={8} justify="flex-start" style={{ paddingBottom: "24px" }}>
            {customEqs?.map((eq) => (
              <Space key={eq.key}>
                <MinusSquareOutlined
                  onClick={() => {
                    if (_runid === "new")
                      setCustomEqs(state => state.filter(_eq => _eq.key !== eq.key)
                        .map((eq, idx, arr) => arr.some(eq => eq.final) ?
                          eq
                          : idx === (arr.length - 1) ?
                            { ...eq, final: true }
                            : eq)
                      )
                  }} />
                {(_runid === "new") ? <math-field
                  onInput={(evt: any) => onEquationChange(eq.key, evt.target?.value)}
                >
                  {eq.latex}
                </math-field> :
                  <math-field
                    read-only
                    onInput={(evt: any) => onEquationChange(eq.key, evt.target?.value)}
                  >
                    {eq.latex}
                  </math-field>}
                <Checkbox disabled={!!_runid && _runid !== "new"} checked={eq.final} onChange={e => { setFinal(eq.key, e.target.checked) }}>Final equation</Checkbox>
              </Space>
            ))}<br />
            {_runid === "new" && <StyledButton style={{ textAlign: "start" }} size="large" type="link" onClick={() => { setCustomEqs(state => [...state, { key: crypto.randomUUID(), latex: "", final: state.length ? false : true }]) }}>+ Add equation</StyledButton>}
            <Where extractedVariables={extractedVariables} constraints={constraints} updateConstraints={updateConstraints} variables={variables} updateVariables={updateVariables} />
          </Flex>}<br />

          {equationModelData && (<>



            {/* Results - Eq. Modelling */}
            {(!isCustomEqsEnabled) && <Collapse
              items={[
                {
                  key: "get-data",
                  label: <Typography.Title level={5}>Results</Typography.Title>,
                  children: (<>
                    <Radio.Group onChange={(e: RadioChangeEvent) => {
                      setEqType(e.target.value);
                    }} value={eqType}
                      options={EQ_TYPES}
                    />
                    <br /><br />
                    <Tabs
                      size="small"
                      tabBarExtraContent={(
                        <Form.Item
                          label="Precision"
                          name="precision"
                          rules={[{ required: true, message: 'Please input precision!' }]}
                        >
                          <StyledInput min={0} step={1} type="number" size="small" placeholder="Precision" onChange={(e) => { setPrecision(Math.round(Number(e.target.value))) }} defaultValue={precision} value={precision} />
                        </Form.Item>
                      )}
                      defaultActiveKey={activeWorkOrderId}
                      tabPosition={"top"}
                      moreIcon={<DownOutlined />}
                      activeKey={activeWorkOrderId}
                      onTabClick={(key) => { setActiveWorkOrderId(key); setType('ingredients'); }}
                      items={Object.keys(equationModelData).map((woId: string) => {
                        return {
                          label: formatTabName(workOrders.find((wo: any) => woId === wo.work_order_id)?.work_order_name, woId),
                          key: woId,
                          children: (
                            <>
                              <Row justify="center">
                                <Spin
                                  style={{ marginTop: "30px" }}
                                  spinning={filterDataStatus === AsyncStates.LOADING}
                                  indicator={<LoadingOutlined />}
                                ></Spin>
                              </Row>
                              {/* {!filterData?.dataframe?.length && filterDataStatus !== AsyncStates.LOADING && (<Empty style={{ marginTop: "30px" }} />)} */}
                              <Table
                                style={{ marginTop: 24, position: "relative" }}
                                size="large"
                                columns={[{
                                  title: "Properties Equations",
                                  dataIndex: "equation_dummy",
                                  key: "Properties Equations",
                                  render: (text: any, record: any, index: number) => {
                                    return (
                                      <MathJax style={{ fontSize: antdTheme.fontSizeLG }} dynamic={true}>
                                        {`\\({\\color{${`#7C9E3D`}}${record.Properties}} = ${withPrecision(text)}\\)`}
                                      </MathJax>
                                    )
                                  }
                                },
                                {
                                  title: "Error (MAPE %)",
                                  dataIndex: "Error",
                                  key: "Error",
                                }
                                ]}
                                dataSource={Object.entries(equationModelData[woId][eqType] || {}).map(([Properties, Cols]: any) => ({ Properties, equation: Cols.equation, Error: Cols.mape, dummy: Cols.dummy, equation_dummy: Cols.equation_dummy }))} pagination={false} />
                            </>
                          ),
                        };
                      })}
                    /><br />
                    <Table
                      title={() => "Where,"}
                      bordered={true}
                      style={{ maxWidth: 500 }}
                      size="small"
                      columns={[
                        {
                          key: "variable", dataIndex: "variable", title: "Variable",
                          render: (text: any, record: any, index: number) => {
                            return (
                              <MathJax style={{ fontSize: antdTheme.fontSizeLG }} dynamic={true}>
                                {`\\(${text}\\)`}
                              </MathJax>
                            )
                          }
                          },
                        { key: "parameter", dataIndex: "parameter", title: "Parameter" }
                      ]}
                      dataSource={whereVariables}
                      pagination={false}
                    />
                  </>),
                  extra: isCustomEqsEnabled ? (downloadLink ? <Tooltip title="Export Report"><a className="download" href={downloadLink} target="_blank" rel="noreferrer"><DownloadOutlined /></a></Tooltip> : null) : <StyledButton type="primary" onClick={e => { e.stopPropagation(); setShowCompare(true) }} size="small">Compare</StyledButton>
                },
              ]}
              onChange={(key) => {
              }}
              defaultActiveKey={["get-data"]}
            />}

            {/* Results - Kinetic */}
            {(isCustomEqsEnabled && (equationModelData?.esimated_parameter_values || equationModelData?.error_metrics || equationModelData?.calculated_output)) && <Collapse
              items={[
                {
                  key: "get-data",
                  label: "Results",
                  children: (<>
                    <Row>
                      <Col span={24}>
                        <Table
                          title={() => "Estimated Unknowns"}
                          columns={[
                            {
                              key: "unknown",
                              title: "Unknown",
                              dataIndex: "unknown",
                              render: (text: any, record: any, index: number) => {
                                return (
                                  <MathJax style={{ fontSize: antdTheme.fontSizeLG }} dynamic={true}>
                                    {`\\(${text}\\)`}
                                  </MathJax>
                                )
                              }
                            },
                            { key: "estimate", title: "Estimate", dataIndex: "estimate" }
                          ]}
                          dataSource={Object.entries(equationModelData?.esimated_parameter_values || {}).map(([unknown, estimate]) => ({ unknown, estimate }))}
                          pagination={false}
                        />
                      </Col>
                    </Row><br />
                    <Row gutter={16}>
                      <Col span={8}>
                        <Descriptions title="Metric table" bordered column={1} items={Object.entries(equationModelData?.error_metrics || {}).map(([metric, value]) => ({
                          key: metric,
                          label: metric.replaceAll("_", " ").toUpperCase(),
                          children: value,
                        }))} />
                      </Col>
                      <Col span={16}>
                        {Boolean(equationModelData?.actual_output?.length) && <Plot equationModelData={equationModelData} />}
                      </Col>
                    </Row><br />
                    {true && <Collapse
                      defaultActiveKey={["eq-data"]}
                      items={[
                        {
                          key: "eq-data",
                          label: "Equations",
                          children: (<StyledCard type="inner">
                            <Form.Item
                              label="Precision"
                              name="precision"
                              rules={[{ required: true, message: 'Please input precision!' }]}
                            >
                              <StyledInput style={{ width: 80 }} min={0} step={1} type="number" size="small" placeholder="Precision" onChange={(e) => { setPrecision(Math.round(Number(e.target.value))) }} defaultValue={precision} value={precision} />
                            </Form.Item>
                            <Flex gap={16} vertical>
                              {renderedEqs.map((eq: string) => (
                                <MathJax dynamic={true}>{`\\(${withPrecision(eq)}\\)`}</MathJax>
                              ))}
                            </Flex>
                          </StyledCard>
                          )
                        }]}
                    />}
                  </>),
                  extra: isCustomEqsEnabled ? (downloadLink ? <Tooltip title="Export Report"><a className="download" href={downloadLink} target="_blank" rel="noreferrer"><DownloadOutlined /></a></Tooltip> : null) : <StyledButton type="primary" onClick={e => { e.stopPropagation(); setShowCompare(true) }} size="small">Compare</StyledButton>
                },
              ]}
              onChange={(key) => {
              }}
              defaultActiveKey={["get-data"]}
            />}

          </>)}


          {/* Action Buttons */}
          {_runid === "new" && <Flex justify="end">
            <Space>
              <Checkbox checked={isCustomEqsEnabled} onChange={e => { setisCustomEqsEnabled(e.target.checked) }}>Custom Equation</Checkbox>
              {isCustomEqsEnabled && <Dropdown.Button menu={menuProps as any} onClick={handleButtonClick}>
                Save
              </Dropdown.Button>}
              <Tooltip title={t("customML.useDataForModelTraining")}>
                <span>
                  <StyledButton
                    onClick={isCustomEqsEnabled ? customDataTrain2 : customDataTrain}
                    type="primary"
                    loading={equationModelStatus === AsyncStates.LOADING}
                  >
                    {isCustomEqsEnabled ? "Find Unknowns" : "Generate Equations"}
                  </StyledButton>
                </span>
              </Tooltip>
            </Space>
          </Flex>}<br />

          {/* Work Orders Data */}
          <Collapse
            defaultActiveKey={["work-orders-data"]}
            items={[
              {
                key: "work-orders-data",
                label: "Work Orders Data",
                children: <Tabs
                  defaultActiveKey={activeWorkOrderId}
                  tabPosition={"top"}
                  moreIcon={<DownOutlined />}
                  activeKey={activeWorkOrderId}
                  onTabClick={(key) => { setActiveWorkOrderId(key); setType('ingredients'); }}
                  items={selectedWorkOrders.map((woId: string) => {
                    return {
                      label: formatTabName(workOrders.find((wo: any) => woId === wo.work_order_id)?.work_order_name, woId),
                      key: woId,
                      children: (
                        <>
                          <Row justify="center">
                            <Spin
                              style={{ marginTop: "30px" }}
                              spinning={filterDataStatus === AsyncStates.LOADING}
                              indicator={<LoadingOutlined />}
                            ></Spin>
                          </Row>
                          {!filterData?.dataframe?.length && filterDataStatus !== AsyncStates.LOADING && (<Empty style={{ marginTop: "30px" }} />)}
                          {!!filterData?.dataframe?.length && (
                            <EquationModelViewData
                              filterData={filterData?.dataframe}
                              propertyTrainWarnings={filterData.property_train_warnings}
                              from={"custom_ml"}
                              submitDataFilter={submitDataFilter}
                              pagination={pagination}
                              dataCount={dataCount}
                              UserIgnoredDataInfo={[userIgnoredData, setUserIgnoredData]}
                              isMultiStage={isMultiStage}
                              activeWorkOrderId={activeWorkOrderId}
                              typeInfo={[type, setType]}
                              stage={stage}
                              isEquationModel={false}
                              runId={_runid}
                            />
                          )}
                          <Row justify="space-between" style={{ paddingBottom: "24px" }}>
                            <div>
                              {isMultiStage && availableStages.length > 0 && stage && (
                                <>
                                  <span onClick={() => updateStage(-1)} className="left-button"><LeftOutlined /></span>
                                  <Select
                                    className="custom-select"
                                    value={stage}
                                    style={{ width: 120 }}
                                    onChange={(value) => setStage(value)}
                                    options={availableStages.map((stage: string) => ({ label: stage, value: stage }))}
                                  />
                                  <span onClick={() => updateStage(1)} className="right-button"><RightOutlined /></span>
                                </>
                              )}
                              {/* <Space style={{ marginLeft: "16px" }}>
                                  <Row justify="center" align="middle" style={{ gap: "8px" }}>
                                    <Text className="info-text">{t("common.totalWorkOrders")}: {selectedWorkOrders.length}</Text>
                                    <svg height="4" width="4">
                                      <circle cx="2" cy="2" r="2" stroke="#595959" stroke-width="0" fill="#595959" />
                                    </svg>
                                    <Text className="info-text">{t("common.totalTrials")}: {totalTrials}</Text>
                                  </Row>
                                </Space> */}
                            </div>
                          </Row>
                        </>
                      ),
                    };
                  })}
                />
              }
            ]}
          />

        </StyledCard>
      </Space>
      {showCompare && <CompareDrawer open={showCompare} onClose={() => { setShowCompare(false) }} equationModelData={equationModelData} runId={_runid} />}
    </>
  );
};

const Plot = ({ equationModelData }: any) => {
  const [t] = useTranslate()
  const { getValue: getEUValue } = useValue();

  const options = useMemo(() => {
    const options = {
      chart: { type: "scatter" },
      title: { text: "Performance Plot" },
      legend: {
        verticalAlign: 'top',
      },
      xAxis: {
        title: { text: "Actual" },
        // categories: equationModelData?.actual_output || [],
        // tickPixelInterval: 50,
        // ...((equationModelData?.calculated_output && equationModelData?.actual_output) && {
        //   floor: Math.min(...equationModelData?.calculated_output, ...equationModelData?.actual_output),
        //   ceiling: Math.max(...equationModelData?.calculated_output, ...equationModelData?.actual_output)
        // })
      },
      yAxis: {
        title: { text: "Predicted" },
        // tickPixelInterval: 50,
        // ...((equationModelData?.calculated_output && equationModelData?.actual_output) && {
        //   floor: Math.min(...equationModelData?.calculated_output, ...equationModelData?.actual_output),
        //   ceiling: Math.max(...equationModelData?.calculated_output, ...equationModelData?.actual_output)
        // })
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "line",
          name: "Ideal fit line",
          data: equationModelData?.actual_output.map((y: number, i: number) => [y, y]) || [],
          marker: {
            enabled: false
          },
          connectNulls: true,
          tooltip: {
            headerFormat: '',
            pointFormatter: function (this: any) {
              return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(equationModelData?.calculated_output[this.index])}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
            },
          }
        },
        {
          type: "scatter",
          name: Object.values(equationModelData.input_equations.output_var_mappings || {}),
          // keys: ["y"],
          data: equationModelData?.calculated_output.map((y: number, i: number) => [equationModelData?.actual_output[i], y]) || [],
          tooltip: {
            pointFormatter: function (this: any) {
              return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(this.y)}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
            },
          },
        },
      ],
    }
    return options
  }, [equationModelData?.actual_output, equationModelData?.calculated_output, equationModelData.input_equations.output_var_mappings, getEUValue, t])
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
import { sendDeleteImageAnalysis, sendGetImageAnalysis, sendPostImageAnalysis } from "src/utils/sendApi";

export const fetchSemAnalysisDataApi = sendPostImageAnalysis(`image-analysis/analyse`);

export const fetchSemAnalysisMaskDataApi = sendPostImageAnalysis(`image-analysis/get_masks`);

export const deleteSemAnalysisFilesApi = (analysis_type: any) => sendDeleteImageAnalysis(`image-analysis/${analysis_type}`);

export const fetchSemAnalysisListApi = (analysisType: string) => sendGetImageAnalysis("image-analysis/" + analysisType);

export const fetchSemAnalysisScaleApi = sendPostImageAnalysis("image-analysis/calibrate_image_scale");

export const fetchSemAnalysisMaskHeightWidthApi = (id: any, analysis_type: any) => sendGetImageAnalysis(`image-analysis/user_generated_mask_attributes/${analysis_type}/${id}`);

export const setSemAnalysisMaskHeightWidthApi = (id: any, analysis_type: any) => sendPostImageAnalysis(`image-analysis/user_generated_mask_attributes/${analysis_type}/${id}`);

export const deleteSemAnalysisMaskHeightWidthApi = (id: any, analysis_type: any) => sendDeleteImageAnalysis(`image-analysis/user_generated_mask_attributes/${analysis_type}/${id}`);

export const setImageSubtractionApi = sendPostImageAnalysis("image-analysis/image-subtraction");

export const fetchImageSubtractionApi = sendPostImageAnalysis(`image-analysis/image-subtraction/get_masks`);

export const fetchInstanceDetectionApi = sendPostImageAnalysis("image-analysis/instance-detection");

export const classificationAnalysisApi = (id: any) => sendGetImageAnalysis(`image-analysis/classify/${id}`);

export const downloadSemAnalysisReportApi = sendPostImageAnalysis("image-analysis/export_report/pdf");

export const deleteMasksApi = sendDeleteImageAnalysis("image-analysis/image-subtraction/delete_anomaly_masks");

export const updateClassificationApi = sendPostImageAnalysis("image-analysis/change_classification");

export const resetMasksApi = (batch_id: string, file_id: string) => sendGetImageAnalysis(`image-analysis/image-subtraction/reset_anomaly_masks/${batch_id}/${file_id}`);

export const processPaintFilmAnalysisApi = sendPostImageAnalysis("image-analysis/process_paint_film_images");

export const fetchBatchInfoApi = (analysis_type: string, batchId?: string) => sendGetImageAnalysis(`image-analysis/batches/${analysis_type}/${batchId ? batchId : "all"}`);

export const exportBatchReportApi = sendPostImageAnalysis(`image-analysis/export_batch_report/excel`);

export const deleteBatchApi = (analysis_type: string) => sendDeleteImageAnalysis(`image-analysis/delete_batch/${analysis_type}`);

export const recalibrateScaleApi = sendPostImageAnalysis("image-analysis/recalibrate_scale");

export const analysisTypesApi = sendGetImageAnalysis("image-analysis/analysis-types");

export const modifyPaintFilm = sendPostImageAnalysis("image-analysis/process_paint_film_images/modify");

export const processFiberAnalysisApi = sendPostImageAnalysis("image-analysis/process_fiber_images");

export const processWhiteObjectsApi = sendPostImageAnalysis("image-analysis/process_white_object_images");

export const modifyWhiteObjectsApi = sendPostImageAnalysis("image-analysis/process_white_object_images/modify");

export const modifyPaintFilmBatchApi = sendPostImageAnalysis("image-analysis/apply_settings_batch");

export const modifyFiberAnalysisApi = sendPostImageAnalysis("image-analysis/process_fiber_images/modify");

export const downloadSegmentedImagesApi = sendPostImageAnalysis("image-analysis/download_segmented_images");

import { Collapse, Slider, Switch } from "antd";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  modifyPaintFilmBatchRequest,
  modifyPaintFilmRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";

type TProps = {
  minDiameter: number;
  maxDiameter: number;
  showAllMasks: boolean;
  showOriginalImage: boolean;
  setDiameterRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>;
  setShowAllMasks: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOriginalImage: React.Dispatch<React.SetStateAction<boolean>>;
  showBBox: boolean;
  setShowBBox: React.Dispatch<React.SetStateAction<boolean>>;
  files: any;
};

const DEFAULT_MIN_RANGE = 8;
const DEFAULT_BILATERAL_THRESHOLD = 9;

const PaintFilmAnalysisDetailsSidebar = ({
  minDiameter,
  maxDiameter,
  showAllMasks,
  showOriginalImage,
  setDiameterRangeThreshold,
  setShowAllMasks,
  setShowOriginalImage,
  showBBox,
  setShowBBox,
  files
}: TProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const params = useParams<any>();
  const [diameterRange, setDiameterRange] = useState<any[]>([]);
  const [bilateralThreshold, setBilateralThreshold] = useState<number>(DEFAULT_BILATERAL_THRESHOLD);
  const { semAnalysisMaskData, analysisTypes, modifyPaintFilmBatchStatus, modifyPaintFilmBatchData } = useSelector(
    (state: StoreState) => state.semAnalysis
  );

  const resetDiameter = useCallback(() => {
    let minVal = DEFAULT_MIN_RANGE;

    setDiameterRange([minVal, maxDiameter]);
    setDiameterRangeThreshold([minVal, maxDiameter]);
    setBilateralThreshold(DEFAULT_BILATERAL_THRESHOLD);

    if (semAnalysisMaskData?.parameters) {
      const parameters = semAnalysisMaskData?.parameters;
      setDiameterRange([parameters?.diameter_threshold, maxDiameter]);
      setDiameterRangeThreshold([parameters?.diameter_threshold, maxDiameter]);
      setBilateralThreshold(parameters?.bilateral_threshold);
    }
  }, [maxDiameter, semAnalysisMaskData?.parameters, setDiameterRangeThreshold]);

  useEffect(() => {
    resetDiameter();
  }, [resetDiameter]);

  const modify = () => {
    dispatch(
      modifyPaintFilmRequest({
        batch_id: params.batchId,
        file_id: params.fileId,
        thresholds: {
          bilateral_threshold: bilateralThreshold,
          diameter_threshold: diameterRange[0],
        },
      })
    );
  };

  const resetAll = () => {
    setBilateralThreshold(9);
    resetDiameter();

    let minVal = DEFAULT_MIN_RANGE;

    dispatch(
      modifyPaintFilmRequest({
        batch_id: params.batchId,
        file_id: params.fileId,
        thresholds: { bilateral_threshold: 9, diameter_threshold: minVal },
      })
    );
  };

  const applyToAll = () => {
    dispatch(
      modifyPaintFilmBatchRequest({
        batch_id: params.batchId,
        file_ids: files.map((file: any) => file.file_id),
        analysis_type:
          analysisTypes[
            IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
          ],
        thresholds: {
          bilateral_threshold: bilateralThreshold,
          diameter_threshold: diameterRange[0],
        },
      })
    );
  };

  const isBatchProcessing = useMemo(() => {
    return modifyPaintFilmBatchStatus === AsyncStates.LOADING || Object.keys(modifyPaintFilmBatchData || {}).includes(params.batchId);
  }, [modifyPaintFilmBatchData, modifyPaintFilmBatchStatus, params.batchId])

  return (
    <div className="sidebar" style={{ width: "240px" }}>
      <div className="content" style={{ width: "240px" }}>
        <div className="option">
          <div className="label">{t("sem.showOriginalImage")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showOriginalImage}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowOriginalImage(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="option">
          <div className="label">{t("sem.showAllMasks")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showAllMasks}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowAllMasks(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="option">
          <div className="label">{t("sem.showBoundingBox")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showBBox}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowBBox(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>
        <Collapse
          expandIconPosition="end"
          rootClassName="setting-collapse"
          items={[
            {
              key: "1",
              label: t("sem.advancedSettings"),
              children: (
                <>
                  <div className="option">
                    <div className="label">{t("sem.diameter")}</div>
                    <Slider
                      range
                      step={0.1}
                      min={0}
                      max={maxDiameter}
                      value={diameterRange}
                      onChange={(val) => setDiameterRange(val)}
                      onChangeComplete={(val) => {
                        setDiameterRangeThreshold(val);
                      }}
                      style={{ width: 200 }}
                      rootClassName="sem-slider"
                    />
                  </div>

                  <div className="option">
                    <div className="label">{t("sem.sensitivity")}</div>
                    <Slider
                      reverse
                      step={1}
                      min={4}
                      max={14}
                      value={bilateralThreshold}
                      onChange={(val) => setBilateralThreshold(val)}
                      style={{ width: 200, color: "white" }}
                      marks={{
                        4: t("sem.strong"),
                        9: t("sem.medium"),
                        14: t("sem.weak"),
                      }}
                      rootClassName="sem-slider sem-slider-no-track"
                    />
                  </div>

                  <div className="option">
                    <StyledButton size="small" type="primary" onClick={modify}>
                      {t("common.apply")}
                    </StyledButton>
                    <StyledButton size="small" onClick={resetAll}>
                      {t("sem.resetToDefault")}
                    </StyledButton>
                  </div>
                  <div className="option" style={{ marginTop: "-10px" }}>
                    <StyledButton
                      size="small"
                      style={{ width: "100%" }}
                      onClick={applyToAll}
                      loading={isBatchProcessing}
                    >
                      {t("sem.applyToBatch")}
                    </StyledButton>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(PaintFilmAnalysisDetailsSidebar);

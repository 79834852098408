import { Col, Form, Input, Modal, Row, Select } from "antd";
import { SCALE_UNITS } from "../Shared/ScaleUnits";
import useTranslate from "src/utils/useTranslate";

export default function CalibrateModal({
  calibrateModal,
  setCalibrateModal,
  handleCalibrate,
}: any) {
  const [t] = useTranslate();
  const [form] = Form.useForm();

  const handleOk = () => {
    const values = form.getFieldsValue();
    if (values["scale"] || values["unit"]) {
      handleCalibrate(values["scale"], values["unit"]);
      setCalibrateModal(false);
    }
  };

  return (
    <Modal
      title={t("sem.calibrate")}
      open={calibrateModal}
      width={350}
      onCancel={() => setCalibrateModal(false)}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item label={t("sem.scaleLength")} name="scale">
              <Input type="number" placeholder="0" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("common.unit")} name="unit">
              <Select
                placeholder={t("common.selectAUnit")}
                options={SCALE_UNITS.map((unit) => ({
                  label: unit,
                  value: unit,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

import { Drawer } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../Shared/SEMAnalysis.scss";
import WhiteObjectDetailsSidebar from "./WhiteObjectAnalysisDetailsSidebar";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadBatchReportRequest,
  fetchSemAnalysisMaskDataRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import WhiteObjectDetailsContent from "./WhiteObjectAnalysisDetailsContent";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";
import useTranslate from "src/utils/useTranslate";

const WhiteObjectAnalysisDetails = () => {
  const [t] = useTranslate();
  const location = useLocation<any>();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAllMasks, setShowAllMasks] = useState(
    location.state?.showAllMasks ?? true
  );
  const [showBBox, setShowBBox] = useState(location.state?.showBBox ?? true);
  const [area, setArea] = useState<number[]>();
  const [showOriginalImage, setShowOriginalImage] = useState(
    location.state?.showOriginalImage ?? true
  );
  const {
    semAnalysisMaskData,
    downloadBatchReportStatus,
    analysisTypes,
    modifyWhiteObjectsStatus,
  } = useSelector((state: StoreState) => state.semAnalysis);

  const fetchMasks = useCallback(() => {
    dispatch(
      fetchSemAnalysisMaskDataRequest({
        file_id: params.fileId,
        analysis_type:
          analysisTypes[
            IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS as keyof typeof analysisTypes
          ],
      })
    );
  }, [analysisTypes, dispatch, params.fileId]);

  useLayoutEffect(() => {
    fetchMasks();
  }, [fetchMasks]);

  useEffect(() => {
    if (modifyWhiteObjectsStatus === AsyncStates.SUCCESS) fetchMasks();
  }, [fetchMasks, modifyWhiteObjectsStatus]);

  const maskData = useMemo(
    () => {
      if(!area) return semAnalysisMaskData?.mask_results;
      else {
        return semAnalysisMaskData?.mask_results?.filter((mask: any) => mask.pixel_area >= area[0] && mask.pixel_area <= area[1])
      }
    }, [semAnalysisMaskData, area]
  );

  const imageToShow = useMemo(() => {
    return showOriginalImage
      ? semAnalysisMaskData?.original_image_url
      : semAnalysisMaskData?.binary_image_url;
  }, [semAnalysisMaskData, showOriginalImage]);

  const downloadReport = useCallback(() => {
    const payload: any = {
      file_ids: [params.fileId],
      analysis_type:
        analysisTypes[
          IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS as keyof typeof analysisTypes
        ],
    };

    dispatch(downloadBatchReportRequest(payload));
  }, [analysisTypes, dispatch, params.fileId]);

  const onDrawerClose = () =>
    history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS}`);

  return (
    <Drawer
      title={t("sem.whiteObjectAnalysis")}
      placement="bottom"
      open={true}
      height="100%"
      styles={{
        body: { padding: 0 },
      }}
      onClose={onDrawerClose}
      className="paint-film-analysis-drawer"
      extra={
        <>
          <StyledButton
            type="primary"
            onClick={downloadReport}
            style={{ marginRight: 10 }}
            loading={downloadBatchReportStatus === AsyncStates.LOADING}
          >
            {t("report.downloadReport")}
          </StyledButton>
        </>
      }
    >
      <div className="container">
        <WhiteObjectDetailsSidebar
          showOriginalImage={showOriginalImage}
          setShowOriginalImage={setShowOriginalImage}
          showAllMasks={showAllMasks}
          setShowAllMasks={setShowAllMasks}
          showBBox={showBBox}
          setShowBBox={setShowBBox}
          area={area}
          setArea={setArea}
        />
        <WhiteObjectDetailsContent
          showAllMasks={showAllMasks}
          filteredData={maskData}
          image={imageToShow}
          showBBox={showBBox}
        />
      </div>
    </Drawer>
  );
};

export default WhiteObjectAnalysisDetails;

import useTranslate from "src/utils/useTranslate";
import KDEChart from "../../Shared/KDEChart";
import MaskTable from "../../Shared/MaskTable";

type TProps = {
    data: any;
};

const PaintFilmAnalysisAttributes = ({
    data
}: TProps) => {
    const [t] = useTranslate();

    return (
        <>
            {data && data?.mask_results?.length && (
                <>
                    <KDEChart
                        data={data?.mask_results}
                        unit={data?.unit}
                        selections={[]}
                        handleChartSelection={() => null}
                        titles={{
                            graphTitle: t("sem.diameterDistribution"),
                            yAxis: t("common.count"),
                            xAxis: t("sem.diameter")
                        }}
                        showSelections={false}
                    />
                    <h3>{t("sem.totalMasks")} ({data?.mask_results?.length})</h3>
                    <MaskTable data={data} />
                </>
            )}
        </>
    );
};

export default PaintFilmAnalysisAttributes;

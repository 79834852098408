import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Table } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { updateClassificationRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";

const QualitySelections = [
  {
    label: "NG",
    value: "NG"
  },
  {
    label: "OK",
    value: "OK"
  }
]

const QuadrantTable: FC<any> = ({ fileId }: { fileId: string }) => {
  const [t] = useTranslate();
  const {
    classificationAnalysis, semAnalysisMaskData, updateClassificationStatus
  } = useSelector((state: StoreState) => state.semAnalysis);
  const dispatch = useDispatch();

  const getLengthOfProtrusion = useCallback((maskId: string, quadrantClass: string) => {
    if (quadrantClass.toLowerCase() === "ok") return "0";

    const mask = semAnalysisMaskData?.mask_results?.find((mask: any) => mask.id === maskId);
    return mask?.max_length?.pixel_length + " " + semAnalysisMaskData?.unit
  }, [semAnalysisMaskData]);

  const onQualityChange = useCallback((quadrant: string, quality: string) => {
    const payload = {
      file_id: fileId,
      result: [{
        quadrant,
        quality
      }]
    }

    dispatch(updateClassificationRequest(payload))
  }, [fileId, dispatch]);

  const qualitySelection = useCallback((quadrant: string, quality: string) => {
    return < Select
      placeholder="-"
      value={quality}
      className="quadrant-table-input"
      onChange={(v) => onQualityChange(quadrant, v)}
      options={QualitySelections}
    />
  }, [onQualityChange])

  const getLocation = useCallback((qudrant: string) => {
    if (qudrant === "Q1") {
      return t("sem.topRight")
    } else if (qudrant === "Q2") {
      return t("sem.topLeft")
    } else if (qudrant === "Q3") {
      return t("sem.bottomLeft")
    } else if (qudrant === "Q4") {
      return t("sem.bottomRight")
    }
  }, [t]);

  const dataWithKeys: any = useMemo(() =>
    classificationAnalysis?.result?.map((item: any, index: any) => ({
      key: index,
      location: getLocation(item.quadrant),
      quadrant: item.quadrant,
      quality: qualitySelection(item.quadrant, item.class),
      lengthOfProtrusion: getLengthOfProtrusion(item.mask_id, item.class)
    })), [classificationAnalysis?.result, getLengthOfProtrusion, qualitySelection, getLocation]);


  const columns = [
    {
      title: t("sem.location"),
      dataIndex: "location",
      key: "location",
      width: "10%",
    },
    {
      title: t("sem.qudrant"),
      dataIndex: 'quadrant',
      key: "quadrant",
      width: "20%",
    },
    {
      title: t("sem.quality"),
      dataIndex: 'quality',
      key: "quality",
      width: "20%",
    },
    {
      title: t("sem.protrusionLength"),
      dataIndex: 'lengthOfProtrusion',
      key: "lengthOfProtrusion",
      width: "20%",
    },
  ];

  return (
    <Spin spinning={updateClassificationStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      <h3>{t("sem.quadrantInfo")} {"(" + classificationAnalysis?.result?.length + ")"}</h3>
      <Table dataSource={dataWithKeys} columns={columns} />
    </Spin>
  );
};

export default React.memo(QuadrantTable);

import { Collapse, Slider, Switch } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { modifyFiberRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
  minWidth: number;
  maxWidth: number;
  minLength: number;
  maxLength: number;
  showAllMasks: boolean;
  showOriginalImage: boolean;
  setWidthRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>;
  setLengthRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>;
  setShowAllMasks: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOriginalImage: React.Dispatch<React.SetStateAction<boolean>>;
  showBBox: boolean;
  setShowBBox: React.Dispatch<React.SetStateAction<boolean>>;
  showSkeleton: boolean;
  setShowSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

const DEFAULT_PARAMS = {
  COLOR_INTENSITY: 40,
  DEFAULT_BILATERAL_THRESHOLD: 9,
};

const FiberAnalysisDetailsSidebar = ({
  minWidth,
  maxWidth,
  minLength,
  maxLength,
  showAllMasks,
  showOriginalImage,
  setWidthRangeThreshold,
  setLengthRangeThreshold,
  setShowAllMasks,
  setShowOriginalImage,
  showBBox,
  setShowBBox,
  showSkeleton,
  setShowSkeleton,
}: TProps) => {
  const [t] = useTranslate();
  const params = useParams<any>();
  const [widthRange, setWidthRange] = useState<any[]>([]);
  const [lengthRange, setLengthRange] = useState<any[]>([]);
  const [colorIntensity, setColorIntensity] = useState(
    DEFAULT_PARAMS.COLOR_INTENSITY
  );
  const [bilateralThreshold, setBilateralThreshold] = useState<number>(
    DEFAULT_PARAMS.DEFAULT_BILATERAL_THRESHOLD
  );
  const { semAnalysisMaskData } = useSelector(
    (state: StoreState) => state.semAnalysis
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setWidthRange([minWidth, maxWidth]);
    setLengthRange([minLength, maxLength]);
    setWidthRangeThreshold([minWidth, maxWidth]);
    setLengthRangeThreshold([minLength, maxLength]);
  }, [
    maxLength,
    maxWidth,
    minLength,
    minWidth,
    setLengthRangeThreshold,
    setWidthRangeThreshold,
  ]);

  const resetParams = useCallback(() => {
    setBilateralThreshold(DEFAULT_PARAMS.DEFAULT_BILATERAL_THRESHOLD);
    setColorIntensity(DEFAULT_PARAMS.COLOR_INTENSITY);

    if (semAnalysisMaskData?.parameters) {
      const parameters = semAnalysisMaskData?.parameters;
      setColorIntensity(parameters?.color_threshold);
      setBilateralThreshold(parameters?.bilateral_threshold);
    }
  }, [semAnalysisMaskData?.parameters]);

  useEffect(() => {
    resetParams();
  }, [resetParams]);

  const modify = () => {
    dispatch(
      modifyFiberRequest({
        batch_id: params.batchId,
        file_id: params.fileId,
        thresholds: {
          bilateral_threshold: bilateralThreshold,
          color_threshold: colorIntensity,
        },
      })
    );
  };

  const resetAll = () => {
    setBilateralThreshold(9);
    resetParams();

    dispatch(
      modifyFiberRequest({
        batch_id: params.batchId,
        file_id: params.fileId,
        thresholds: {
          bilateral_threshold: DEFAULT_PARAMS.DEFAULT_BILATERAL_THRESHOLD,
          color_threshold: DEFAULT_PARAMS.COLOR_INTENSITY,
        },
      })
    );
  };

  return (
    <div className="sidebar" style={{ width: "245px" }}>
      <div className="content" style={{ width: "245px" }}>
        <div className="option">
          <div className="label">{t("sem.showOriginalImage")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showOriginalImage}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowOriginalImage(val);
            }}
            style={{
              outline: "none",
              width: "62px",
            }}
          />
        </div>

        <div className="option">
          <div className="label">{t("sem.showAllMasks")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showAllMasks}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowAllMasks(val);
            }}
            style={{
              outline: "none",
              width: "62px",
            }}
          />
        </div>

        <div className="option">
          <div className="label">{t("sem.showBoundingBox")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showBBox}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowBBox(val);
            }}
            style={{
              outline: "none",
              width: "62px",
            }}
          />
        </div>
        <div className="option">
          <div className="label">{t("sem.showSkeleton")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showSkeleton}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowSkeleton(val);
            }}
            style={{
              outline: "none",
              width: "62px",
            }}
          />
        </div>
        <div className="option">
          <div className="label">{t("sem.width")}</div>
          <Slider
            range
            step={0.01}
            min={minWidth}
            max={maxWidth}
            value={widthRange}
            onChange={(val) => setWidthRange(val)}
            onChangeComplete={(val) => setWidthRangeThreshold(val)}
            style={{ width: 200 }}
            rootClassName="sem-slider"
          />
        </div>
        <div className="option">
          <div className="label">{t("sem.length")}</div>
          <Slider
            range
            min={minLength}
            max={maxLength}
            value={lengthRange}
            onChange={(val) => setLengthRange(val)}
            onChangeComplete={(val) => setLengthRangeThreshold(val)}
            style={{ width: 200 }}
            rootClassName="sem-slider"
          />
        </div>
        <Collapse
          expandIconPosition="end"
          rootClassName="setting-collapse"
          items={[
            {
              key: "1",
              label: t("sem.advancedSettings"),
              children: (
                <>
                  <div className="option">
                    <div className="label">{t("sem.colorIntensity")}</div>
                    <Slider
                      step={1}
                      min={10}
                      max={100}
                      value={colorIntensity}
                      onChange={(val) => setColorIntensity(val)}
                      style={{ width: 200 }}
                      rootClassName="sem-slider sem-slider-inverse-track"
                    />
                  </div>

                  <div className="option">
                    <div className="label">{t("sem.sensitivity")}</div>
                    <Slider
                      reverse
                      step={1}
                      min={4}
                      max={14}
                      value={bilateralThreshold}
                      onChange={(val) => setBilateralThreshold(val)}
                      style={{ width: 200, color: "white" }}
                      marks={{
                        4: "Strong",
                        9: "Medium",
                        14: "Weak",
                      }}
                      rootClassName="sem-slider sem-slider-no-track"
                    />
                  </div>

                  <div className="option">
                    <StyledButton size="small" type="primary" onClick={modify}>
                      {t("common.apply")}
                    </StyledButton>
                    <StyledButton size="small" onClick={resetAll}>
                      {t("sem.resetToDefault")}
                    </StyledButton>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(FiberAnalysisDetailsSidebar);

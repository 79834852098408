import { useState, useEffect, useCallback, useRef } from "react"
import {
  Space,
  Form,
  Row,
  Col,
  Select,
  Spin,
  Modal,
  Typography,
  Drawer,
  List,
  Descriptions,
  Checkbox,
  Collapse,
} from "antd"
import { ProjectSelection } from "src/components/ProjectSelection"
import useTranslate from "src/utils/useTranslate"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
  filterDataRequest,
  deleteModelRequest,
  filterDataClear,
  getCustomTraingsRequest,
  // equationModelClear,
} from "src/store/actions/customML"
import { AsyncStates } from "src/constants"
import { useMemberName } from "src/utils/useMemberName"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import { CustomPrompt } from "src/utils/CustomPrompts"
import { useHistory, useLocation, useParams } from "react-router-dom"
import "./CustomML.scss"
import { CreateProjectModal } from "src/components/Project/CreateProjectModal"
import { EquationModelResult } from "./EquationModelResults"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
import { MathJaxContext } from "better-react-mathjax"


const { Option } = Select
const { Text, Title } = Typography

/**
 * selectedwo
 * selectedformulations
 * ismultistage
 * project
 */

export function EquationModel({
  customMlDrawerVisible,
  setCustomMlDrawerVisible,
  isRedirect,
  ...props
}: any) {
  const [filterForm] = Form.useForm();
  const { state } = useLocation<any>()
  const { runid } = useParams<{ runid: string }>()
  const [selectedWo, setSelectedWo] = useState<any>([]);

  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { getName } = useMemberName()

  // const configs = useSelector((state: StoreState) => state.configs.features)
  const workOrders = useSelector((state: StoreState) => state.workOrders.data)
  const workOrdersStatus = useSelector(
    (state: StoreState) => state.workOrders.status,
  )
  const {
    filterData,
    filterDataStatus,
    // customDataTrainStatus,
    deleteModelStatus,
    customTrainingsData,
    customTrainingsStatus,
  } = useSelector((state: StoreState) => state.CustomML)
  const { current, selectAllProjects, projectList } = useSelector(
    (state: StoreState) => state.projects,
  )

  const [
    // selectedFormulations
    , setSelectedFormulations] = useState<any>([]);
  const [selectedVersions, setSelectedVersions] = useState<any[]>([])

  const [filters, setFilters] = useState<any>({})
  const [createModalPayload, setCreateModalPayload] = useState<any>({})

  const [isEditing, setIsEditing] = useState(false);

  const formPopulated = useRef(false);
  const submitBtn = useRef<HTMLElement>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  const [userIgnoredData, setUserIgnoredData] = useState<any>({
    ingredients: {},
    properties: {},
    processing: {},
    formulations: {}
  })
  const [mounted, setMounted] = useState(false);
  const [isMultiStage, setIsMultiStage] = useState<boolean>(props.isMultiStage);
  const [autoSubmitForm, setAutoSubmitForm] = useState(false);

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [mounted]);

  useEffect(() => {
    if (autoSubmitForm) submitBtn.current?.click();
  }, [autoSubmitForm]);

  useEffect(() => {
    if (mounted && isRedirect) {
      // Populate initial form when redirected from formulations
      setSelectedWo(props.selectedWorkOrders);
      setSelectedFormulations(props.selectedFormulations);

      filterForm.setFieldValue(
        "work_order_ids",
        props.selectedWorkOrders,
      )
      filterForm.setFieldValue(
        "is_multi_stage",
        props.isMultiStage,
      )

      setAutoSubmitForm(true);
    }
  }, [mounted, isRedirect, props.selectedWorkOrders, props.isMultiStage, props.selectedFormulations, filterForm]);

  useEffect(() => {
    setSelectedWo([]);
    setSelectedFormulations([]);
    filterForm.setFieldValue(
      "work_order_ids",
      [],
    )
  }, [isMultiStage, filterForm]);

  useEffect(() => {
    if (mounted) setIsMultiStage(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  useEffect(() => {
    setCreateModalPayload({})
    setSelectedWo([])
    setSelectedFormulations([]);
    filterForm.resetFields()
    dispatch(filterDataClear())
  }, [current, selectAllProjects, filterForm, dispatch])

  useEffect(() => {
    return () => {
      dispatch(filterDataClear())
    }
  }, [dispatch])

  const submitDataFilter = useCallback(
    (page_num = pagination.current, pageSize = pagination.pageSize, workOrderId = null, stage = "Stage 1") => {
      const {
        // applications,
        // materials,
        work_order_ids,
        // stage_identifier,
        is_multi_stage,
      } = filterForm.getFieldsValue()

      if (!workOrderId) workOrderId = !!work_order_ids?.length ? work_order_ids[0] : null;

      let payload: any = {
        page_num,
        page_size: pageSize,
        // input_types: ["ingredients", "processing"],
        // output_types: ["properties"],
        // applications: !!applications?.length ? applications : [],
        // materials: !!materials?.length ? materials : [],
        work_order_ids: isMultiStage ? !!work_order_ids?.length ? work_order_ids : [] : [workOrderId],
        // total_work_order_ids: !!work_order_ids?.length ? work_order_ids : [],
        // formulation_ids: !!selectedFormulations ? selectedFormulations : [],
        // project_ids: selectAllProjects ? [] : [current],
        // stage_identifier: stage_identifier ? stage_identifier : [],
        // is_multi_stage: !!is_multi_stage,
        // ...(Boolean(configs?.processing_profiles) && {
        //   use_processing_profiles: true,
        // }),
      }

      if (is_multi_stage) {
        payload = {
          ...payload,
          stage: stage,
          selected_work_order_id: workOrderId
        }
        delete payload.total_work_order_ids;
      }

      setFilters(
        selectAllProjects
          ? {}
          : projectList.find((res: any) => res.project_id === current),
      )
      setPagination({ current: page_num, pageSize })
      const {
        stage_identifier: stg,
        materials: mat,
        applications: app,
        formulation_ids: fid,
        ...multiStagePayload
      } = payload

      const modalPayload = {
        ...payload,
        work_order_ids: !!work_order_ids?.length ? work_order_ids : []
      }
      setCreateModalPayload(is_multi_stage ? multiStagePayload : modalPayload)
      dispatch(filterDataRequest({ payload }))
      setIsEditing(false)
    },
    [current, dispatch, filterForm, pagination, projectList, selectAllProjects, isMultiStage],
  )

  const getFilteredWorkOrders = useCallback(() => {
    if (isMultiStage) return workOrders.filter(wo => wo.stages?.length > 1);
    else return workOrders.filter(wo => wo.stages?.length === 1);
  }, [isMultiStage, workOrders])

  const drawerCheckboxChange = (e: any, version: any) => {
    if (e.target.checked) {
      setSelectedVersions((prevState: any) => [...prevState, version])
    } else {
      setSelectedVersions(
        selectedVersions.filter((res: any) => res !== version),
      )
    }
  }

  useEffect(() => {
    if (deleteModelStatus === AsyncStates.SUCCESS) {
      setSelectedVersions([])
    }
  }, [deleteModelStatus])

  const deleteModels = () => {
    dispatch(
      deleteModelRequest({
        versions: selectedVersions,
      }),
    )
  }

  const onPaginate = (current: number, pageSize: number) => {
    dispatch(
      getCustomTraingsRequest({
        filter_name: "",
        page_num: current,
        page_size: pageSize,
      }),
    )
  }

  const { push } = useHistory()

  const [createProjectModal, setCreateProjectModal] = useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string[]>(['get-data'])

  useEffect(() => {
    if (filterDataStatus === AsyncStates.SUCCESS) setActiveKey([])
  }, [filterDataStatus])

  useEffect(() => {
    if (runid && runid !== "new") {
      filterForm.setFieldValue(
        "work_order_ids",
        Object.keys(state?.work_orders || {})
      )
      setSelectedWo(Object.keys(state?.work_orders || {}))
      // dispatch(equationModelClear())
      formPopulated.current = true;
      setUserIgnoredData({
        ingredients: [],
        properties: [],
        processing: [],
        formulations: []
      })
    }
  }, [filterForm, runid, state?.work_orders])

  return (
    <MathJaxContext version={2} config={{
      CommonHTML: { linebreaks: { automatic: true } },
      "HTML-CSS": { linebreaks: { automatic: true } },
      SVG: { linebreaks: { automatic: true } },
      TeX: {
        extensions: ["color.js"]
      }
    }} hideUntilTypeset="every">
      <CustomPrompt
        isEditing={isEditing as boolean}
        message={`${t("common.unsavedChangesLost")}!.`}
      />
      <CreateProjectModal
        setCreateProjectModal={setCreateProjectModal}
        createProjectModal={createProjectModal}
      />
      {runid === "new" && <StyledCard
        headStyle={{ border: "none", paddingBottom: 0 }}
        title={
          <Space>
            <Typography.Text>{t("common.project")}</Typography.Text>
            <ProjectSelection
              size="middle"
              createProjectModal={createProjectModal}
              setCreateProjectModal={setCreateProjectModal}
              actions={() => formPopulated.current = false}
            />
          </Space>
        }
      >
        <Space
          direction="vertical"
          size={"middle"}
          style={{ userSelect: "none", width: '100%' }}
        >
          <Collapse
            items={[
              {
                key: "get-data",
                label: t("aiEngine.customML.button.getData"),
                children: (
                  <>
                    <div style={{ paddingBottom: "1rem" }}>
                      <Text strong type="secondary">
                        {t("customMl.note")}
                      </Text>
                    </div>
                    <Form
                      onFinish={() => {
                        // dispatch(equationModelClear())
                        formPopulated.current = true;
                        setIsEditing(false);
                        setUserIgnoredData({
                          ingredients: [],
                          properties: [],
                          processing: [],
                          formulations: []
                        })
                      }}
                      form={filterForm}
                      layout="vertical"
                      onValuesChange={(_, allValues) => {
                        if ((allValues.is_multi_stage || (allValues.work_order_ids && allValues.work_order_ids.length)) > 0) {
                          setIsEditing(true)
                        } else {
                          formPopulated.current = false;
                          setIsEditing(false)
                        }
                      }
                      }
                      scrollToFirstError
                    >
                      <Row justify="space-between">
                        <Col span={16}>

                          <Form.Item
                            name="work_order_ids"
                            label={t("aiEngine.customInsights.workOrder")}
                            rules={[
                              { type: 'array', required: true, message: t("common.selectAtleastOneWorkOrder") },
                            ]}
                          >
                            <Select
                              className="workorders__dropdown__select"
                              placeholder={t(
                                "aiEngine.customInsights.selectWorkOrder",
                              )}
                              allowClear
                              mode="multiple"
                              optionFilterProp="children"
                              onChange={(value) => {
                                setSelectedWo(value);
                                setSelectedFormulations([]);
                              }}
                              loading={
                                workOrdersStatus === AsyncStates.LOADING
                              }
                              onDeselect={(woOrder: string) => {
                                //materials
                                filterForm.setFieldValue(
                                  "materials",
                                  filterForm
                                    .getFieldValue("materials")
                                    ?.filter(
                                      (value: string) =>
                                        value !==
                                        workOrders.find(
                                          (value) =>
                                            value.work_order_id === woOrder,
                                        ).material,
                                    ),
                                )
                                //applications
                                filterForm.setFieldValue(
                                  "applications",
                                  filterForm
                                    .getFieldValue("applications")
                                    ?.filter(
                                      (value: string) =>
                                        value !==
                                        workOrders.find(
                                          (value) =>
                                            value.work_order_id === woOrder,
                                        ).application,
                                    ),
                                )
                              }}
                              dropdownRender={(menu) => {
                                return (
                                  <div>
                                    {getFilteredWorkOrders().length > 0 && (
                                      <Checkbox
                                        style={{ padding: 10 }}
                                        checked={
                                          selectedWo.length ===
                                          getFilteredWorkOrders().length
                                        }
                                        onChange={(e) => {
                                          setIsEditing(true);
                                          if (e.target.checked) {
                                            const allWorkOrder =
                                              getFilteredWorkOrders().map(
                                                (wo) => wo.work_order_id,
                                              )
                                            setSelectedWo(allWorkOrder)
                                            filterForm.setFieldsValue({
                                              work_order_ids: allWorkOrder,
                                            })
                                          } else {
                                            setSelectedWo([])
                                            filterForm.setFieldsValue({
                                              work_order_ids: [],
                                            })
                                          }
                                        }}
                                      >{`${t("common.selectAll")}`}</Checkbox>
                                    )}
                                    <Spin
                                      spinning={
                                        workOrdersStatus ===
                                        AsyncStates.LOADING
                                      }
                                    >
                                      {menu}
                                    </Spin>
                                  </div>
                                )
                              }}
                            >
                              {workOrdersStatus === AsyncStates.SUCCESS &&
                                getFilteredWorkOrders().map((res: any) => (
                                  <Option
                                    value={res.work_order_id}
                                    key={res.work_order_id}
                                  >
                                    {res.work_order_name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>


                          <Row justify="space-between">
                            <StyledButton
                              ref={submitBtn}
                              type="primary"
                              htmlType="submit"
                            >
                              {t("aiEngine.customML.button.getData")}
                            </StyledButton>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                ),
              },
            ]}
            onChange={(key) => {
              if (Array.isArray(key)) {
                setActiveKey(key)
              } else {
                setActiveKey([key])
              }
            }}
            activeKey={activeKey}
          />
        </Space>
      </StyledCard>}
      {!isEditing && formPopulated.current && <EquationModelResult
        filterData={filterData}
        filterDataStatus={filterDataStatus}
        createModalPayload={createModalPayload}
        filter={filters}
        submitDataFilter={submitDataFilter}
        pagination={pagination}
        dataCount={filterData?.total_count || filterData?.wo_formulation_ids_count}
        isMultiStage={isMultiStage}
        ignoredData={[userIgnoredData, setUserIgnoredData]}
        selectedWorkOrders={selectedWo}
        workOrders={workOrders}
      />}
      <Drawer
        open={customMlDrawerVisible}
        width={500}
        onClose={() => setCustomMlDrawerVisible(false)}
        title={
          <Row justify="space-between">
            <Title level={4} style={{ margin: 0 }}>
              {t("aiEngine.history")}
            </Title>
            <StyledButton
              style={{ marginRight: 35 }}
              type="primary"
              disabled={!selectedVersions?.length}
              onClick={() => {
                Modal.confirm({
                  title: `${t("common.delete")} ${t("aiEngine.selectedRecords")}?`,
                  icon: <StyledDeleteIcon />,
                  okText: t("common.confirm"),
                  cancelText: t("common.cancel"),
                  okButtonProps: { style: { borderRadius: "12px" } },
                  cancelButtonProps: { style: { borderRadius: "12px" } },
                  onOk: () => deleteModels(),
                })
              }}
              loading={deleteModelStatus === AsyncStates.LOADING}
            >
              {t("aiEngine.deleteModels")}
            </StyledButton>
          </Row>
        }
      >
        <List
          loading={
            customTrainingsStatus === AsyncStates.LOADING ||
            deleteModelStatus === AsyncStates.LOADING
          }
          pagination={{
            defaultCurrent: 1,
            total: customTrainingsData?.total_count,
            showSizeChanger: true,
            onShowSizeChange: onPaginate,
            position: "top",
            onChange: onPaginate,
          }}
          dataSource={customTrainingsData?.data?.filter(
            (res: any) => res.is_custom,
          )}
          renderItem={(item: any) => (
            <List.Item style={{ marginTop: 10 }}>
              <StyledCard
                type="inner"
                style={
                  Boolean(item?.is_multistage)
                    ? {
                      width: "100%",
                      cursor: "not-allowed",
                      background: "#f0f0f0",
                    }
                    : { width: "100%", cursor: "pointer" }
                }
                title={
                  item.status === "Completed" && (
                    <Checkbox
                      checked={selectedVersions?.includes(item?.version)}
                      onChange={(e) => {
                        e.stopPropagation()
                        drawerCheckboxChange(e, item?.version)
                      }}
                      onClick={(e) => e.stopPropagation()}
                    ></Checkbox>
                  )
                }
                onClick={() => {
                  const { version, status } = item
                  if (
                    !Boolean(item?.is_multistage) &&
                    version &&
                    status === "Completed"
                  )
                    push(`/model-details?version=${item.version}`)
                }}
              >
                <Descriptions bordered column={1}>
                  <Descriptions.Item label={t("common.objective")}>
                    {item?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.comment")}>
                    {item?.comment}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("aiEngine.drawer.createdOn")}>
                    {new Date(item?.created_on).toLocaleString()}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.createdBy")}>
                    {getName(item?.created_by)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.status")}>
                    {item?.status}
                  </Descriptions.Item>
                </Descriptions>
              </StyledCard>
            </List.Item>
          )}
        />
      </Drawer>
    </MathJaxContext>
  )
}

import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import {
  DeleteMasksActionTypes,
  DeleteSemAnalysisFilesActionTypes,
  DeleteSemAnalysisMaskHeightWidthActionTypes,
  DeletePaintFilmBatchActionTypes,
  DownloadSemAnalysisReportActionTypes,
  FetchClassificationAnalysisActionTypes,
  FetchImageSubtractionActionTypes,
  FetchInstanceDetectionActionTypes,
  FetchSemAnalysisDataActionTypes,
  FetchSemAnalysisListActionTypes,
  FetchSemAnalysisMaskDataActionTypes,
  FetchSemAnalysisMaskHeightWidthActionTypes,
  FetchSemAnalysisScaleActionTypes,
  FetchPaintFilmBatchInfoActionTypes,
  ProcessPaintFilmAnalysisActionTypes,
  ResetMasksActionTypes,
  SetImageSubtractionActionTypes,
  SetSemAnalysisMaskHeightWidthActionTypes,
  PaintFilmRecalibrateScaleActionTypes,
  UpdateClassificationActionTypes,
  AnalysisTypesActionTypes,
  FetchResidualBatchInfoActionTypes,
  DownloadBatchReportActionTypes,
  DeleteResidualBatchActionTypes,
  ResidualRecalibrateScaleActionTypes,
  ModifyPaintFilmActionTypes,
  ProcessFiberAnalysisActionTypes,
  FetchFiberBatchInfoActionTypes,
  DeleteFiberBatchActionTypes,
  FiberRecalibrateScaleActionTypes,
  ProcessWhiteObjectsActionTypes,
  ModifyWhiteObjectsActionTypes,
  ModifyPaintFilmBatchActionTypes,
  ModifyFiberActionTypes,
  DownloadSegmentedImagesTypes,
} from "../actions/semAnalysis";

export type SemAnalysisState = {
  semAnalysisData: any;
  semAnalysisDataStatus: AsyncStates;
  semAnalysisDataError: String;

  classificationAnalysis: any;
  classificationAnalysisStatus: AsyncStates;
  classificationAnalysisError: String;

  semAnalysisMaskData: any;
  semAnalysisMaskDataStatus: AsyncStates;
  semAnalysisMaskDataError: String;

  semAnalysisList: any;
  semAnalysisListStatus: AsyncStates;
  semAnalysisListError: String;

  semAnalysisScale: any;
  semAnalysisScaleStatus: AsyncStates;
  semAnalysisScaleError: String;

  deleteSemAnalysisFiles: any;
  deleteSemAnalysisFilesStatus: AsyncStates;
  deleteSemAnalysisFilesError: String;

  imageSubtraction: any;
  imageSubtractionStatus: AsyncStates;
  imageSubtractionError: String;

  setImageSubtraction: any;
  setImageSubtractionStatus: AsyncStates;
  setImageSubtractionError: String;

  instanceDetection: any;
  instanceDetectionStatus: AsyncStates;
  instanceDetectionError: String;

  setSemAnalysisMaskHeightWidth: any;
  setSemAnalysisMaskHeightWidthStatus: AsyncStates;
  setSemAnalysisMaskHeightWidthError: String;

  semAnalysisMaskHeightWidth: any;
  semAnalysisMaskHeightWidthStatus: AsyncStates;
  semAnalysisMaskHeightWidthError: String;

  deleteSemAnalysisMaskHeightWidth: any;
  deleteSemAnalysisMaskHeightWidthStatus: AsyncStates;
  deleteSemAnalysisMaskHeightWidthError: String;

  downloadSemAnalysisReport: string;
  downloadSemAnalysisReportStatus: AsyncStates;
  downloadSemAnalysisReportError: String;

  deleteMasks: any;
  deleteMasksStatus: AsyncStates;
  deleteMasksError: String;

  updateClassificationStatus: AsyncStates;
  updateClassificationError: String;

  resetMasks: any,
  resetMasksStatus: AsyncStates,
  resetMasksError: string,

  processPaintFilmAnalysis: any,
  processPaintFilmAnalysisStatus: AsyncStates,
  processPaintFilmAnalysisError: string,

  paintFilmBatchInfo: any[],
  paintFilmBatchInfoStatus: AsyncStates,
  paintFilmBatchInfoError: string,

  downloadBatchReport: string,
  downloadBatchReportStatus: AsyncStates,
  downloadBatchReportError: String,

  deletePaintFilmBatch: any;
  deletePaintFilmBatchStatus: AsyncStates;
  deletePaintFilmBatchError: String;

  paintFilmRecalibrateScaleStatus: AsyncStates;
  paintFilmRecalibrateScaleError: String;

  analysisTypes: {};
  analysisTypesStatus: AsyncStates;
  analysisTypesError: String;

  residualBatchInfo: any[],
  residualBatchInfoStatus: AsyncStates,
  residualBatchInfoError: string,

  deleteResidualBatch: any;
  deleteResidualBatchStatus: AsyncStates;
  deleteResidualBatchError: String;

  residualRecalibrateScaleStatus: AsyncStates;
  residualRecalibrateScaleError: String;

  modifyPaintFilmStatus: AsyncStates;

  modifyPaintFilmBatchStatus: AsyncStates;
  modifyPaintFilmBatchData: any;
  modifyPaintFilmBatchError: '';

  processFiberAnalysis: {},
  processFiberAnalysisStatus: AsyncStates,
  processFiberAnalysisError: String,

  fiberBatchInfo: any[],
  fiberBatchInfoStatus: AsyncStates,
  fiberBatchInfoError: string,

  deleteFiberBatch: {},
  deleteFiberBatchStatus: AsyncStates,
  deleteFiberBatchError: String,

  fiberRecalibrateScaleStatus: AsyncStates;
  fiberRecalibrateScaleError: String;

  processWhiteObjects: {},
  processWhiteObjectsStatus: AsyncStates,
  processWhiteObjectsError: String,

  modifyWhiteObjects: {},
  modifyWhiteObjectsStatus: AsyncStates,
  modifyWhiteObjectsError: String,

  modifyFiberStatus: AsyncStates;

  downloadSegmentedImagesStatus: AsyncStates
};

const defaultState: SemAnalysisState = {
  semAnalysisData: {},
  semAnalysisDataStatus: AsyncStates.INITIAL,
  semAnalysisDataError: "",

  classificationAnalysis: {},
  classificationAnalysisStatus: AsyncStates.INITIAL,
  classificationAnalysisError: "",

  semAnalysisMaskData: {},
  semAnalysisMaskDataStatus: AsyncStates.INITIAL,
  semAnalysisMaskDataError: "",

  semAnalysisList: [],
  semAnalysisListStatus: AsyncStates.INITIAL,
  semAnalysisListError: "",

  semAnalysisScale: {},
  semAnalysisScaleStatus: AsyncStates.INITIAL,
  semAnalysisScaleError: "",

  deleteSemAnalysisFiles: {},
  deleteSemAnalysisFilesStatus: AsyncStates.INITIAL,
  deleteSemAnalysisFilesError: "",

  imageSubtraction: {},
  imageSubtractionStatus: AsyncStates.INITIAL,
  imageSubtractionError: "",

  setImageSubtraction: {},
  setImageSubtractionStatus: AsyncStates.INITIAL,
  setImageSubtractionError: "",

  instanceDetection: {},
  instanceDetectionStatus: AsyncStates.INITIAL,
  instanceDetectionError: "",

  setSemAnalysisMaskHeightWidth: {},
  setSemAnalysisMaskHeightWidthStatus: AsyncStates.INITIAL,
  setSemAnalysisMaskHeightWidthError: "",

  semAnalysisMaskHeightWidth: {},
  semAnalysisMaskHeightWidthStatus: AsyncStates.INITIAL,
  semAnalysisMaskHeightWidthError: "",

  deleteSemAnalysisMaskHeightWidth: '',
  deleteSemAnalysisMaskHeightWidthStatus: AsyncStates.INITIAL,
  deleteSemAnalysisMaskHeightWidthError: '',

  downloadSemAnalysisReport: '',
  downloadSemAnalysisReportStatus: AsyncStates.INITIAL,
  downloadSemAnalysisReportError: '',

  deleteMasks: '',
  deleteMasksStatus: AsyncStates.INITIAL,
  deleteMasksError: '',

  updateClassificationStatus: AsyncStates.INITIAL,
  updateClassificationError: '',

  resetMasks: '',
  resetMasksStatus: AsyncStates.INITIAL,
  resetMasksError: '',

  processPaintFilmAnalysis: {},
  processPaintFilmAnalysisStatus: AsyncStates.INITIAL,
  processPaintFilmAnalysisError: '',

  paintFilmBatchInfo: [],
  paintFilmBatchInfoStatus: AsyncStates.INITIAL,
  paintFilmBatchInfoError: '',

  downloadBatchReport: '',
  downloadBatchReportStatus: AsyncStates.INITIAL,
  downloadBatchReportError: '',

  deletePaintFilmBatch: {},
  deletePaintFilmBatchStatus: AsyncStates.INITIAL,
  deletePaintFilmBatchError: '',

  paintFilmRecalibrateScaleStatus: AsyncStates.INITIAL,
  paintFilmRecalibrateScaleError: '',

  analysisTypes: {},
  analysisTypesStatus: AsyncStates.INITIAL,
  analysisTypesError: '',

  residualBatchInfo: [],
  residualBatchInfoStatus: AsyncStates.INITIAL,
  residualBatchInfoError: '',

  deleteResidualBatch: {},
  deleteResidualBatchStatus: AsyncStates.INITIAL,
  deleteResidualBatchError: '',

  residualRecalibrateScaleStatus: AsyncStates.INITIAL,
  residualRecalibrateScaleError: '',

  modifyPaintFilmStatus: AsyncStates.INITIAL,

  modifyPaintFilmBatchStatus: AsyncStates.INITIAL,
  modifyPaintFilmBatchData: {},
  modifyPaintFilmBatchError: '',

  processFiberAnalysis: {},
  processFiberAnalysisStatus: AsyncStates.INITIAL,
  processFiberAnalysisError: '',

  fiberBatchInfo: [],
  fiberBatchInfoStatus: AsyncStates.INITIAL,
  fiberBatchInfoError: '',

  deleteFiberBatch: {},
  deleteFiberBatchStatus: AsyncStates.INITIAL,
  deleteFiberBatchError: '',

  fiberRecalibrateScaleStatus: AsyncStates.INITIAL,
  fiberRecalibrateScaleError: '',

  processWhiteObjects: {},
  processWhiteObjectsStatus: AsyncStates.INITIAL,
  processWhiteObjectsError: '',

  modifyWhiteObjects: {},
  modifyWhiteObjectsStatus: AsyncStates.INITIAL,
  modifyWhiteObjectsError: '',

  modifyFiberStatus: AsyncStates.INITIAL,

  downloadSegmentedImagesStatus: AsyncStates.INITIAL,
};

const semAnalysisReducer = handleActions<SemAnalysisState, any>(
  {
    [FetchSemAnalysisDataActionTypes.REQUEST]: (state) => ({
      ...state,
      semAnalysisDataStatus: AsyncStates.LOADING,
    }),
    [FetchSemAnalysisDataActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      semAnalysisDataStatus: AsyncStates.SUCCESS,
      semAnalysisData: action.payload ?? {},
    }),
    [FetchSemAnalysisDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      semAnalysisDataStatus: AsyncStates.ERROR,
      semAnalysisDataError: action.payload,
    }),
    [FetchSemAnalysisDataActionTypes.CLEAR]: (state, action) => ({
      ...state,
      semAnalysisDataStatus: AsyncStates.INITIAL,
    }),

    [FetchClassificationAnalysisActionTypes.REQUEST]: (state) => ({
      ...state,
      classificationAnalysisStatus: AsyncStates.LOADING,
    }),
    [FetchClassificationAnalysisActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      classificationAnalysisStatus: AsyncStates.SUCCESS,
      classificationAnalysis: action.payload ?? {},
    }),
    [FetchClassificationAnalysisActionTypes.FAILURE]: (state, action) => ({
      ...state,
      classificationAnalysisStatus: AsyncStates.ERROR,
      classificationAnalysisError: action.payload,
    }),
    [FetchClassificationAnalysisActionTypes.CLEAR]: (state, action) => ({
      ...state,
      classificationAnalysisStatus: AsyncStates.INITIAL
    }),

    [FetchSemAnalysisMaskDataActionTypes.REQUEST]: (state) => ({
      ...state,
      semAnalysisMaskDataStatus: AsyncStates.LOADING,
      semAnalysisMaskDataError: '',
    }),
    [FetchSemAnalysisMaskDataActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      semAnalysisMaskDataStatus: AsyncStates.SUCCESS,
      semAnalysisMaskData: action.payload ?? {},
    }),
    [FetchSemAnalysisMaskDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      semAnalysisMaskDataStatus: AsyncStates.ERROR,
      semAnalysisMaskDataError: action.payload,
    }),
    [FetchSemAnalysisMaskDataActionTypes.CLEAR]: (state, action) => ({
      ...state,
      semAnalysisMaskData: {},
      semAnalysisMaskDataStatus: AsyncStates.INITIAL,
      semAnalysisMaskDataError: '',
    }),

    [FetchSemAnalysisListActionTypes.REQUEST]: (state) => ({
      ...state,
      semAnalysisListStatus: AsyncStates.LOADING,
    }),
    [FetchSemAnalysisListActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      semAnalysisListStatus: AsyncStates.SUCCESS,
      semAnalysisList: action.payload ?? {},
    }),
    [FetchSemAnalysisListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      semAnalysisListStatus: AsyncStates.ERROR,
      semAnalysisListError: action.payload,
    }),
    [FetchSemAnalysisListActionTypes.CLEAR]: (state, action) => ({
      ...state,
      semAnalysisList: [],
      semAnalysisListStatus: AsyncStates.INITIAL,
      semAnalysisListError: '',
    }),

    [FetchSemAnalysisScaleActionTypes.REQUEST]: (state) => ({
      ...state,
      semAnalysisScaleStatus: AsyncStates.LOADING,
    }),
    [FetchSemAnalysisScaleActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      semAnalysisScaleStatus: AsyncStates.SUCCESS,
      semAnalysisScale: action.payload ?? {},
    }),
    [FetchSemAnalysisScaleActionTypes.FAILURE]: (state, action) => ({
      ...state,
      semAnalysisScaleStatus: AsyncStates.ERROR,
      semAnalysisScaleError: action.payload,
    }),

    [DeleteSemAnalysisFilesActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteSemAnalysisFilesStatus: AsyncStates.LOADING,
    }),
    [DeleteSemAnalysisFilesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteSemAnalysisFilesStatus: AsyncStates.SUCCESS,
      deleteSemAnalysisFiles: action.payload ?? {},
    }),
    [DeleteSemAnalysisFilesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteSemAnalysisFilesStatus: AsyncStates.ERROR,
      deleteSemAnalysisFilesError: action.payload,
    }),
    [DeleteSemAnalysisFilesActionTypes.CLEAR]: (state, action) => ({
      ...state,
      deleteSemAnalysisFilesStatus: AsyncStates.INITIAL,
    }),

    [FetchImageSubtractionActionTypes.REQUEST]: (state) => ({
      ...state,
      imageSubtractionStatus: AsyncStates.LOADING,
    }),
    [FetchImageSubtractionActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      imageSubtractionStatus: AsyncStates.SUCCESS,
      imageSubtraction: action.payload ?? {},
    }),
    [FetchImageSubtractionActionTypes.FAILURE]: (state, action) => ({
      ...state,
      imageSubtractionStatus: AsyncStates.ERROR,
      imageSubtractionError: action.payload,
    }),

    [SetImageSubtractionActionTypes.REQUEST]: (state) => ({
      ...state,
      setImageSubtractionStatus: AsyncStates.LOADING,
    }),
    [SetImageSubtractionActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      setImageSubtractionStatus: AsyncStates.SUCCESS,
      setImageSubtraction: action.payload ?? {},
    }),
    [SetImageSubtractionActionTypes.FAILURE]: (state, action) => ({
      ...state,
      setImageSubtractionStatus: AsyncStates.ERROR,
      setImageSubtractionError: action.payload,
    }),
    [SetImageSubtractionActionTypes.CLEAR]: (state, action) => ({
      ...state,
      setImageSubtractionStatus: AsyncStates.INITIAL,
    }),

    [FetchInstanceDetectionActionTypes.REQUEST]: (state) => ({
      ...state,
      instanceDetectionStatus: AsyncStates.LOADING,
    }),
    [FetchInstanceDetectionActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      instanceDetectionStatus: AsyncStates.SUCCESS,
      instanceDetection: action.payload ?? {},
    }),
    [FetchInstanceDetectionActionTypes.FAILURE]: (state, action) => ({
      ...state,
      instanceDetectionStatus: AsyncStates.ERROR,
      instanceDetectionError: action.payload,
    }),
    [FetchInstanceDetectionActionTypes.CLEAR]: (state) => ({
      ...state,
      instanceDetectionStatus: AsyncStates.INITIAL,
      instanceDetectionError: "",
      instanceDetection: {},
    }),

    [SetSemAnalysisMaskHeightWidthActionTypes.REQUEST]: (state) => ({
      ...state,
      setSemAnalysisMaskHeightWidthStatus: AsyncStates.LOADING,
    }),
    [SetSemAnalysisMaskHeightWidthActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      setSemAnalysisMaskHeightWidthStatus: AsyncStates.SUCCESS,
      setSemAnalysisMaskHeightWidth: action.payload ?? {},
    }),
    [SetSemAnalysisMaskHeightWidthActionTypes.FAILURE]: (state, action) => ({
      ...state,
      setSemAnalysisMaskHeightWidthStatus: AsyncStates.ERROR,
      setSemAnalysisMaskHeightWidthError: action.payload,
    }),

    [FetchSemAnalysisMaskHeightWidthActionTypes.REQUEST]: (state) => ({
      ...state,
      semAnalysisMaskHeightWidthStatus: AsyncStates.LOADING,
    }),
    [FetchSemAnalysisMaskHeightWidthActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      semAnalysisMaskHeightWidthStatus: AsyncStates.SUCCESS,
      semAnalysisMaskHeightWidth: action.payload ?? {},
    }),
    [FetchSemAnalysisMaskHeightWidthActionTypes.FAILURE]: (state, action) => ({
      ...state,
      semAnalysisMaskHeightWidthStatus: AsyncStates.ERROR,
      semAnalysisMaskHeightWidthError: action.payload,
    }),
    [FetchSemAnalysisMaskHeightWidthActionTypes.CLEAR]: (state) => ({
      ...state,
      semAnalysisMaskHeightWidthStatus: AsyncStates.INITIAL,
      semAnalysisMaskHeightWidthError: "",
      semAnalysisMaskHeightWidth: {},
    }),
    [DeleteSemAnalysisMaskHeightWidthActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteSemAnalysisMaskHeightWidthStatus: AsyncStates.LOADING,
    }),
    [DeleteSemAnalysisMaskHeightWidthActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteSemAnalysisMaskHeightWidthStatus: AsyncStates.SUCCESS,
      deleteSemAnalysisMaskHeightWidth: action.payload ?? '',
    }),
    [DeleteSemAnalysisMaskHeightWidthActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteSemAnalysisMaskHeightWidthStatus: AsyncStates.ERROR,
      deleteSemAnalysisMaskHeightWidthError: action.payload,
    }),

    [DownloadSemAnalysisReportActionTypes.REQUEST]: (state) => ({
      ...state,
      downloadSemAnalysisReportStatus: AsyncStates.LOADING,
    }),
    [DownloadSemAnalysisReportActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      downloadSemAnalysisReportStatus: AsyncStates.SUCCESS,
      downloadSemAnalysisReport: action.payload ?? '',
    }),
    [DownloadSemAnalysisReportActionTypes.FAILURE]: (state, action) => ({
      ...state,
      downloadSemAnalysisReportStatus: AsyncStates.ERROR,
      downloadSemAnalysisReportError: action.payload,
    }),

    [DeleteMasksActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteMasksStatus: AsyncStates.LOADING,
    }),
    [DeleteMasksActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteMasksStatus: AsyncStates.SUCCESS,
      deleteMasksError: action.payload ?? '',
    }),
    [DeleteMasksActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteMasksStatus: AsyncStates.ERROR,
      deleteMasksError: action.payload,
    }),

    [UpdateClassificationActionTypes.REQUEST]: (state) => ({
      ...state,
      updateClassificationStatus: AsyncStates.LOADING,
    }),
    [UpdateClassificationActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      updateClassificationStatus: AsyncStates.SUCCESS,
      classificationAnalysis: action.payload ?? {},
    }),
    [UpdateClassificationActionTypes.FAILURE]: (state, action) => ({
      ...state,
      updateClassificationStatus: AsyncStates.ERROR,
      updateClassificationError: action.payload,
    }),

    [ResetMasksActionTypes.REQUEST]: (state) => ({
      ...state,
      resetMasksStatus: AsyncStates.LOADING,
    }),
    [ResetMasksActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      resetMasksStatus: AsyncStates.SUCCESS,
      resetMasksError: action.payload ?? '',
    }),
    [ResetMasksActionTypes.FAILURE]: (state, action) => ({
      ...state,
      resetMasksStatus: AsyncStates.ERROR,
      resetMasksError: action.payload,
    }),

    [ProcessPaintFilmAnalysisActionTypes.REQUEST]: (state) => ({
      ...state,
      processPaintFilmAnalysisStatus: AsyncStates.LOADING,
    }),
    [ProcessPaintFilmAnalysisActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      processPaintFilmAnalysis: action.payload ?? {},
      processPaintFilmAnalysisStatus: AsyncStates.SUCCESS,
    }),
    [ProcessPaintFilmAnalysisActionTypes.FAILURE]: (state, action) => ({
      ...state,
      processPaintFilmAnalysisStatus: AsyncStates.ERROR,
      processPaintFilmAnalysisError: action.payload,
    }),
    [ProcessPaintFilmAnalysisActionTypes.CLEAR]: (state, action) => ({
      ...state,
      processPaintFilmAnalysisStatus: AsyncStates.INITIAL,
    }),

    [FetchPaintFilmBatchInfoActionTypes.REQUEST]: (state) => ({
      ...state,
      paintFilmBatchInfoStatus: AsyncStates.LOADING,
    }),
    [FetchPaintFilmBatchInfoActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      paintFilmBatchInfoStatus: AsyncStates.SUCCESS,
      paintFilmBatchInfo: action.payload ?? []
    }),
    [FetchPaintFilmBatchInfoActionTypes.FAILURE]: (state, action) => ({
      ...state,
      paintFilmBatchInfoStatus: AsyncStates.ERROR,
      paintFilmBatchInfoError: action.payload,
    }),
    [FetchPaintFilmBatchInfoActionTypes.CLEAR]: (state) => ({
      ...state,
      paintFilmBatchInfo: [],
      paintFilmBatchInfoStatus: AsyncStates.INITIAL,
      paintFilmBatchInfoError: "",
    }),

    [DownloadBatchReportActionTypes.REQUEST]: (state) => ({
      ...state,
      downloadBatchReportStatus: AsyncStates.LOADING,
    }),
    [DownloadBatchReportActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      downloadBatchReportStatus: AsyncStates.SUCCESS,
      downloadBatchReport: action.payload ?? '',
    }),
    [DownloadBatchReportActionTypes.FAILURE]: (state, action) => ({
      ...state,
      downloadBatchReportStatus: AsyncStates.ERROR,
      downloadBatchReportError: action.payload,
    }),

    [DeletePaintFilmBatchActionTypes.REQUEST]: (state) => ({
      ...state,
      deletePaintFilmBatchStatus: AsyncStates.LOADING,
    }),
    [DeletePaintFilmBatchActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deletePaintFilmBatchStatus: AsyncStates.SUCCESS,
      deletePaintFilmBatch: action.payload ?? {},
    }),
    [DeletePaintFilmBatchActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deletePaintFilmBatchStatus: AsyncStates.ERROR,
      deletePaintFilmBatchError: action.payload,
    }),
    [DeletePaintFilmBatchActionTypes.CLEAR]: (state, action) => ({
      ...state,
      deletePaintFilmBatchStatus: AsyncStates.INITIAL,
    }),

    [PaintFilmRecalibrateScaleActionTypes.REQUEST]: (state) => ({
      ...state,
      paintFilmRecalibrateScaleStatus: AsyncStates.LOADING,
    }),
    [PaintFilmRecalibrateScaleActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      paintFilmRecalibrateScaleStatus: AsyncStates.SUCCESS
    }),
    [PaintFilmRecalibrateScaleActionTypes.FAILURE]: (state, action) => ({
      ...state,
      paintFilmRecalibrateScaleStatus: AsyncStates.ERROR,
      paintFilmRecalibrateScaleError: action.payload,
    }),
    [PaintFilmRecalibrateScaleActionTypes.CLEAR]: (state, action) => ({
      ...state,
      paintFilmRecalibrateScaleStatus: AsyncStates.INITIAL
    }),

    [AnalysisTypesActionTypes.REQUEST]: (state) => ({
      ...state,
      analysisTypesStatus: AsyncStates.LOADING,
    }),
    [AnalysisTypesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      analysisTypesStatus: AsyncStates.SUCCESS,
      analysisTypes: action.payload ?? {},
    }),
    [AnalysisTypesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      analysisTypesStatus: AsyncStates.ERROR,
      analysisTypesError: action.payload,
    }),

    [FetchResidualBatchInfoActionTypes.REQUEST]: (state) => ({
      ...state,
      residualBatchInfoStatus: AsyncStates.LOADING,
    }),
    [FetchResidualBatchInfoActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      residualBatchInfoStatus: AsyncStates.SUCCESS,
      residualBatchInfo: action.payload ?? []
    }),
    [FetchResidualBatchInfoActionTypes.FAILURE]: (state, action) => ({
      ...state,
      residualBatchInfoStatus: AsyncStates.ERROR,
      residualBatchInfoError: action.payload,
    }),
    [FetchResidualBatchInfoActionTypes.CLEAR]: (state) => ({
      ...state,
      residualBatchInfo: [],
      residualBatchInfoStatus: AsyncStates.INITIAL,
      residualBatchInfoError: "",
    }),

    [DeleteResidualBatchActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteResidualBatchStatus: AsyncStates.LOADING,
    }),
    [DeleteResidualBatchActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteResidualBatchStatus: AsyncStates.SUCCESS,
      deleteResidualBatch: action.payload ?? {},
    }),
    [DeleteResidualBatchActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteResidualBatchStatus: AsyncStates.ERROR,
      deleteResidualBatchError: action.payload,
    }),
    [DeleteResidualBatchActionTypes.CLEAR]: (state, action) => ({
      ...state,
      deleteResidualBatchStatus: AsyncStates.INITIAL,
    }),

    [ResidualRecalibrateScaleActionTypes.REQUEST]: (state) => ({
      ...state,
      residualRecalibrateScaleStatus: AsyncStates.LOADING,
    }),
    [ResidualRecalibrateScaleActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      residualRecalibrateScaleStatus: AsyncStates.SUCCESS
    }),
    [ResidualRecalibrateScaleActionTypes.FAILURE]: (state, action) => ({
      ...state,
      residualRecalibrateScaleStatus: AsyncStates.ERROR,
      residualRecalibrateScaleError: action.payload,
    }),
    [ResidualRecalibrateScaleActionTypes.CLEAR]: (state, action) => ({
      ...state,
      residualRecalibrateScaleStatus: AsyncStates.INITIAL
    }),

    [ModifyPaintFilmActionTypes.REQUEST]: (state) => ({
      ...state,
      modifyPaintFilmStatus: AsyncStates.LOADING,
    }),
    [ModifyPaintFilmActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      modifyPaintFilmStatus: AsyncStates.SUCCESS
    }),
    [ModifyPaintFilmActionTypes.FAILURE]: (state, action) => ({
      ...state,
      modifyPaintFilmStatus: AsyncStates.ERROR
    }),

    [ModifyPaintFilmBatchActionTypes.REQUEST]: (state) => ({
      ...state,
      modifyPaintFilmBatchStatus: AsyncStates.LOADING,
    }),
    [ModifyPaintFilmBatchActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      modifyPaintFilmBatchStatus: AsyncStates.SUCCESS,
      modifyPaintFilmBatchData: action.payload
    }),
    [ModifyPaintFilmBatchActionTypes.FAILURE]: (state, action) => ({
      ...state,
      modifyPaintFilmBatchStatus: AsyncStates.ERROR
    }),
    [ModifyPaintFilmBatchActionTypes.CLEAR]: (state, action) => ({
      ...state,
      modifyPaintFilmBatchStatus: AsyncStates.INITIAL,
      modifyPaintFilmBatchData: {}
    }),

    [ProcessFiberAnalysisActionTypes.REQUEST]: (state) => ({
      ...state,
      processFiberAnalysisStatus: AsyncStates.LOADING,
    }),
    [ProcessFiberAnalysisActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      processFiberAnalysis: action.payload ?? {},
      processFiberAnalysisStatus: AsyncStates.SUCCESS,
    }),
    [ProcessFiberAnalysisActionTypes.FAILURE]: (state, action) => ({
      ...state,
      processFiberAnalysisStatus: AsyncStates.ERROR,
      processFiberAnalysisError: action.payload,
    }),
    [ProcessFiberAnalysisActionTypes.CLEAR]: (state, action) => ({
      ...state,
      processFiberAnalysisStatus: AsyncStates.INITIAL,
    }),

    [FetchFiberBatchInfoActionTypes.REQUEST]: (state) => ({
      ...state,
      fiberBatchInfoStatus: AsyncStates.LOADING,
    }),
    [FetchFiberBatchInfoActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      fiberBatchInfoStatus: AsyncStates.SUCCESS,
      fiberBatchInfo: action.payload ?? []
    }),
    [FetchFiberBatchInfoActionTypes.FAILURE]: (state, action) => ({
      ...state,
      fiberBatchInfoStatus: AsyncStates.ERROR,
      fiberBatchInfoError: action.payload,
    }),
    [FetchFiberBatchInfoActionTypes.CLEAR]: (state) => ({
      ...state,
      fiberBatchInfo: [],
      fiberBatchInfoStatus: AsyncStates.INITIAL,
      fiberBatchInfoError: "",
    }),

    [DeleteFiberBatchActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteFiberBatchStatus: AsyncStates.LOADING,
    }),
    [DeleteFiberBatchActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteFiberBatchStatus: AsyncStates.SUCCESS,
      deleteFiberBatch: action.payload ?? {},
    }),
    [DeleteFiberBatchActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteFiberBatchStatus: AsyncStates.ERROR,
      deleteFiberBatchError: action.payload,
    }),
    [DeleteFiberBatchActionTypes.CLEAR]: (state, action) => ({
      ...state,
      deleteFiberBatchStatus: AsyncStates.INITIAL,
    }),

    [FiberRecalibrateScaleActionTypes.REQUEST]: (state) => ({
      ...state,
      fiberRecalibrateScaleStatus: AsyncStates.LOADING,
    }),
    [FiberRecalibrateScaleActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      fiberRecalibrateScaleStatus: AsyncStates.SUCCESS
    }),
    [FiberRecalibrateScaleActionTypes.FAILURE]: (state, action) => ({
      ...state,
      fiberRecalibrateScaleStatus: AsyncStates.ERROR,
      fiberRecalibrateScaleError: action.payload,
    }),
    [FiberRecalibrateScaleActionTypes.CLEAR]: (state, action) => ({
      ...state,
      fiberRecalibrateScaleStatus: AsyncStates.INITIAL
    }),

    [ProcessWhiteObjectsActionTypes.REQUEST]: (state) => ({
      ...state,
      processWhiteObjectsStatus: AsyncStates.LOADING,
    }),
    [ProcessWhiteObjectsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      processWhiteObjects: action.payload ?? {},
      processWhiteObjectsStatus: AsyncStates.SUCCESS,
    }),
    [ProcessWhiteObjectsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      processWhiteObjectsStatus: AsyncStates.ERROR,
      processWhiteObjectsError: action.payload,
    }),
    [ProcessWhiteObjectsActionTypes.CLEAR]: (state, action) => ({
      ...state,
      processWhiteObjectsStatus: AsyncStates.INITIAL,
    }),

    [ModifyWhiteObjectsActionTypes.REQUEST]: (state) => ({
      ...state,
      modifyWhiteObjectsStatus: AsyncStates.LOADING,
    }),
    [ModifyWhiteObjectsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      modifyWhiteObjects: action.payload ?? {},
      modifyWhiteObjectsStatus: AsyncStates.SUCCESS,
    }),
    [ModifyWhiteObjectsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      modifyWhiteObjectsStatus: AsyncStates.ERROR,
      modifyWhiteObjectsError: action.payload,
    }),
    [ModifyWhiteObjectsActionTypes.CLEAR]: (state, action) => ({
      ...state,
      modifyWhiteObjectsStatus: AsyncStates.INITIAL,
    }),

    [ModifyFiberActionTypes.REQUEST]: (state) => ({
      ...state,
      modifyFiberStatus: AsyncStates.LOADING,
    }),
    [ModifyFiberActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      modifyFiberStatus: AsyncStates.SUCCESS
    }),
    [ModifyFiberActionTypes.FAILURE]: (state, action) => ({
      ...state,
      modifyFiberStatus: AsyncStates.ERROR
    }),

    [DownloadSegmentedImagesTypes.REQUEST]: (state) => ({
      ...state,
      downloadSegmentedImagesStatus: AsyncStates.LOADING,
    }),
    [DownloadSegmentedImagesTypes.SUCCESS]: (state, action) => ({
      ...state,
      downloadSegmentedImagesStatus: AsyncStates.SUCCESS
    }),
    [DownloadSegmentedImagesTypes.FAILURE]: (state, action) => ({
      ...state,
      downloadSegmentedImagesStatus: AsyncStates.ERROR
    }),
  },
  defaultState
);

export default semAnalysisReducer;

import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Space,
  Table,
  Input,
  Select,
  Typography,
  Row,
  Spin,
  Dropdown,
  Menu,
  Popover,
  List,
  Tooltip,
} from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { checkPropertyExistsClear, checkPropertyExistsRequest, inventoryAttachmentsListRequest, setInventoryData } from "src/store/actions/inventory"
import { antdTheme, AsyncStates } from 'src/constants'
import {
  ContainerOutlined,
  EditOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { motion } from 'framer-motion'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import { StyledCard } from 'src/styled_components/StyledCard'
import { StyledButton } from 'src/styled_components/StyledButton'
import { useHistory } from 'react-router-dom'
import useTranslate from 'src/utils/useTranslate'
import { DeleteModal } from './modals/DeleteModal'
import { WorkOrdersModal } from './modals/WorkOrdersModal'
import { displayNamesRequest } from 'src/store/actions/displayNames'
import { sortArray, sortByOrder } from "src/utils/general/sort"
import { AttachmentsModal } from './modals/AttachmentsModal'
import { useQuery } from 'src/utils/useQuery'
import { AddUnitModal } from '../UnitsConversion/modals'
import { unitListRequest } from 'src/store/actions/conversion'
import { FileUploadModal } from './modals/FileUploadModal'
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'


const { Option } = Select
const { Text, Title } = Typography

export const Inventory = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const params = useQuery()
  const projectList = useSelector((state: StoreState) => state.projects.projectList)
  const inventoryDataList = useSelector((state: StoreState) => state.displayNames.inventoryData)
  const inventoryDataStatus = useSelector((state: StoreState) => state.displayNames.statusWithoutSpinner)
  const inventoryUploadStatus = useSelector((state: StoreState) => state.inventory.inventoryUploadStatus)
  const inventoryFileUploadTaskId = useSelector((state: StoreState) => state.inventory.inventoryFileUploadTaskId)
  const { deletePropertyStatus, updateParameterStatus, createParameterStatus } = useSelector((state: StoreState) => state.inventory)
  const { currency } = useSelector((store: StoreState) => store.login.loginResponse);
  const configs = useSelector((state: StoreState) => state.configs.features)
  const [data, setData] = useState<any>([])
  const [initailData, setInitialData] = useState<any>([])
  const [type, setType] = useState<string>(params.get("value") ?? "characterizations")
  const [searchValue, setSearchValue] = useState<string>("")
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [workOrdersVisisble, setWorkOrdersVisible] = useState<boolean>(false)
  const [attachmentsModalVisible, setAttachmentsModalVisible] = useState<boolean>(false)
  const [dataExistsCheck, setDataExistsCheck] = useState(false)
  const [selectedRows, setSelectRows] = useState([])
  const [showCard, setShowCard] = useState(false)
  const [fileUploadVisible, setFileUploadVisible] = useState(false)

  const { push } = useHistory()

  useEffect(() => {
    setType((params.get("value") as any) ?? "characterizations");
  }, [params]);

  useEffect(() => {
    dispatch(displayNamesRequest({ backgroundFetch: true }));
  }, [dispatch]);

  useEffect(() => {
    if (deletePropertyStatus === AsyncStates.SUCCESS) {
      setDeleteModalVisible(false);
      setSelectRows([]);
    }
  }, [deletePropertyStatus]);

  const resetData = useCallback(() => {
    dispatch(checkPropertyExistsClear());
    setDeleteModalVisible(false);
    setWorkOrdersVisible(false);
    setSelectRows([]);
    setDataExistsCheck(false);
    setSearchValue("");
  }, [dispatch]);

  const setModal = useCallback(
    (record: any, modalType: string) => {
      if (modalType === "delete") {
        setDataExistsCheck(
          selectedRows?.some((res: any) => !!res?.project_id?.length)
        );
        setDeleteModalVisible(true);
      } else if (modalType === "edit") {
        record.type = type;
        dispatch(setInventoryData(record));
        push("/inventory/library/update");
        setDataExistsCheck(!!record?.project_id?.length);
      } else if (modalType === "view") {
        record.type = type;
        dispatch(setInventoryData(record));
        dispatch(
          checkPropertyExistsRequest({ type, identifier: record?.identifier })
        );
        setWorkOrdersVisible(true);
      } else if (modalType === "attachments") {
        record.type = type;
        dispatch(setInventoryData(record));
        dispatch(
          inventoryAttachmentsListRequest({
            type,
            identifier: record?.identifier,
          })
        );
        setAttachmentsModalVisible(true);
      }
    },
    [dispatch, type, selectedRows, push]
  );

  const filterCategories = useMemo(() => {
    return [
      ...new Set(
        inventoryDataList?.[type]
          ?.map((res: any) => res?.category)
          .filter((res: any) => !!res?.trim())
      ),
    ];
  }, [inventoryDataList, type]);

  const filterSubCategories = useMemo(() => {
    return [
      ...new Set(
        inventoryDataList?.ingredients
          ?.map((res: any) => res?.sub_category)
          .filter((res: any) => !!res?.trim())
      ),
    ];
  }, [inventoryDataList]);

  const filterLot = useMemo(() => {
    return [
      ...new Set(
        inventoryDataList?.[type]
          ?.map((res: any) => res?.lot_no)
          .filter((res: any) => !!res?.trim())
      ),
    ];
  }, [inventoryDataList, type]);

  const filterSupplier = useMemo(() => {
    return [
      ...new Set(
        inventoryDataList?.[type]
          ?.map((res: any) => res?.supplier)
          .filter((res: any) => !!res?.trim())
      ),
    ];
  }, [inventoryDataList, type]);

  const getProjectsContent = useCallback(
    (rowValue) => {
      return (
        <div style={{ maxWidth: 1000, maxHeight: 500, overflow: "auto" }}>
          <List
            size="small"
            dataSource={projectList.filter((project: any) =>
              !!rowValue?.length
                ? rowValue?.includes(project?.project_id)
                : false
            )}
            renderItem={(item: any) => (
              <List.Item>
                <Text strong>{item?.name}</Text>
              </List.Item>
            )}
          />
        </div>
      );
    },
    [projectList]
  );

  const columns: any = useMemo(() => {
    const data: any = [
      {
        key: "name",
        title: t("common.Name"),
        dataIndex: "name",
        width: 200,
        fixed: "left",
        render: (name: string) => (
          <Text style={{ wordBreak: "break-word" }}>{name || "-"}</Text>
        ),
        sorter: (a: any, b: any, c: any) => {
          if (c === "ascend") {
            return b.name?.toLocaleLowerCase() > a.name?.toLocaleLowerCase()
              ? -1
              : 1;
          } else {
            return b.name?.toLocaleLowerCase() < a.name?.toLocaleLowerCase()
              ? 1
              : -1;
          }
        },
      },
      {
        key: "category",
        title: t("common.category"),
        dataIndex: "category",
        width: 150,
        render: (text: string, record: any) => (
          <Text style={{ wordBreak: "break-word" }}>{text}</Text>
        ),
        filters: filterCategories
          .map((res: any) => ({
            text: res,
            value: res,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          ),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.category === value,
        sorter: (a: any, b: any, c: any) => {
          if (c === "ascend") {
            return b.category?.toLocaleLowerCase() >
              a.category?.toLocaleLowerCase()
              ? -1
              : 1;
          } else {
            return b.category?.toLocaleLowerCase() <
              a.category?.toLocaleLowerCase()
              ? 1
              : -1;
          }
        },
      },
    ];
    if (type === "ingredients") {
      data.push({
        key: "sub_category",
        title: t("inventory.subCategory"),
        dataIndex: "sub_category",
        width: 150,
        render: (text: string, record: any) => (
          <Text style={{ wordBreak: "break-word" }}>
            {record?.sub_category}
          </Text>
        ),
        filters: filterSubCategories
          .map((res: any) => ({
            text: res,
            value: res,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          ),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.sub_category === value,
        sorter: (a: any, b: any, c: any) =>
          sortByOrder(
            a.sub_category?.toLowerCase(),
            b.sub_category?.toLowerCase(),
            c
          ),
      });
      // data.push({
      // 	key: 'range',
      // 	title: t("common.range"),
      // 	dataIndex: 'range',
      // 	width: 150,
      // 	render: (text: any, record: any) => {
      // 		let value = null
      // 		if (!["", null, undefined].includes(text?.min) && !["", null, undefined].includes(text?.max)) {
      // 			value = `${text?.min} - ${text?.max}`
      // 		}
      // 		else if (!["", null, undefined].includes(text?.min)) {
      // 			value = `${t("common.min")}: ${text?.min}`
      // 		}
      // 		else if (!["", null, undefined].includes(text?.max)) {
      // 			value = `${t("common.max")}: ${text?.max}`
      // 		}
      // 		return <Text style={{ wordBreak: "break-word" }}>{value}</Text>
      // 	},
      // })
    }
    data.push({
      key: "unit",
      title: (
        <Text style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ color: "#222" }}>{t("common.unit")}</span>
          <span
            style={{ cursor: "pointer", color: antdTheme.colorPrimary }}
            onClick={() => setShowCard(true)}
          >
            {t("units.addUnit")}
          </span>
        </Text>
      ),
      dataIndex: "unit",
      width: 100,
      render: (rowValue: any, row: any, index: any) => {
        if (typeof rowValue === "string") return rowValue;
        const units = rowValue?.filter((value: any) => !!value);
        return units?.length > 0 ? units?.join(", ") : "-";
      },
    });
    if (type !== "metadata_properties") {
      data.push({
        key: "project_id",
        title: t("projects.header.title"),
        dataIndex: "project_id",
        width: 200,
        render: (rowValue: any, row: any, index: any) => {
          return (
            <Popover
              content={getProjectsContent(rowValue)}
              title={
                <Title level={5} type="secondary">
                  {t("projects.header.title")}
                </Title>
              }
              trigger="hover"
              placement="left"
            >
              <Text style={{ width: 250 }} ellipsis={{}}>
                {!!rowValue?.length
                  ? rowValue
                    ?.map(
                      (res: any) =>
                        projectList.find(
                          (project: any) => project?.project_id === res
                        )?.name || res
                    )
                    ?.join(", ")
                  : ""}
              </Text>
            </Popover>
          );
        },
        filters: projectList
          .map((res: any) => ({
            text: res?.name,
            value: res?.project_id,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          )
          .concat({ text: "None", value: null }),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return !value
            ? !record?.project_id?.length
            : record?.project_id?.includes(value);
        },
      });
    }
    if (type === "ingredients") {
      data.push({
        key: "lot_no",
        dataIndex: "lot_no",
        title: t("inventory.lot_no"),
        width: 150,
        filters: filterLot
          .map((res: any) => ({
            text: res,
            value: res,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record.lot_no === value,
      });
      data.push({
        key: "supplier",
        dataIndex: "supplier",
        title: t("inventory.supplier"),
        width: 150,
        filters: filterSupplier
          .map((res: any) => ({
            text: res,
            value: res,
          }))
          .sort((value1, value2) =>
            sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())
          ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record.supplier === value,
      });
      data.push({
        key: "costing",
        dataIndex: "costing",
        title: (
          <Text>
            {t("inventory.costing")}{" "}{`(In ${currency?.currency_code})`}
          </Text>
        ),
        width: 200,
        render: (costing: any, record: any) => {
          const isCostingNull = Object.values(costing || {}).some((value) => {
            if (value === null) {
              return true;
            }
            return false;
          });
          const costingValue = !isCostingNull
            ? !!costing && typeof costing === "object"
              ? `${costing?.amount} per ${costing?.quantity} ${costing?.unit}`
              : costing
            : null;
          return (
            <Tooltip title={costingValue}>
              <Text style={{ width: "100px" }} ellipsis={{}}>
                {costingValue}
              </Text>
            </Tooltip>
          );
        },
      });
    }
    if (Boolean(configs?.nestle_configs)) {
      data.push({
        key: "main_group",
        title: "Main Group",
        dataIndex: "main_group",
        width: 150,
        align: "center",
        render: (text: any, record: any) => {
          return !!text?.length ? text.join(", ") : null;
        },
      });
      data.push({
        key: "sub_group",
        title: "Sub Group",
        dataIndex: "sub_group",
        width: 150,
        align: "center",
        render: (text: any, record: any) => {
          return !!text?.length ? text.join(", ") : null;
        },
      });
    }
    data.push({
      key: "actions",
      title: `${t("common.actions")}`,
      dataIndex: "actions",
      width: 100,
      align: "center",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item onClick={() => setModal(record, "edit")} key="edit">
                  <StyledButton type="text" icon={<EditOutlined />}>
                    {t("common.edit")}
                  </StyledButton>
                </Menu.Item>
                {type !== "metadata_properties" && (
                  <Menu.Item
                    onClick={() => setModal(record, "view")}
                    key="view"
                  >
                    <StyledButton type="text" icon={<ContainerOutlined />}>
                      {t("common.viewWorkOrders")}
                    </StyledButton>
                  </Menu.Item>
                )}
                {type === "ingredients" && (
                  <Menu.Item
                    onClick={() => setModal(record, "attachments")}
                    key="attachments"
                  >
                    <StyledButton type="text" icon={<FileExcelOutlined />}>
                      {t("common.attachments")}
                    </StyledButton>
                  </Menu.Item>
                )}
              </Menu>
            )}
          >
            {<MoreOutlined style={{ cursor: "pointer" }} />}
          </Dropdown>
        );
      },
    });
    return data;
  }, [
    filterCategories,
    filterSubCategories,
    filterLot,
    filterSupplier,
    t,
    setModal,
    type,
    projectList,
    getProjectsContent,
    configs,
    currency?.currency_code,
  ]);

  const setInitialDisplayNames: any = useCallback(() => {
    setData(
      inventoryDataList?.[type]?.map((res: any, index: number) => ({
        ...res,
        key: index,
      }))
    );
    setInitialData(
      inventoryDataList?.[type]?.map((res: any, index: number) => ({
        ...res,
        key: index,
      }))
    );
  }, [inventoryDataList, type]);

  useEffect(() => {
    setInitialDisplayNames();
  }, [setInitialDisplayNames]);

  const searchDisplayNames = (e: any) => {
    setSearchValue(e.target.value)
    const value = e.target.value?.toLocaleLowerCase()?.trim()
    if (value?.trim()) {
      setData(initailData?.filter((res: any) => res?.name?.toLocaleLowerCase()?.includes(value)))
      setSelectRows((prevState: any) => prevState.filter((res: any) => res?.name?.toLocaleLowerCase()?.includes(value)))
    } else {
      setInitialDisplayNames()
    }
  }

  const [uploadTaskStatus, setUploadTaskStatus] = useState<AsyncStates>(AsyncStates.INITIAL)
  useEffect(() => {
    let unsub: Unsubscribe
    const listenToFileUploadTaskStatus = async () => {
      const taskDocRef = doc(firestoreDb, `${FB_COLLECTION_NAME_TASKERS}/${inventoryFileUploadTaskId}`)
      unsub = onSnapshot(taskDocRef, (doc) => {
        const taskDoc = doc.data()
        if (taskDoc) {
          const taskStatus = taskDoc?.status
          if (taskStatus === "STARTED") {
            setUploadTaskStatus(AsyncStates.LOADING)
          }
          if (taskStatus === "COMPLETED") {
            setUploadTaskStatus(AsyncStates.SUCCESS)
            dispatch(displayNamesRequest({ backgroundFetch: true }))
            unsub()
          }
        }
      })
    }
    if (inventoryFileUploadTaskId) {
      listenToFileUploadTaskStatus()
    }
    return () => {
      (uploadTaskStatus === AsyncStates.SUCCESS && unsub) && unsub()
    }
  }, [inventoryFileUploadTaskId, uploadTaskStatus, dispatch])


  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%", overflowX: "auto", height: "100%" }}>
        <StyledPageHeader ghost={false} title={t("inventory.Library")} extra={<Input.Search style={{ width: 300 }}
          placeholder={t("common.search")}
          onChange={searchDisplayNames}
          allowClear
          value={searchValue}
          enterButton
        />} />
        <Spin spinning={inventoryDataStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
          <StyledCard bodyStyle={{ padding: "8px 16px", height: '100%' }}>
            <div>{t("inventory.doubleclick.note")}</div>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Table
                scroll={{ x: 450, y: window.screen.availWidth <= 1024 ? "55vh" : window.screen.availWidth >= 1920 ? "70vh" : "80vh" }}
                pagination={{ defaultPageSize: 30, pageSizeOptions: [30, 60, 90, 120] }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: (event) => setModal(record, "edit")
                  };
                }}
                onChange={(_, filters) => {
                  const { category, project_id }: any = filters
                  let filteredData = [...selectedRows]
                  if (!!category?.length) {
                    filteredData = filteredData.filter((row: any) => category?.includes(row?.category))
                  }
                  if (!!project_id?.length) {
                    if (project_id?.includes(null)) {
                      filteredData = filteredData.filter((row: any) => (project_id?.some((res: any) => row?.project_id?.includes(res))) || (row?.project_id?.length === 0 || row?.project_id?.length === undefined))
                    } else {
                      filteredData = filteredData.filter((row: any) => (project_id?.some((res: any) => row?.project_id?.includes(res))))
                    }
                  }
                  setSelectRows(filteredData)
                }}
                style={{ cursor: 'pointer', height: "100%" }}
                rowSelection={{
                  selectedRowKeys: selectedRows.map((res: any) => res?.key),
                  onChange: (e: any, record: any) => setSelectRows(record)
                }}
                title={() => <Row justify="space-between">
                  <Space>
                    <Text strong>{t("inventory.filterBy")} :</Text>
                    <Select style={{ width: 300 }} defaultValue={type} onChange={(e) => {
                      setSelectRows([])
                      setSearchValue("")
                      push(`/inventory/library?value=${e}`)
                    }}>
                      <Option value="characterizations">{t("common.characterizations")}</Option>
                      <Option value="processing">{t("common.processing")}</Option>
                      <Option value="properties">{t("formulations.type.properties")}</Option>
                      <Option value="metadata_properties">{t("inventory.metadata.title")}</Option>
                    </Select>
                  </Space>
                  <Space>
                    {Boolean(configs?.work_order_upload) && type === "ingredients" && (
                      <StyledButton onClick={() => setFileUploadVisible(true)} icon={<FileExcelOutlined />} type="primary">{t("common.uploadAFile")}</StyledButton>
                    )}
                    <StyledButton onClick={() => { setDataExistsCheck(false); push("/inventory/library/update") }} icon={<PlusCircleOutlined />} type="primary">{t("common.addNewData")}</StyledButton>
                    <StyledButton onClick={() => setModal(null, "delete")} icon={<StyledDeleteIcon />} disabled={!selectedRows?.length} danger type="primary">{t("common.delete")}</StyledButton>
                  </Space>
                </Row>}
                loading={{
                  spinning: updateParameterStatus === AsyncStates.LOADING ||
                    createParameterStatus === AsyncStates.LOADING ||
                    uploadTaskStatus === AsyncStates.LOADING ||
                    inventoryUploadStatus === AsyncStates.LOADING,
                  indicator: <LoadingOutlined />
                }}
                bordered
                columns={columns}
                dataSource={data}
                expandable={type === "ingredients" ? {
                  expandedRowRender: record => {
                    const ingredientProperties = record?.meta
                    return (
                      <Space direction="vertical" style={{ width: '100%', background: "white", textAlign: "center" }}>
                        {!!ingredientProperties?.length ?
                          <Table pagination={false} columns={[{
                            key: 'property',
                            title: t("common.property"),
                            dataIndex: 'identifier',
                            render: (text: any, record: any) => {
                              return inventoryDataList?.metadata_properties?.find((res: any) => res?.identifier === record?.identifier)?.name
                            }
                          }, {
                            key: 'category',
                            title: t('common.category'),
                            dataIndex: 'category',
                            render: (text: any, record: any) => {
                              return inventoryDataList?.metadata_properties?.find((res: any) => res?.identifier === record?.identifier)?.category
                            }
                          }, {
                            key: 'unit',
                            title: t("common.unit"),
                            dataIndex: 'unit'
                          },
                          {
                            key: 'min_val',
                            title: t("common.minimum") + " " + t("formulations.placeholder.value"),
                            dataIndex: 'min_val'
                          },
                          {
                            key: 'value',
                            title: `${t("formulations.placeholder.maximum")} ${t("formulations.placeholder.value")}`,
                            dataIndex: 'value'
                          },
                          ]} dataSource={ingredientProperties}
                          />
                          : <Text strong>{`${t("inventory.noPropertiesAddedfor")} ${record?.name || ""}`}</Text>}
                      </Space>
                    )
                  },
                } : {}}
              />
            </Space>
          </StyledCard>
        </Spin>
      </Space>
      <DeleteModal visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        type={type}
        selectedRows={selectedRows}
        dataExistsCheck={dataExistsCheck}
      />
      <WorkOrdersModal visible={workOrdersVisisble}
        resetData={resetData}
      />
      <AttachmentsModal
        visible={attachmentsModalVisible}
        setVisible={setAttachmentsModalVisible}
      />
      <AddUnitModal
        refetch={() => dispatch(unitListRequest({ "category": "" }))}
        isAddNewUnitModal={showCard}
        closeModal={() => setShowCard(false)}
      />
      <FileUploadModal fileUploadVisible={fileUploadVisible} setFileUploadVisible={setFileUploadVisible} />
    </motion.div>
  )
}


import {
  Col,
  Divider,
  Dropdown,
  Form,
  List,
  Menu,
  Popover,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  // ContainerOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  FileExcelOutlined,
  // FileExcelOutlined,
  FilterOutlined,
  HistoryOutlined,
  LineChartOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import useTranslate from "src/utils/useTranslate";
import { IngredientModal } from "./components/IngredientModal";
import { StyledButton } from "src/styled_components/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getIngredientGradesRequest,
  getIngredientPropertiesClear,
  getIngredientPropertiesRequest,
  getIngredientsMetaRequest,
  getIngredientsRequest,
  // getIngredientsSearchRequest,
  getSuppliersRequest,
  inventoryPreferencesRequest,
  saveInventoryFiltersClear,
  saveInventoryFiltersRequest,
  updateInventoryPreferencesRequest,
} from "src/store/actions/inventoryV2";
import { SupplierModal } from "./components/SupplierModal";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { AsyncStates } from "src/constants";
import { DeleteModal } from "src/components/Inventory/modals/DeleteModal";
import Filters from "./filters";
import { Ingredient } from "./types";
import { Link, useHistory } from "react-router-dom";
import { history } from "src";
import { FileUploadModal } from "src/components/Inventory/modals/FileUploadModal";
import ManageFieldsModal from "./components/ManageFieldsModal";
import dayjs from "dayjs";
import Search, { SearchProps } from "antd/es/input/Search";
import { useValue } from "src/utils/useValue";
import { FiltersHistoryDrawer } from "src/components/Formulation/SavedFilters";
import { NewFilterForm } from "src/components/Formulation/SavedFilters/NewFilterForm";
import { GetSavedFiltersResponse } from "src/store/sagas/saveFormulationsFilters";
import { AddUnitModal } from "src/components/UnitsConversion/modals";
import { unitListRequest } from "src/store/actions/conversion";
import InventoryIngredientPropertyDetail from "./components/InventoryIngredientPropertyDetail";
import "./InventoryV2.scss";
import { fetchAdditiveRequest, fetchCategoryRequest, fetchFieldRequest, fetchParametersRequest, fetchPropertyRequest } from "src/store/actions/repository";
import { getPropertyMethodsRequest } from "src/store/actions/inventoryV2";
import { CATEGORY_TYPES } from "./Repository/Common/Data";
import mixpanel from "mixpanel-browser";
import * as fuzzySearch from '@m31coding/fuzzy-search';

const config = fuzzySearch.Config.createDefaultConfig();
config.normalizerConfig.allowCharacter = (_c) => true;

const searcher = fuzzySearch.SearcherFactory.createDefaultSearcher(config);


const { Text, Title } = Typography;

export const InventoryV2 = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { push } = useHistory()
  const { getValue } = useValue()
  const [form] = Form.useForm();
  const tableRef = useRef<any>(null)
  const { currency } = useSelector(
    (store: StoreState) => store.login.loginResponse
  );
  const projectList = useSelector(
    (state: StoreState) => state.projects.projectList
  );
  const updateIngredientResponse = useSelector(
    (state: StoreState) => state.inventoryV2.updateIngredient
  );
  const [ingredientModalData, setIngredientModalData] = useState<any>({
    mode: "create",
    ingredient: null,
  });
  const { data: ingredientsList, status } = useSelector(
    (state: StoreState) => state.inventoryV2.getIngredients
  );
  const configs = useSelector((state: StoreState) => state.configs.features)
  const customFields = useSelector(
    (state: StoreState) => state.repository.fields.data
  );

  const [tableData, setTableData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRows, setSelectRows] = useState<any>([]);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    limit: 30,
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [addIngredientModalOpen, setAddIngredientModalOpen] = useState<boolean>(false);
  const [outerSelector, setOuterSelector] = useState<string>()
  const [innerSelector, setInnerSelector] = useState<string[]>([])
  const [formState, setFormState] = useState<any>({})
  const [filters, setFilters] = useState<any>({})
  const [fileUploadVisible, setFileUploadVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddUnitModal, setAddUnitModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [savedFiltersApplied, setSavedFiltersApplied] = useState(true);

  const [selectedIngredientIds, setSelectedIngredientIds] = useState<string[]>([]);
  const [filterHistoryDrawerVisible, setFilterHistoryDrawerVisible] =
    useState(false);
  const [createNewFilterModalVisible, setCreateNewFilterModalVisible] = useState(false);

  const getIngredientPropertyStatus = useSelector((state: StoreState) => state.inventoryV2.getIngredientPropertyStatus);

  const categories = useSelector((state: StoreState) => state.repository.categories.data);

  const methods = useSelector(
    (state: StoreState) => state.inventoryV2.propertyMethods.data
  )

  const parameters = useSelector(
    (state: StoreState) => state.repository.parameters.data
  );
  const property = useSelector(
    (state: StoreState) => state.repository.properties.data
  );

  const savedIngredientFilters = useSelector((state: StoreState) => state.inventoryV2.savedIngredientFilters);

  const ingredientPreferences = useSelector((state: StoreState) => state.inventoryV2.preferences.data.ingredient_table);

  useEffect(() => {
    return () => {
      dispatch(getIngredientPropertiesClear())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(inventoryPreferencesRequest())
  }, [dispatch])


  useEffect(() => {
    return () => {
      if (!window.location?.pathname.includes("/inventory/ingredients") && savedIngredientFilters) { // clear Previous applied filters when they visit other pages
        dispatch(saveInventoryFiltersClear())
      }
    }
  }, [savedIngredientFilters, dispatch])

  useEffect(() => {
    dispatch(fetchCategoryRequest({ type: CATEGORY_TYPES.INGREDIENT }));
    dispatch(fetchParametersRequest());
    dispatch(fetchPropertyRequest());
    dispatch(getPropertyMethodsRequest());
    dispatch(fetchFieldRequest());
    dispatch(fetchAdditiveRequest());
    dispatch(getSuppliersRequest());
    dispatch(getIngredientGradesRequest())
  }, [dispatch]);


  const applyFilters = useCallback((values: any) => {
    const payload = {
      selector: outerSelector || "and",
      data: values?.outer_conditions?.reduce(
        (array: any[], element: any, index: number) => [
          ...array,
          {
            selector: innerSelector?.[index] || element?.innerSelector || "and",
            data: element?.inner_conditions.map((innerCondition: any) => {
              if (innerCondition.parameter === "custom_fields") {
                const cField = customFields.find((cf: any) => cf.field_id === innerCondition.parameter_type);
                if (cField?.field_type === 'date') {
                  return {
                    ...innerCondition,
                    val: dayjs(innerCondition.val).format('YYYY-MM-DD'),
                    max: innerCondition?.max ? dayjs(innerCondition.max).format('YYYY-MM-DD') : null
                  }
                }
              }
              if (["family", "ingredient", "custom_fields", "supplier", "costing", "lot_no", "project_id", "grade"].includes(innerCondition.parameter)) {
                if (innerCondition.operator === 'in') {
                  return {
                    ...innerCondition,
                    val: innerCondition.val?.flat()
                  }
                }
                return innerCondition
              }
              if (["category", "methods", "properties", "parameter"].includes(innerCondition.parameter)) {
                if (innerCondition.operator === 'in') {
                  // Value comes from cascader
                  const flattenedValueArray = innerCondition.val?.map((innerArray: []) => innerArray[(innerArray.length - 1)]);
                  return {
                    ...innerCondition,
                    val: flattenedValueArray
                  }
                }
                if (["properties", "parameter"].includes(innerCondition.parameter)) {
                  // Value comes from cascader
                  const flattenedParameterType = innerCondition.parameter_type?.[(innerCondition.parameter_type.length - 1)];
                  return {
                    ...innerCondition,
                    parameter_type: [flattenedParameterType]
                  }
                }
                return innerCondition
              }
              return innerCondition
            }),
          },
        ],
        []
      ),
    }
    setFilters(payload)
    setPaginationState({
      page: 1,
      limit: 30
    })
    dispatch(getIngredientsRequest({
      filters: payload,
      sorts: {}
    }))
    setShowFilters(false)
    setSavedFiltersApplied(false)
    dispatch(saveInventoryFiltersRequest(payload))
  }, [customFields, dispatch, innerSelector, outerSelector])

  const generateCategoryValueForCascader = useCallback((value: string[]) => {
    return value.map((val: string) => {
      // Find category match
      const category_index = categories.findIndex(c => c.category_id === val || c.name === val);
      if (category_index !== -1) return [categories[category_index]?.category_id];
      // Find subcategory match
      const sub_cat_index = categories.findIndex(c => c.sub_category && c.sub_category.includes(val));
      if (sub_cat_index !== -1) return [categories[sub_cat_index]?.category_id, val];

      return null
    }).filter(v => v);
  }, [categories])

  const generatePropertyValueForCascader = useCallback((parameter_type: string) => {
    const selectedProperty = property.find(p => p.property_id === parameter_type || p.name === parameter_type);
    if (selectedProperty) return [selectedProperty?.category_name, selectedProperty?.property_id]

    return null;
  }, [property])

  const generateParameterValueForCascader = useCallback((parameter_type: string) => {
    const selectedParameter = parameters.find(p => p.parameter_id === parameter_type || p.parameter === parameter_type);
    if (selectedParameter) return [selectedParameter?.category_name, selectedParameter?.property_name, selectedParameter?.parameter_id]

    return null;
  }, [parameters])

  const generateMethodValueForCascader = useCallback((value: string[]) => {
    return value.map((val: string) => {
      const selectedMethod = methods.find((m: any) => m.method_name === val);
      if (selectedMethod) return [selectedMethod.category_name, selectedMethod.property_name, val]

      return null
    }).filter(v => v);
  }, [methods])

  const handleSelectFilter = useCallback((item: GetSavedFiltersResponse["result"]["data"][0]) => {
    const query = item?.query || {}
    if (Object.keys(query).length === 0) {
      form.resetFields()
    }
    if (Object.keys(query).length > 0) {
      setOuterSelector(query?.selector)
      setInnerSelector(query?.data?.map((res: any) => res?.selector ?? "and"))

      const outerConditions = query.data.map((outerCondition: any) => ({
        innerSelector: outerCondition.selector,
        inner_conditions: outerCondition.data.map((innerCondition: any) => {
          if (innerCondition.parameter === "custom_fields") {
            const cField = customFields.find((cf: any) => cf.field_id === innerCondition.parameter_type);
            if (cField?.field_type === 'date') {
              return {
                ...innerCondition,
                val: dayjs(innerCondition.val, 'YYYY-MM-DD').toDate(),
                max: innerCondition?.max ? dayjs(innerCondition.max, 'YYYY-MM-DD').toDate() : null
              }
            }
          }
          if (["family", "ingredient", "custom_fields", "supplier", "costing", "lot_no", "project_id"].includes(innerCondition.parameter)) {
            if (innerCondition.operator === 'in') {
              return {
                ...innerCondition,
                val: innerCondition.val?.map((v: any) => [v])
              }
            }
            return innerCondition
          }
          if (["category", "methods"].includes(innerCondition.parameter)) {
            // Value for cascader
            let cascaderValue: any;
            const value = innerCondition.val;
            if (innerCondition.parameter === "category") cascaderValue = generateCategoryValueForCascader(value);
            if (innerCondition.parameter === "methods") cascaderValue = generateMethodValueForCascader(value);

            return {
              ...innerCondition,
              val: cascaderValue
            }
          }
          if (["properties", "parameter"].includes(innerCondition.parameter)) {
            // Value for cascader
            let cascaderValue: any;
            const parameterType = innerCondition.parameter_type[0];
            if (innerCondition.parameter === "properties") cascaderValue = generatePropertyValueForCascader(parameterType);
            if (innerCondition.parameter === "parameter") cascaderValue = generateParameterValueForCascader(parameterType);

            return {
              ...innerCondition,
              parameter_type: cascaderValue
            }
          }
          return innerCondition
        }),
      }));
      form.setFieldValue(
        "outer_conditions", outerConditions
      )
      setFilters(query)
      setFormState({
        "outer_conditions": outerConditions
      })
      applyFilters({
        "outer_conditions": outerConditions
      })
    }

  }, [applyFilters, customFields, form, generateCategoryValueForCascader, generateMethodValueForCascader, generateParameterValueForCascader, generatePropertyValueForCascader])

  useEffect(() => {
    if (!Object.keys(savedIngredientFilters ?? {}).length) { // Makes an initial API call to fetch ingredient data when no filters have been applied or saved
      dispatch(
        getIngredientsRequest({
          filters: {},
          sorts: {}
        })
      );
    }
  }, [dispatch, savedIngredientFilters])

  // const searchOnChange = (e: any) => {
  //   setSearchValue(e.target.value);
  // };

  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    searcher.indexEntities(
      ingredientsList?.data?.map((o: any) => ({ ...o, grade: o.grade ? String(o.grade) : "", category: o.category ? String(o.category) : "" })) || [],
      (e: any) => e?.inventory_id,
      (e: any) => [e?.name, e?.grade, e?.category]
    );
    // const result = searcher.getMatches(new fuzzySearch.Query(searchValue, Infinity, 0.1));
    // console.log("🚀 ~ useEffect ~ result:", result)
    const result = ingredientsList?.data
      ?.map((o: any) => ({ ...o, grade: o.grade ? String(o.grade) : "", category: o.category ? String(o.category) : "" }))
      ?.filter((o: any) => o.name?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")?.includes(searchValue?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, ""))
        || o.grade?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")?.includes(searchValue?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, ""))
        || o.category?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")?.includes(searchValue?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")))
    // console.log("🚀 ~ useEffect ~ matches:", result)
    setSearchResults(result)
  }, [ingredientsList, searchValue])

  const searchedIngredients = useMemo(() => {
    // const searched = searchResults?.matches?.map(o => o?.entity)
    const searched = searchResults
    if (searchValue) return searched
    return ingredientsList?.data
  }, [ingredientsList?.data, searchResults, searchValue])

  const searchOnChange: SearchProps['onSearch'] = (value) => {
    setSearchValue(value)
    // value ? dispatch(getIngredientsSearchRequest({
    //   "search_term": value
    // })) : dispatch(getIngredientsRequest({
    //   filters,
    //   sorts: {}
    // }))
    setPaginationState({
      page: 1,
      limit: 30,
    })
  }

  useEffect(() => {
    if (updateIngredientResponse.status === AsyncStates.SUCCESS) {
      // searchValue ? dispatch(getIngredientsSearchRequest({
      //   "search_term": searchValue
      // })) : 
      dispatch(getIngredientsRequest({
        filters,
        sorts: {}
      }))
    }
  }, [dispatch, filters, updateIngredientResponse.status])

  useEffect(() => {
    dispatch(getIngredientsMetaRequest())
  }, [dispatch])

  // useEffect(() => {
  //   // searchValue ? dispatch(getIngredientsSearchRequest({
  //   //   "search_term": searchValue
  //   // })) : 
  //   dispatch(
  //     getIngredientsRequest({
  //       filters,
  //       sorts: {}
  //     })
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  useEffect(() => {
    if (status === AsyncStates.SUCCESS) {
      setTableData(searchedIngredients?.map((res: any, index: number) => ({
        ...res,
        key: index,
      })) || [])
    }
  }, [searchedIngredients, status])

  useEffect(() => {
    if (status === AsyncStates.SUCCESS) {
      setExpandedRowKeys([])
    }
  }, [status])

  useEffect(() => {
    if (savedFiltersApplied && savedIngredientFilters) { // Apply Previous applied filters back
      handleSelectFilter({
        query: savedIngredientFilters
      } as any)
    }
  }, [savedFiltersApplied, savedIngredientFilters, handleSelectFilter, dispatch])

  const clearFilters = () => {
    setFormState({})
    setInnerSelector([])
    setOuterSelector("")
    form.resetFields()
    setPaginationState({
      page: 1,
      limit: 30
    })
    setFilters({})
    dispatch(
      getIngredientsRequest({
        filters: {},
        sorts: {}
      })
    );
    dispatch(saveInventoryFiltersClear())
    setSavedFiltersApplied(true)
  }

  const getProjectsContent = useCallback(
    (rowValue) => {
      return (
        <div style={{ maxWidth: 1000, maxHeight: 500, overflow: "auto" }}>
          <List
            size="small"
            dataSource={projectList.filter((project: any) =>
              !!rowValue?.length
                ? rowValue?.includes(project?.project_id)
                : false
            )}
            renderItem={(item: any) => (
              <List.Item>
                <Text strong>{item?.name}</Text>
              </List.Item>
            )}
          />
        </div>
      );
    },
    [projectList]
  );

  const extractAlphaNum = (string?: string) => {
    if (!string || string === "" || string === "-") return "zzzzz";
    else return String(string).replace(/[^0-9A-Z]+/gi, "");
  }

  const columnVisibilityMap = useMemo(() => {
    return {
      category: t("common.category"),
      costing: t("inventory.costing"),
      grade: t("common.grade"),
      lot_no: t("inventory.lot_no"),
      sap_no: t("common.SAPNo"),
      project_id: t("projects.header.title"),
      responsible_person: t("common.responsiblePerson"),
      sub_category: t("inventory.subCategory"),
      supplier: t("inventory.supplier"),
      version_date: t("common.versionDate"),
    }
  }, [t])

  const calculateDynamicWidths = useCallback((tableRef, columns: any[]) => {
    if (!tableRef?.current) return {};

    const tableWidth = tableRef.current.offsetWidth;
    const prioritySum = columns.reduce((sum: number, col) => sum + (col.priority || 3), 0);
    let remainingWidth = tableWidth;

    // Assigning the widths based on priorities
    const calculatedWidths: any = {};
    columns.forEach((col: any) => {
      const priority = col.priority || 3;
      const minWidth = col.width;
      const dynamicWidth = Math.max(minWidth, (priority / prioritySum) * tableWidth);
      calculatedWidths[col.key] = dynamicWidth;
      remainingWidth -= dynamicWidth;
    });

    if (remainingWidth > 0) {
      Object.keys(calculatedWidths).forEach((key) => {
        const colIndex = columns.findIndex((col) => col.key === key);
        const priority = columns[colIndex].priority || 3;
        calculatedWidths[key] += (priority / prioritySum) * remainingWidth;
      });
    }

    return calculatedWidths;
  }, [])

  const columns: any = useMemo(() => {
    const allColumns = [
      {
        key: "name",
        title: t("common.Name"),
        dataIndex: "name",
        width: 200,
        priority: 1,
        fixed: "left",
        render: (name: string, record: Ingredient) => (
          <Link to={`/inventory/ingredients/${record.inventory_id}`}>
            <Text style={{ wordBreak: "break-word" }}>{name || "-"}</Text>
          </Link>
        ),
        sorter: (a: any, b: any) => extractAlphaNum(a.name).localeCompare(extractAlphaNum(b.name))
      },
      // {
      //   key: "family",
      //   title: t("common.family"),
      //   dataIndex: "family_id",
      //   width: 150,
      //   render: (family_id: string, record: any) => (
      //     <Text style={{ wordBreak: "break-word" }}>{families.find((fam: any) => fam.family_id === family_id)?.family_name ?? record.family ?? "-"}</Text>
      //   ),
      // },
      {
        key: "grade",
        title: t("common.grade"),
        dataIndex: "grade",
        width: 150,
        priority: 2,
        render: (gradeName: string | number) => {
          return <Text style={{ wordBreak: "break-word" }}> {gradeName === "" ? "-" : gradeName ?? "-"}</Text>
        },
        sorter: (a: any, b: any) => extractAlphaNum(a.grade).localeCompare(extractAlphaNum(b.grade))
      },
      {
        key: "category",
        title: t("common.category"),
        dataIndex: "category",
        width: 150,
        priority: 2,
        render: (text: string, record: any) => {
          return <Text style={{ wordBreak: "break-word" }}>{record?.category_name ?? text}</Text>
        },
        sorter: (a: any, b: any) => extractAlphaNum(a.category).localeCompare(extractAlphaNum(b.category))
      },
      {
        key: "sub_category",
        title: t("inventory.subCategory"),
        dataIndex: "sub_category",
        width: 150,
        priority: 2,
        render: (text: string, record: any) => (
          <Text style={{ wordBreak: "break-word" }}>
            {record?.sub_category}
          </Text>
        ),
        sorter: (a: any, b: any) => extractAlphaNum(a.sub_category).localeCompare(extractAlphaNum(b.sub_category))
      },
      // {
      //   key: "unit",
      //   title: (
      //     <div style={{ display: "flex", flexDirection: "column" }}>
      //       <span style={{ color: "#222" }}>{t("common.unit")}</span>
      //       {/* <Typography.Text
      //         style={{ cursor: "pointer", color: antdTheme.colorPrimary }}
      //       // onClick={() => setShowCard(true)}
      //       >
      //         {t("units.addUnit")}
      //       </Typography.Text> */}
      //     </div>
      //   ),
      //   dataIndex: "unit",
      //   width: 100,
      //   render: (rowValue: any, row: any, index: any) => {
      //     if (typeof rowValue === "string") return rowValue;
      //     const units = rowValue?.filter((value: any) => !!value);
      //     return units?.length > 0 ? units?.join(", ") : "-";
      //   },
      // },
      {
        key: "project_id",
        title: t("projects.header.title"),
        dataIndex: "project_id",
        width: 200,
        priority: 1,
        render: (rowValue: any, row: any, index: any) => {
          return (
            <Popover
              content={getProjectsContent(rowValue)}
              title={
                <Title level={5} type="secondary">
                  {t("projects.header.title")}
                </Title>
              }
              trigger="hover"
              placement="left"
            >
              <Text style={{ width: 200 }} ellipsis={{}}>
                {!!rowValue?.length
                  ? rowValue
                    ?.map(
                      (res: any) =>
                        projectList.find(
                          (project: any) => project?.project_id === res
                        )?.name || res
                    )
                    ?.join(", ")
                  : ""}
              </Text>
            </Popover>
          );
        },
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return !value
            ? !record?.project_id?.length
            : record?.project_id?.includes(value);
        },
      },
      {
        key: "lot_no",
        dataIndex: "lot_no",
        title: t("inventory.lot_no"),
        width: 250,
        filterSearch: true,
        priority: 1,
        onFilter: (value: any, record: any) => record.lot_no === value,
      },
      {
        key: "sap_no",
        dataIndex: "sap_no",
        title: t("common.SAPNo"),
        width: 250,
        filterSearch: true,
        priority: 1,
        onFilter: (value: any, record: any) => record?.sap_no === value,
      },
      {
        key: "supplier",
        dataIndex: "supplier",
        title: t("inventory.supplier"),
        width: 150,
        filterSearch: true,
        priority: 2,

        onFilter: (value: any, record: any) => record.supplier === value,
      },
      {
        key: "responsible_person",
        dataIndex: "responsible_person",
        title: t("common.responsiblePerson"),
        width: 150,
        filterSearch: true,
        priority: 2,

        onFilter: (value: any, record: any) => record.responsible_person === value,
      },
      {
        key: "version_date",
        dataIndex: "version_date",
        title: t("common.versionDate"),
        width: 150,
        filterSearch: true,
        priority: 2,
        onFilter: (value: any, record: any) => record.version_date === value,
      },
      {
        key: "costing",
        dataIndex: "costing",
        title: (
          <Text>
            {t("inventory.costing")}{" "}{`(In ${currency?.currency_code})`}
          </Text>
        ),
        width: 200,
        priority: 1,
        render: (costing: any, record: any) => {
          const costingValue = !!costing && typeof costing === "object"
            ? costing?.amount ? `${costing?.currency ?? "-"} ${getValue(costing?.amount)} ${costing?.quantity && costing?.unit ? `per ${getValue(costing?.quantity) ?? "-"} ${costing?.unit ?? "-"}` : ''}` : null
            : null
          return (
            <Tooltip title={costingValue}>
              <Text style={{ width: "100px" }} ellipsis={{}}>
                {costingValue}
              </Text>
            </Tooltip>
          );
        },
      },
    ];

    // below logic is make the column(actions, select ingredient, and expand) icon to stay at a static positon and rest columns to get adjusted based on their priority and width 
    const data = allColumns.filter((column) => ["name"].includes(column.dataIndex) ? true : ingredientPreferences[column.dataIndex])
    const dynamicWidths = calculateDynamicWidths(tableRef, data);



    const adjustedColumns = data.map((col) => ({
      ...col,
      width: dynamicWidths[col.dataIndex] || col.width,
    }));

    return [
      ...adjustedColumns,
      {
        key: "actions",
        title: `${t("common.actions")}`,
        dataIndex: "actions",
        width: 100,
        priority: 3,
        align: "center",
        fixed: "right",
        render: (text: any, record: any) => {
          return (
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setIngredientModalData({
                        mode: "edit",
                        ingredient: record,
                      });
                      setAddIngredientModalOpen(true);
                    }}
                    key="edit"
                  >
                    <StyledButton type="text" icon={<EditOutlined />}>
                      {t("common.edit")}
                    </StyledButton>
                  </Menu.Item>
                  {/* <Menu.Item
                  // onClick={() => setModal(record, "view")}
                  key="view"
                >
                  <StyledButton type="text" icon={<ContainerOutlined />}>
                    {t("common.viewWorkOrders")}
                  </StyledButton>
                </Menu.Item> */}

                  {/* <Menu.Item
                  // onClick={() => setModal(record, "attachments")}
                  key="attachments"
                >
                  <StyledButton type="text" icon={<FileExcelOutlined />}>
                    {t("common.attachments")}
                  </StyledButton>
                </Menu.Item> */}
                  <Menu.Item
                    onClick={() => {
                      setSelectRows([record]);
                      setDeleteModalVisible(true);
                    }}
                    key="delete"
                  >
                    <StyledButton
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      className={"project__dropdown__btn"}
                    >
                      {t("common.delete")}
                    </StyledButton>
                  </Menu.Item>
                </Menu>
              )}
            >
              {<MoreOutlined style={{ cursor: "pointer" }} />}
            </Dropdown>
          );
        },
      }]
  }, [currency?.currency_code, getProjectsContent, projectList, t, getValue, ingredientPreferences, tableRef, calculateDynamicWidths]);

  const addNewOption = (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.2rem" }}>
      <div
        style={{ padding: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          setIngredientModalData({
            mode: "create",
            ingredient: undefined,
          });
          setAddIngredientModalOpen(true);
        }}
      >
        {t("inventory.Ingredient")}
      </div>
      <div
        style={{ padding: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("common.Supplier")}
      </div>

      <div
        style={{ padding: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          alert("Coming soon");
        }}
      >
        {t("common.template")}
      </div>
    </div>
  );

  const compareHandler = () => {
    mixpanel.track("Compared ingredients");
    if (selectedIngredientIds.length > 1) {
      push(`/inventory/ingredients/compare?ids=${selectedIngredientIds.join(",")}`)
    } else {
      message.error(
        `Please select more than 1 ingredients`
      );
    }
  };

  const hideColumnsOverlay = () => {
    return (
      <Menu style={{ padding: 10, width: "max-content" }}>
        {Object.keys(columnVisibilityMap).map((key) => (
          <Menu.Item key={key}>
            <Row
              justify="space-between"
              wrap={false}
              style={{ height: "100%", alignItems: "center" }}
            >
              <Text>{columnVisibilityMap?.[key as keyof typeof columnVisibilityMap]}</Text>
              <Switch
                style={{ marginLeft: 10 }}
                checked={ingredientPreferences?.[key]}
                onChange={(e: boolean) => {
                  const updatedPreferences = {
                    ...ingredientPreferences,
                    [key]: e
                  }
                  dispatch(updateInventoryPreferencesRequest({
                    "ingredient_table": updatedPreferences
                  }))
                }
                }
              />
            </Row>
          </Menu.Item>
        ))}
        <Divider style={{ margin: "10px 0" }} />
        <Row justify="space-between">
          <StyledButton
            disabled={Object.entries(ingredientPreferences).every(([key, value]) => !value)}
            onClick={() => {
              const updatedPreferences = Object.keys(columnVisibilityMap).reduce((acc: { [key: string]: boolean }, key) => {
                acc[key] = false;
                return acc;
              }, {});
              dispatch(updateInventoryPreferencesRequest({
                "ingredient_table": updatedPreferences
              }))
            }}
            type="default"
            size="small"
          >
            {t("common.hideAll")}
          </StyledButton>
          <StyledButton
            disabled={Object.entries(ingredientPreferences).every(([key, value]) => value)}
            onClick={() => {
              const updatedPreferences = Object.keys(columnVisibilityMap).reduce((acc: { [key: string]: boolean }, key) => {
                acc[key] = true;
                return acc;
              }, {});
              dispatch(updateInventoryPreferencesRequest({
                "ingredient_table": updatedPreferences
              }))
            }
            }
            type="default"
            size="small"
          >
            {t("common.showAll")}
          </StyledButton>
        </Row>
      </Menu>
    );
  };


  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
      <Space
        direction="vertical"
        size="small"
        style={{ width: "100%", overflowX: "auto", height: "100%" }}
      >
        <StyledPageHeader
          ghost={false}
          title={`${t("common.ingredients")} ${ingredientsList?.count ? `(${searchedIngredients?.length})` : ''}`}
          extra={
            <>
              {(Boolean(configs?.work_order_upload) || true) && (
                <StyledButton size="small" onClick={() => setFileUploadVisible(true)} icon={<FileExcelOutlined />}>{t("common.uploadAFile")}</StyledButton>
              )}
              <StyledButton
                size="small"
                onClick={() => {
                  setAddUnitModal(true);
                }}
                type="primary"
                icon={<PlusOutlined />}
              >
                {t("units.addUnit")}
              </StyledButton>

              <Popover arrow={false} content={addNewOption} title={null} trigger="hover" placement="bottom">
                <StyledButton size="small" type="primary">{t("common.Add New")} <DownOutlined /></StyledButton>
              </Popover>

              {/* <StyledButton
                size="small"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                type="dashed"
                icon={<EditOutlined />}
              >
                Custom Fields
              </StyledButton> */}
            </>
          }
        />
        <StyledCard bodyStyle={{ padding: "8px 16px", height: "100%" }}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Table
              ref={tableRef}
              rowSelection={{
                type: "checkbox",
                onChange: (selectedRows: any[]) => {
                  setSelectedIngredientIds(selectedRows);
                },
              }}
              title={() => (
                <Col>
                  <Row justify="space-between">
                    <Space>
                      {!showFilters && !searchValue && (
                        <StyledButton
                          size="small"
                          icon={<FilterOutlined />}
                          style={{ borderRadius: 0 }}
                          onClick={() => {
                            setShowFilters(true);
                          }}
                        >
                          {t("common.addFilters")}
                        </StyledButton>)}
                      {Object.keys(filters).length > 0 ? (
                        <>
                          <StyledButton
                            type="primary"
                            style={{ borderRadius: 0 }}
                            size="small"
                            icon={<FilterOutlined />}
                            onClick={() => setCreateNewFilterModalVisible(true)}
                          >
                            {t("common.saveFilter")}
                          </StyledButton>
                          <StyledButton
                            style={{ borderRadius: 0 }}
                            size="small"
                            icon={<FilterOutlined />}
                            onClick={clearFilters}
                          >
                            {t("common.clearFilter")}
                          </StyledButton>
                        </>
                      ) :
                        // <Input size="small" value={searchValue} placeholder={t("common.search")} allowClear onChange={searchOnChange}
                        // onPressEnter={ingredientSearchRequest} onBlur={ingredientSearchRequest}
                        // />
                        null
                      }
                      <Tooltip
                        title={
                          selectedIngredientIds.length === 0
                            ? "Please select one or more ingredients to start comparing."
                            : null
                        }
                        trigger={"hover"}
                      >
                        <StyledButton
                          type="primary"
                          size="small"
                          onClick={compareHandler}
                          icon={<LineChartOutlined />}
                          disabled={selectedIngredientIds.length === 0}
                        >
                          {t("common.compare")}
                        </StyledButton>
                      </Tooltip>
                    </Space>
                    <Space >
                      <Popover
                        placement="left"
                        content={hideColumnsOverlay}
                        trigger="click"
                      >
                        <StyledButton
                          size="small"
                          style={!!Object.values(ingredientPreferences || {}).filter((res: any) => !res).length ? {
                            backgroundColor: "#E6F4FF"
                          } : {}
                          }
                          className="animated__hover__btn bordered" icon={<EyeInvisibleOutlined />} type="text">
                          <Text strong >
                            <span className="animated__hover__content">
                              {`${Object.values(ingredientPreferences || {}).filter((res: any) => !res).length || ""} ${t("common.hiddenColumns")}`}
                            </span>
                          </Text>
                        </StyledButton>
                      </Popover>
                      <Search size="small" placeholder="Search" onSearch={searchOnChange} allowClear />
                      <Tooltip title={t("common.filtersHistory")} >
                        <StyledButton
                          size="small"
                          ghost
                          type="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilterHistoryDrawerVisible(true);
                          }}
                          icon={<HistoryOutlined />}
                        />
                      </Tooltip>
                      {/* <Input.Search
                        style={{ width: 300 }}
                        placeholder={t("common.search")}
                        onChange={(e: any) => setSearchValue(e.target.value)}
                        allowClear
                        value={searchValue}
                        enterButton
                      /> */}
                    </Space>
                  </Row>
                  {showFilters && (
                    <Filters
                      visible={showFilters}
                      setVisible={setShowFilters}
                      form={form}
                      applyFilters={applyFilters}
                      clearFilters={clearFilters}
                      formState={formState}
                      setFormState={setFormState}
                      outerSelector={outerSelector}
                      setOuterSelector={setOuterSelector}
                      innerSelector={innerSelector}
                      setInnerSelector={setInnerSelector}
                    />
                  )}
                  <FiltersHistoryDrawer
                    visible={filterHistoryDrawerVisible}
                    setFilters={setFilters}
                    setVisible={setFilterHistoryDrawerVisible}
                    form={form}
                    setInnerSelector={setInnerSelector}
                    setOuterSelector={setOuterSelector}
                    setFormState={setFormState}
                    filter_type="inventory"
                    onApplyFilter={handleSelectFilter}
                  />
                  <NewFilterForm
                    visible={createNewFilterModalVisible}
                    setVisible={setCreateNewFilterModalVisible}
                    filters={filters}
                    filter_type="inventory"
                  />
                </Col>
              )}
              style={{ cursor: "pointer", height: "100%" }}
              bordered
              columns={columns}
              loading={status === AsyncStates.LOADING}
              dataSource={[...tableData]}
              rowKey={(record: any) => record?.inventory_id}
              className="inventory-v2-custom-table"
              // scroll={{ x: 1000 }}
              expandable={
                {
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => {
                    if (!!record?.inventory_id && expanded) {
                      dispatch(getIngredientPropertiesRequest({ inventory_id: record?.inventory_id }))
                    }
                    if (expanded) {
                      setExpandedRowKeys((prevKeys) => {
                        if (prevKeys.includes(record?.inventory_id)) return prevKeys
                        return [...prevKeys, record?.inventory_id]
                      })
                    } else {
                      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record?.inventory_id))
                    }
                  },
                  expandedRowRender: (record) => {
                    return (
                      <>
                        {getIngredientPropertyStatus?.[record?.inventory_id] === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                          <Text type="danger" strong>{t("common.errorFetchingProperties")}</Text>
                          <StyledButton
                            onClick={() => {
                              dispatch(getIngredientPropertiesRequest({ inventory_id: record?.inventory_id }))
                            }}>
                            {t("common.retry")}
                          </StyledButton>
                        </div>}
                        <Spin spinning={getIngredientPropertyStatus?.[record?.inventory_id] === AsyncStates.LOADING}
                          indicator={<LoadingOutlined />}
                        >
                          <div
                            style={{
                              padding: "0.5rem 0rem",
                              marginLeft: "1rem",
                              overflowX: "auto",
                            }}
                          >
                            <InventoryIngredientPropertyDetail propertiesByCategory={record?.properties} inventory_id={record?.inventory_id} />
                          </div>
                        </Spin>
                      </>
                    );
                  },
                }
              }
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/inventory/ingredients/${record.inventory_id}`)
                  },
                };
              }}
              pagination={{
                current: paginationState.page,
                position: ["bottomRight"],
                pageSize: paginationState.limit,
                pageSizeOptions: [30, 50, 70, 100],
                total: searchResults?.length,
                onChange: (current, size) =>
                  setPaginationState({
                    page: paginationState.limit === size ? current : 1,
                    limit: size,
                  }),
              }}
              locale={{
                triggerDesc: t("table.sort.ZA"),
                triggerAsc: t("table.sort.AZ"),
                cancelSort: t("table.sort.cancel")
              }}
            />
          </Space>
        </StyledCard>
      </Space >
      <IngredientModal
        open={addIngredientModalOpen}
        setOpen={setAddIngredientModalOpen}
        mode={ingredientModalData.mode}
        ingredient={ingredientModalData.ingredient}
      />
      {
        deleteModalVisible && <DeleteModal
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          type={"ingredients"}
          selectedRows={selectedRows}
          deleteIngredientCallback={() => {
            dispatch(getIngredientsRequest({
              filters: savedIngredientFilters ? savedIngredientFilters : filters,
              sorts: {}
            }))
          }}
        />
      }
      <SupplierModal open={open} setOpen={setOpen} />
      <FileUploadModal fileUploadVisible={fileUploadVisible} setFileUploadVisible={setFileUploadVisible} />
      {isModalOpen && <ManageFieldsModal modalState={[isModalOpen, setIsModalOpen]} />}
      <AddUnitModal
        refetch={() => dispatch(unitListRequest({ "category": "" }))}
        isAddNewUnitModal={showAddUnitModal}
        closeModal={() => setAddUnitModal(false)}
      />
    </motion.div >
  );
};

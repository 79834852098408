import { Table } from "antd"
import React, { FC } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"

const BboxTable: FC<any> = () => {
  const [t] = useTranslate();
  const { instanceDetection } = useSelector(
    (state: StoreState) => state.semAnalysis,
  )

  const dataWithKeys: any = () =>
    Object.keys(instanceDetection?.mapping || {})?.map((item: any, index: any) => ({
      key: instanceDetection?.mapping[item]?.index,
      id: item.replace("bbox_id_", "Bounding Box "),
      bbox: JSON.stringify(instanceDetection?.mapping[item]?.bbox),
      area: <>{instanceDetection?.mapping[item]?.area} {instanceDetection?.unit && <>{instanceDetection?.unit} <sup>2</sup></>}</>,
    }))

  const columns: any = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("sem.bbox"),
      dataIndex: "bbox",
      key: "bbox",
    },
    {
      title: t("sem.area"),
      dataIndex: "area",
      key: "area",
      fixed: "right"
    },
  ]

  return (<>
    <h3>{t("sem.boundingBoxInfo")} {"(" + Object.keys(instanceDetection?.mapping || {})?.length + ")"}</h3>
    <Table dataSource={dataWithKeys()} columns={columns} />
  </>)
}

export default React.memo(BboxTable);

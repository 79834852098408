import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Dropdown, Flex, Segmented, Space, Spin } from "antd";
import { MenuProps } from "antd/lib";
import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import "./Shared/SEMAnalysis.scss";
import ResidualAnalysis from "./ResidualAnalysis/ResidualAnalysis";
import ProtrusionAnalysis from "./ProtrusionAnalysis/ProtrusionAnalysis";
import GeneralAnalysis from "./GeneralAnalysis/GeneralAnalysis";
import FlowabilityAnalysis from "./FlowabilityAnalysis/FlowabilityAnalysis";
import PaintFilmAnalysis from "./PaintFilmAnalysis/PaintFilmAnalysis";
import { useHistory, useParams } from "react-router-dom";
import NewPaintFilmAnalysisModal from "./Shared/NewAnalysisModals/NewPaintFilmAnalysisModal";
import NewContrastiveAnalysisModal from "./Shared/NewAnalysisModals/NewContrastiveAnalysisModal";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import {
  analysisTypesRequest,
  fetchSemAnalysisListClear,
  fetchSemAnalysisMaskDataClear,
} from "src/store/actions/semAnalysis";
import NewSingleImageAnalysisModal from "./Shared/NewAnalysisModals/NewSingleImageAnalysisModal";
import SeedAnalysis from "./SeedAnalysis/SeedAnalysis";
import NewFiberAnalysisModal from "./Shared/NewAnalysisModals/NewFiberAnalysisModal";
import FiberAnalysis from "./FiberAnalysis/FiberAnalysis";
import NewWhiteObjectAnalysisModal from "./Shared/NewAnalysisModals/NewWhiteObjectAnalysisModal";
import WhiteObjectAnalysis from "./WhiteObjectAnalysis/WhiteObjectAnalysis";
import useTranslate, { TranslateFunc } from "src/utils/useTranslate";

export enum IMAGE_ANALYSIS_TYPES {
  RESIDUAL_ANALYSIS = "residual-analysis",
  FLOWABILITY_ANALYSIS = "flowability-analysis",
  PROTRUSION_ANALYSIS = "protrusion-analysis",
  GENERAL_ANALYSIS = "general-analysis",
  PAINT_FILM_ANALYSIS = "paint-film-analysis",
  SEED_ANALYSIS = "seed-analysis",
  FIBER_ANALYSIS = "fiber-analysis",
  WHITE_OBJECT_ANALYSIS = "white-object-analysis",
}

export const imageAnalysisTypesTranslationMapper = (
  type: string,
  t: TranslateFunc
) => {
  type = type.toLowerCase();
  switch (type) {
    case "residual analysis":
      return t("sem.residualAnalysis");
    case "protrusion analysis":
      return t("sem.protrusionAnalysis");
    case "flowability analysis":
      return t("sem.flowabilityAnalysis");
    case "paint film analysis":
      return t("sem.paintFilmAnalysis");
    case "fiber analysis":
      return t("sem.fiberAnalysis");
    case "seed analysis":
      return t("sem.seedAnalysis");
    case "general analysis":
      return t("sem.generalAnalysis");
    case "white object analysis":
      return t("sem.whiteObjectAnalysis");
  }
};

const SEMAnalysisWrapper = () => {
  const [t] = useTranslate();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPaintFilmAnalysisModal, setShowPaintFilmAnalysisModal] =
    useState(false);
  const [showFlowabilityAnalysisModal, setShowFlowabilityAnalysisModal] =
    useState<boolean>(false);
  const [showProtrusionAnalysisModal, setShowProtrusionAnalysisModal] =
    useState<boolean>(false);
  const [showContrastiveAnalysisModal, setShowContrastiveAnalysisModal] =
    useState<boolean>(false);
  const [showGeneralAnalysisModal, setShowGeneralAnalysisModal] =
    useState<boolean>(false);
  const [showSeedAnalysisModal, setShowSeedAnalysisModal] =
    useState<boolean>(false);
  const [showFiberAnalysisModal, setShowFiberAnalysisModal] =
    useState<boolean>(false);
  const [showWhiteObjectAnalysisModal, setShowWhiteObjectAnalysisModal] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>();
  const { analysisTypes, analysisTypesStatus } = useSelector(
    (state: StoreState) => state.semAnalysis
  );

  useLayoutEffect(() => {
    if (analysisTypesStatus === AsyncStates.INITIAL)
      dispatch(analysisTypesRequest());
  }, [analysisTypesStatus, dispatch]);

  useEffect(() => {
    if (!params.analysisType && Object.keys(analysisTypes).length > 0) {
      history.push(`/sem-analysis/${Object.keys(analysisTypes)[0]}`);
    } else {
      setCurrentTab(params.analysisType);
    }
  }, [history, params, analysisTypes]);

  const dropDownOptions: MenuProps["items"] = useMemo(() => {
    return Object.keys(analysisTypes || {}).map((key) => {
      return {
        key: key,
        label: (
          <>
            {imageAnalysisTypesTranslationMapper(
              analysisTypes[key as keyof typeof analysisTypes],
              t
            )}
          </>
        ),
        onClick: (e: any) => {
          e.domEvent.stopPropagation();
          if (key === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS)
            setShowContrastiveAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS)
            setShowFlowabilityAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS)
            setShowProtrusionAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS)
            setShowGeneralAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS)
            setShowPaintFilmAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS)
            setShowSeedAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS)
            setShowFiberAnalysisModal(true);
          if (key === IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS)
            setShowWhiteObjectAnalysisModal(true);
        },
      };
    });
  }, [analysisTypes, t]);

  if (analysisTypesStatus !== AsyncStates.SUCCESS)
    return <Spin indicator={<LoadingOutlined />} spinning={true} />;

  return (
    <Fragment>
      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: 20 }}
        size="large"
      >
        <StyledPageHeader
          ghost={false}
          title={t("sem.imageAnalysis")}
          extra={
            <Space size="large">
              <Dropdown
                menu={{
                  items: dropDownOptions,
                }}
                placement="bottomRight"
                trigger={["click"]}
              >
                <StyledButton type="primary" size="middle">
                  {t("sem.newAnalysis")}
                  <DownOutlined />
                </StyledButton>
              </Dropdown>
            </Space>
          }
        />
      </Space>

      <Flex gap={12}>
        <Segmented
          value={currentTab}
          options={Object.keys(analysisTypes)
            .filter((t) => t !== IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS)
            .map((key) => ({
              label: imageAnalysisTypesTranslationMapper(
                analysisTypes[key as keyof typeof analysisTypes],
                t
              ),
              value: key,
            }))}
          onChange={(value) => {
            dispatch(fetchSemAnalysisListClear());
            dispatch(fetchSemAnalysisMaskDataClear());
            history.push(`/sem-analysis/${value}`);
          }}
        />

        {Object.keys(analysisTypes).find(
          (t) => t === IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS
        ) && (
          <Segmented
            value={currentTab}
            options={[
              {
                label: imageAnalysisTypesTranslationMapper(
                  analysisTypes[
                    IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
                  ],
                  t
                ),
                value: IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS,
              },
            ]}
            onChange={(value) => {
              dispatch(fetchSemAnalysisListClear());
              dispatch(fetchSemAnalysisMaskDataClear());
              history.push(
                `/sem-analysis/${IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS}`
              );
            }}
          />
        )}
      </Flex>

      {currentTab === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS && (
        <ResidualAnalysis />
      )}
      {currentTab === IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS && (
        <FlowabilityAnalysis />
      )}
      {currentTab === IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS && (
        <ProtrusionAnalysis />
      )}
      {currentTab === IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS && (
        <GeneralAnalysis />
      )}
      {currentTab === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS && (
        <PaintFilmAnalysis />
      )}
      {currentTab === IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS && <SeedAnalysis />}
      {currentTab === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS && <FiberAnalysis />}
      {currentTab === IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS && (
        <WhiteObjectAnalysis />
      )}

      {showPaintFilmAnalysisModal && (
        <NewPaintFilmAnalysisModal
          showPaintFilmAnalysisModal={showPaintFilmAnalysisModal}
          setShowPaintFilmAnalysisModal={setShowPaintFilmAnalysisModal}
        />
      )}
      {showContrastiveAnalysisModal && (
        <NewContrastiveAnalysisModal
          newContrastiveAnalysisModal={showContrastiveAnalysisModal}
          setNewContrastiveAnalysisModal={setShowContrastiveAnalysisModal}
        />
      )}
      {showFlowabilityAnalysisModal && (
        <NewSingleImageAnalysisModal
          showSingleImageAnalysisModal={showFlowabilityAnalysisModal}
          setShowSingleImageAnalysisModal={setShowFlowabilityAnalysisModal}
          analysisType={
            analysisTypes[
              IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes
            ]
          }
        />
      )}
      {showProtrusionAnalysisModal && (
        <NewSingleImageAnalysisModal
          showSingleImageAnalysisModal={showProtrusionAnalysisModal}
          setShowSingleImageAnalysisModal={setShowProtrusionAnalysisModal}
          analysisType={
            analysisTypes[
              IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes
            ]
          }
        />
      )}
      {showGeneralAnalysisModal && (
        <NewSingleImageAnalysisModal
          showSingleImageAnalysisModal={showGeneralAnalysisModal}
          setShowSingleImageAnalysisModal={setShowGeneralAnalysisModal}
          analysisType={
            analysisTypes[
              IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
            ]
          }
        />
      )}
      {showSeedAnalysisModal && (
        <NewSingleImageAnalysisModal
          showSingleImageAnalysisModal={showSeedAnalysisModal}
          setShowSingleImageAnalysisModal={setShowSeedAnalysisModal}
          analysisType={
            analysisTypes[
              IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS as keyof typeof analysisTypes
            ]
          }
        />
      )}
      {showFiberAnalysisModal && (
        <NewFiberAnalysisModal
          showFiberAnalysisModal={showFiberAnalysisModal}
          setShowFiberAnalysisModal={setShowFiberAnalysisModal}
        />
      )}
      {showWhiteObjectAnalysisModal && (
        <NewWhiteObjectAnalysisModal
          showWhiteObjectAnalysisModal={showWhiteObjectAnalysisModal}
          setShowWhiteObjectAnalysisModal={setShowWhiteObjectAnalysisModal}
        />
      )}
    </Fragment>
  );
};

export default SEMAnalysisWrapper;

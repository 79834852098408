import { DeleteOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Checkbox, Flex } from "antd";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    count: number;
    selectedIds: string[];
    showDownloadReport?: boolean;
    showDelete?: boolean;
    showSelectAll?: boolean;
    onDelete?: () => void;
    onDownloadReport?: () => void;
    onSelectAll?: () => void
    downloadReportStatus?: AsyncStates
    deleteStatus?: AsyncStates,
    showDownloadImages?: boolean,
    onDownloadImages?: () => void,
    downloadImageStatus?: AsyncStates
}

const SelectionsActions = ({ count, selectedIds, showDelete = true, showSelectAll = true, showDownloadReport = true, onDelete, onDownloadReport, onSelectAll, downloadReportStatus, deleteStatus, showDownloadImages = false, onDownloadImages, downloadImageStatus }: TProps) => {
    const [t] = useTranslate();
    if (!count || count === 0) return null;

    return (
        <Flex justify="space-between" style={{ marginTop: "20px" }}>
            <div>
                {showSelectAll && (
                    <><Checkbox checked={count === selectedIds.length} onClick={() => onSelectAll && onSelectAll()} />&nbsp;&nbsp;{t("common.selectAll")}</>
                )}
            </div>
            <Flex justify="flex-end" gap={8}>
                {showDownloadImages && <StyledButton
                    type="primary"
                    onClick={() => onDownloadImages && onDownloadImages()}
                    style={{ marginRight: 10 }}
                    icon={<VerticalAlignBottomOutlined />}
                    loading={downloadImageStatus ? downloadImageStatus === AsyncStates.LOADING : false}
                    disabled={selectedIds.length === 0}
                >
                    {t("sem.downloadSegmentedImages")}
                </StyledButton>}
                {showDownloadReport && <StyledButton
                    type="primary"
                    onClick={() => onDownloadReport && onDownloadReport()}
                    style={{ marginRight: 10 }}
                    icon={<VerticalAlignBottomOutlined />}
                    loading={downloadReportStatus ? downloadReportStatus === AsyncStates.LOADING : false}
                    disabled={selectedIds.length === 0}
                >
                    {t("report.downloadReport")}
                </StyledButton>}
                {showDelete && <StyledButton
                    danger
                    type="default"
                    onClick={() => onDelete && onDelete()}
                    style={{ marginRight: 10 }}
                    icon={<DeleteOutlined />}
                    loading={deleteStatus ? deleteStatus === AsyncStates.LOADING : false}
                    disabled={selectedIds.length === 0}
                >
                    {t("common.delete")}
                </StyledButton>}
            </Flex>
        </Flex>
    )
}

export default SelectionsActions;
import { Col, Flex, Form, Input, Modal, Row, Select } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import ImageUpload from "../ImageUpload";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  processWhiteObjectsClear,
  processWhiteObjectsRequest,
} from "src/store/actions/semAnalysis";
import { AsyncStates } from "src/constants";
import { SCALE_UNITS } from "../ScaleUnits";
import useTranslate from "src/utils/useTranslate";

const NewWhiteObjectAnalysisModal = ({
  showWhiteObjectAnalysisModal,
  setShowWhiteObjectAnalysisModal,
}: any) => {
  const [t] = useTranslate();
  const [image, setImage] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [scaleForm] = Form.useForm();
  const dispatch = useDispatch();
  const { processWhiteObjectsStatus } = useSelector(
    (state: any) => state.semAnalysis
  );

  useEffect(() => {
    if (processWhiteObjectsStatus === AsyncStates.SUCCESS) {
      setShowWhiteObjectAnalysisModal(false);
      dispatch(processWhiteObjectsClear());
    }
  }, [dispatch, processWhiteObjectsStatus, setShowWhiteObjectAnalysisModal]);

  useEffect(() => {
    if (file) {
      const reader: any = new FileReader();
      reader.onload = (event: any) => {
        setImage(event?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleFormSubmit = useCallback(() => {
    if (!file) return;

    scaleForm
      .validateFields({ validateOnly: false })
      .then(() => {
        if (file) {
          const formData = new FormData();

          formData.append("image_file", file);
          formData.append(
            "scale",
            `${scaleForm.getFieldValue("scale")} ${scaleForm.getFieldValue(
              "unit"
            )}`
          );

          dispatch(processWhiteObjectsRequest(formData));
        }
      })
      .catch((err) => console.error(err));
  }, [dispatch, file, scaleForm]);

  return (
    <Modal
      title={<h3>{t("common.New")} {t("sem.whiteObjectAnalysis")}</h3>}
      open={showWhiteObjectAnalysisModal}
      onCancel={() => setShowWhiteObjectAnalysisModal(false)}
      footer={
        image && (
          <StyledButton
            type="primary"
            size="middle"
            onClick={() => handleFormSubmit()}
            loading={processWhiteObjectsStatus === AsyncStates.LOADING}
          >
            {t("sem.runAnalysis")}
          </StyledButton>
        )
      }
    >
      <Flex vertical gap={20}>
        <div style={{ flex: 1 }}>
          {!image ? (
            <ImageUpload setFile={setFile} />
          ) : (
            <img src={image} style={{ maxWidth: "100%" }} alt="" />
          )}
        </div>
        <Form form={scaleForm}>
          <Row gutter={[8, 8]}>
            <Col>
              <Form.Item
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      value >= 0
                        ? Promise.resolve()
                        : Promise.reject(
                            `${t("sem.valueMustBeGreaterThanEqualTo")} 0`
                          ),
                  },
                ]}
                name="scale"
              >
                <Input type="number" placeholder={t("sem.scale")} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item rules={[{ required: true }]} name="unit">
                <Select
                  placeholder={t("common.selectUnit")}
                  options={SCALE_UNITS.map((unit) => ({
                    label: unit,
                    value: unit,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Flex>
    </Modal>
  );
};

export default NewWhiteObjectAnalysisModal;

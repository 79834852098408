import React, { useCallback, useEffect, useMemo } from "react";
import {
  Form,
  Radio,
  Select,
  Space,
  Table,
  Input, Popconfirm,
} from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import "mathlive";
import "./CustomML.scss";
import { MathfieldElement } from "mathlive";
import { StyledInput } from "src/styled_components/StyledInput";
import { useParams } from "react-router-dom";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>;
    }
  }
}

interface Item {
  key: string;
  variable: string;
  type: number;
  operator: string;
  value: string;
  valueType: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editable: boolean;
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'equation' | 'text';
  record: Item;
  index: number;
  data: any[];
  varsData: any[];
  update: any;
  runid: any;
}

const VariablesEditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  record,
  index,
  dataIndex,
  children,
  editable,
  title,
  update,
  data,
  runid,
  ...restProps
}) => {

  const onValueChange = useCallback((value: string) => {
    const newData = data.map((variable: any, _index: number) => index === _index ? { ...variable, value } : variable)
    update(newData)
  }, [data, index, update])

  const onVariableChange = useCallback((e: any) => {
    const newData = data.map((variable: any, _index: number) => index === _index ? { ...variable, variable: e.target.value } : variable)
    update(newData)
  }, [data, index, update])

  const onTypeChange = useCallback((type: string) => {
    const newData = data.map((variable: any, _index: number) => index === _index ? { ...variable, type } : variable)
    update(newData)
  }, [data, index, update])

  useEffect(() => {

  }, [])

  const { filterData } = useSelector((state: StoreState) => state.CustomML)

  const ingrDN = useSelector((state: StoreState) => state.displayNames.data.ingredients || {})
  const procDN = useSelector((state: StoreState) => state.displayNames.data.processing || {})
  const propertiesDN = useSelector((state: StoreState) => state.displayNames.data.properties || {})


  const ingrOpts = useMemo(() => {
    const ingrs: string[] = Array.from(new Set(filterData?.dataframe?.flatMap((trial: any) => Object.keys(trial.ingredients))))
    return ingrs.map((ingr: string) => ({ value: ingr, label: ingrDN[ingr]?.name ?? ingr }))
  }, [filterData?.dataframe, ingrDN])
  const procOpts = useMemo(() => {
    const property: string[] = Array.from(new Set(filterData?.dataframe?.flatMap((trial: any) => Object.keys(trial.processing?.[0]?.processing))))
    return property.map((property: string) => ({ value: property, label: procDN[property]?.name ?? property }))
  }, [filterData?.dataframe, procDN])
  const propertyOpts = useMemo(() => {
    const property: string[] = Array.from(new Set(filterData?.dataframe?.flatMap((trial: any) => Object.keys(trial.properties?.[0]?.properties))))
    return property.map((property: string) => ({ value: property, label: propertiesDN[property]?.name ?? property }))
  }, [filterData, propertiesDN])

  const field = useMemo(() => {
    if (!editable) {
      return children
    }

    let field
    switch (dataIndex) {
      case "variable":
        field = (runid === "new") ? (
          <math-field
            onInput={onVariableChange}
          >
            {record[dataIndex]}
          </math-field>
        ) : (
          <math-field
            read-only
            onInput={onVariableChange}
          >
            {record[dataIndex]}
          </math-field>
        )
        break

      case "valueType":
        field = (
          <Radio.Group onChange={undefined} defaultValue={"constant"}>
            <Space>
              <Radio value={"variable"}>Variable</Radio>
              <Radio value={"constant"}>Constant</Radio>
            </Space>
          </Radio.Group>
        )
        break

      case "operator":
        field = (
          <Select
            defaultValue="="
            style={{ width: 80 }}
            onChange={undefined}
            options={[
              { value: '=', label: '=' },
              { value: '<=', label: '<=' },
              { value: '>=', label: '>=' },
              { value: '<', label: '<' },
              { value: '>', label: '>' },
              { value: 'range', label: 'Range' },
            ]}
          />
        )
        break

      case "type":
        field = (
          <Select
            defaultValue={data[index].type}
            style={{ width: 110 }}
            onChange={onTypeChange}
            options={[
              { value: 'unknown', label: 'Unknown' },
              { value: 'property', label: 'Property' },
              { value: 'ingredient', label: 'Ingredient' },
              { value: 'processing', label: 'Processing' },
              { value: 'constant', label: 'Constant' },
            ]}
            value={data[index].type}
          />
        )
        break

      case "value":
        if (data[index].type === "unknown") {
          field = "-"
        } else if (data[index].type === "constant") {
          field = <StyledInput defaultValue={data[index].value} value={data[index].value} onChange={e => { onValueChange(e.target.value) }} />
        } else if (data[index].type === "ingredient") {
          field = (
            <Select
              defaultValue={data[index].value}
              value={data[index].value}
              style={{ width: 110 }}
              onChange={onValueChange}
              options={ingrOpts}
            />
          )
        } else if (data[index].type === "processing") {
          field = (
            <Select
              defaultValue={data[index].value}
              value={data[index].value}
              style={{ width: 110 }}
              onChange={onValueChange}
              options={procOpts}
            />
          )
        } else if (data[index].type === "property") {
          field = (
            <Select
              defaultValue={data[index].value}
              value={data[index].value}
              style={{ width: 110 }}
              onChange={onValueChange}
              options={propertyOpts}
            />
          )
        }
        break

      default:
        field = <Input defaultValue={(record as any)[dataIndex]} />
    }

    return (
      <Form.Item
        name={dataIndex + record.key}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: `Please Input ${title}!`,
          },
        ]}
      >
        {field}
      </Form.Item>
    )
  }, [children, data, dataIndex, editable, index, ingrOpts, onTypeChange, onValueChange, onVariableChange, procOpts, propertyOpts, record, runid, title])

  return (
    <td {...restProps}>
      {field}
    </td>
  );
};

export const Variables = ({ data, add, remove: _remove, update, autoDetect, disableAutoDetect }: any) => {
  const { runid } = useParams<{ runid: string }>()

  const [form] = Form.useForm();

  const remove = (key: string) => {
    _remove(key)
  };

  const defaultColumns = [
    {
      title: 'Variable',
      dataIndex: 'variable',
      width: '25%',
      editable: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '15%',
      editable: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '40%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: Item) => {
        return (
          <Popconfirm title="Sure to remove?" onConfirm={() => remove(record.key)}>
            <StyledButton style={{ border: "none", padding: 0 }} type="link">Remove</StyledButton>
          </Popconfirm>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item, index: number | undefined) => ({
        record,
        index,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        update,
        data,
        runid
      }),
    };
  });

  return (
    <Form form={form} component={false} disabled={!!runid && runid !== "new"}>
      <Table
        components={{
          body: {
            cell: VariablesEditableCell,
          },
        }}
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        footer={() => <Space><StyledButton disabled={!!runid && runid !== "new"} size="small" onClick={add}>+ Add</StyledButton><StyledButton disabled={(!!runid && runid !== "new") || disableAutoDetect} size="small" onClick={autoDetect}>Auto-detect</StyledButton></Space>}
        size="small"
        style={{ marginTop: 16 }}
      />
    </Form>
  );
};


import { Card, Col, Flex, Form, Input, Modal, Row, Select, Steps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setImageSubtractionRequest } from "src/store/actions/semAnalysis";
import { StyledButton } from "src/styled_components/StyledButton";
import ImageUpload from "../ImageUpload";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { SCALE_UNITS } from "../ScaleUnits";
import { CloseOutlined } from "@ant-design/icons";
import { fileToBase64 } from "src/utils/general/files";
import useTranslate from "src/utils/useTranslate";

const NewContrastiveAnalysisModal = ({
  newContrastiveAnalysisModal,
  setNewContrastiveAnalysisModal,
}: any) => {
  const [t] = useTranslate();
  const setImageSubtractionStatus = useSelector(
    (state: StoreState) => state.semAnalysis.setImageSubtractionStatus
  );
  const [files, setFiles] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [beforeFile, setBeforeFile] = useState<any>();
  const [beforeImage, setBeforeImage] = useState<any>();
  const [currentStep, setCurrentStep] = useState(0);
  const [scaleForm] = Form.useForm();
  const [batchForm] = Form.useForm();
  const [type, setType] = useState<"PCB_A" | "PCB_B">();
  const dispatch = useDispatch();

  useEffect(() => {
    if (setImageSubtractionStatus === AsyncStates.SUCCESS) {
      setFiles([]);
      setBeforeFile(undefined);
      scaleForm.resetFields();
      batchForm.resetFields();
      setCurrentStep(0);
      setNewContrastiveAnalysisModal(false);
    }
  }, [
    batchForm,
    dispatch,
    setImageSubtractionStatus,
    scaleForm,
    setNewContrastiveAnalysisModal,
  ]);

  useEffect(() => {
    if (!beforeFile) setBeforeImage(undefined);
    else {
      const reader: any = new FileReader();
      reader.onload = (event: any) => {
        setBeforeImage(event?.target?.result);
      };
      reader.readAsDataURL(beforeFile);
    }
  }, [beforeFile]);

  useEffect(() => {
    if (!files || files.length === 0) setImages([]);
    else {
      (async () => {
        const fileReaderPromises = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          fileReaderPromises.push(fileToBase64(file));
        }
        try {
          const rawImageData: any = await Promise.all(fileReaderPromises);
          setImages(rawImageData);
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [files]);

  const steps = [
    {
      title: t("common.select"),
    },
    {
      title: t("sem.before"),
    },
    {
      title: t("sem.after"),
    },
  ];

  const handleSetFile = useCallback((file: any, type: any) => {
    if (type === "before") setBeforeFile(file);
    if (type === "batch") setFiles((prev) => [...prev, file]);
  }, []);

  const handleImageSubstraction = useCallback(() => {
    batchForm
      .validateFields({ validateOnly: false })
      .then(() => {
        if (files && files.length > 0) {
          const formData = new FormData();

          files.forEach((file) => {
            formData.append("analyse_images", file);
          });

          formData.append("batch_name", batchForm.getFieldValue("batch_name"));
          if (
            scaleForm.getFieldValue("scale") &&
            scaleForm.getFieldValue("unit")
          )
            formData.append(
              "scale",
              `${scaleForm.getFieldValue("scale")} ${scaleForm.getFieldValue(
                "unit"
              )}`.trim()
            );
          formData.append("master_B", beforeFile);
          formData.append("pcb_type", type!);

          dispatch(setImageSubtractionRequest(formData));
        }
      })
      .catch((err) => console.error(err));
  }, [batchForm, dispatch, files, beforeFile, scaleForm, type]);

  return (
    <Modal
      width={600}
      title={
        <Flex gap={10} justify="space-between">
          <h3>{t("common.New")} {t("sem.residualAnalysis")}</h3>
          <Steps
            current={currentStep}
            items={steps}
            size="small"
            style={{ marginRight: 20, width: 250 }}
          />
        </Flex>
      }
      open={newContrastiveAnalysisModal}
      onCancel={() => setNewContrastiveAnalysisModal(false)}
      rootClassName="contrastive-analysis-modal"
      footer={
        <>
          {currentStep === 0 && (
            <StyledButton
              type="primary"
              size="middle"
              onClick={() => type && setCurrentStep(1)}
              loading={setImageSubtractionStatus === AsyncStates.LOADING}
            >
              {t("common.continue")}
            </StyledButton>
          )}
          {currentStep === 1 && (
            <StyledButton
              type="primary"
              size="middle"
              onClick={() =>
                scaleForm
                  .validateFields({ validateOnly: false })
                  .then(() => beforeImage && setCurrentStep(2))
                  .catch((err) => console.error(err))
              }
              loading={setImageSubtractionStatus === AsyncStates.LOADING}
            >
              {t("common.continue")}
            </StyledButton>
          )}
          {currentStep === 2 && files && files.length > 0 && (
            <StyledButton
              type="primary"
              size="middle"
              onClick={handleImageSubstraction}
              loading={setImageSubtractionStatus === AsyncStates.LOADING}
            >
              {t("sem.runAnalysis")}
            </StyledButton>
          )}
        </>
      }
    >
      <>
        {currentStep === 0 && (
          <Flex
            justify="space-around"
            align="center"
            style={{ padding: "24px 0" }}
          >
            <Card
              style={{ width: 150, cursor: "pointer" }}
              className={type === "PCB_A" ? "selected-border" : ""}
              onClick={() => setType("PCB_A")}
            >
              <div style={{ textAlign: "center" }}>PCB_A</div>
            </Card>
            <Card
              style={{ width: 150, cursor: "pointer" }}
              className={type === "PCB_B" ? "selected-border" : ""}
              onClick={() => setType("PCB_B")}
            >
              <div style={{ textAlign: "center" }}>PCB_B</div>
            </Card>
          </Flex>
        )}
        {currentStep === 1 && (
          <>
            <p style={{ marginBottom: 15 }}>{t("sem.uploadBeforeImageAndScale")}</p>
            <Flex gap={10} justify="space-between" vertical>
              {beforeImage ? (
                <div className="image-preview-container">
                  <div className="image-preview">
                    <img src={beforeImage} alt="Scale" />
                    <span
                      className="close"
                      onClick={() => setBeforeFile(undefined)}
                    >
                      <CloseOutlined />
                    </span>
                  </div>
                </div>
              ) : (
                <ImageUpload
                  setFile={(file: any) => handleSetFile(file, "before")}
                  title={`${t("sem.beforeImage")}`}
                  className="paint-film-image-upload"
                />
              )}
              <Form form={scaleForm}>
                <Row gutter={[8, 8]}>
                  <Col>
                    <Form.Item name="scale">
                      <Input type="number" placeholder={t("sem.scale")} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="unit">
                      <Select
                        placeholder={t("common.selectUnit")}
                        options={SCALE_UNITS.map((unit) => ({
                          label: unit,
                          value: unit,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Flex>
          </>
        )}
        {currentStep === 2 && (
          <>
            <p style={{ marginBottom: 15 }}>{t("sem.uploadImagesForAnalysis")}</p>
            <Flex gap={10} justify="space-between" vertical>
              <ImageUpload
                setFile={(file: any) => handleSetFile(file, "batch")}
                title={`${t("sem.uploadImages")}`}
                className="paint-film-image-upload"
                multiple={true}
              />
              {images && images.length > 0 && (
                <div className="image-preview-container">
                  {images.map(({ id, rawData }) => (
                    <div className="image-preview" key={id}>
                      <img src={rawData} alt="Scale" />
                      <span
                        className="close"
                        onClick={() =>
                          setFiles((prev) =>
                            prev.filter((file) => file.uid !== id)
                          )
                        }
                      >
                        <CloseOutlined />
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <Form form={batchForm}>
                <Row gutter={[8, 8]}>
                  <Col>
                    <Form.Item rules={[{ required: true }]} name="batch_name">
                      <Input type="text" placeholder={t("sem.batchName")} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Flex>
          </>
        )}
      </>
    </Modal>
  );
};

export default NewContrastiveAnalysisModal;

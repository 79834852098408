import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
    deleteSemAnalysisFilesRequest,
    downloadBatchReportRequest,
    fetchSemAnalysisListRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import ThumbnailList from "../Shared/ThumbnailList";
import { Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_SEM_ANALYSIS, firestoreDb } from "src/utils/firebase";
import { useHistory } from "react-router-dom";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";
import SelectionsActions from "../Shared/SelectionsActions";
import useTranslate from "src/utils/useTranslate";

const SeedAnalysis = () => {
    const [t] = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    let {
        semAnalysisListStatus,
        deleteSemAnalysisFilesStatus,
        semAnalysisData,
        analysisTypes,
        semAnalysisList,
        downloadBatchReportStatus
    } = useSelector((state: StoreState) => state.semAnalysis);
    const dispatchExecutedRef = useRef(false);
    const [uploadTaskStatus, setUploadTaskStatus] = useState<any>("");
    const [loadingDataMap, setLoadingDataMap] = useState<any>({});
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

    useEffect(() => {
        dispatch(
            fetchSemAnalysisListRequest({
                analysisType:
                    analysisTypes[
                    IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS as keyof typeof analysisTypes
                    ],
            })
        );
    }, [analysisTypes, dispatch]);

    useEffect(() => {
        if (deleteSemAnalysisFilesStatus === AsyncStates.SUCCESS)
            dispatch(
                fetchSemAnalysisListRequest({
                    analysisType:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS as keyof typeof analysisTypes
                        ],
                })
            );
    }, [analysisTypes, deleteSemAnalysisFilesStatus, dispatch]);

    useEffect(() => {
        let unsub: Unsubscribe;

        const listenToMasksGenerationStatus = async () => {
            const taskDocRef = doc(
                firestoreDb,
                `${FB_COLLECTION_SEM_ANALYSIS}/${semAnalysisData.firebase_doc_id}`
            );

            unsub = onSnapshot(taskDocRef, (doc) => {
                const taskDoc = doc.data();

                if (taskDoc) {
                    const taskStatus = taskDoc["status"];
                    setUploadTaskStatus(taskStatus);

                    setLoadingDataMap((prev: any) => ({
                        ...prev,
                        [semAnalysisData.firebase_doc_id]: {
                            status: taskStatus,
                            data: taskDoc,
                        },
                    }));

                    if (taskStatus === "Completed" && !dispatchExecutedRef.current) {
                        dispatchExecutedRef.current = true;
                        dispatch(
                            fetchSemAnalysisListRequest({
                                analysisType:
                                    analysisTypes[
                                    IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS as keyof typeof analysisTypes
                                    ],
                            })
                        );
                    }
                }
            });
        };

        if (
            semAnalysisData.firebase_doc_id &&
            !loadingDataMap?.[semAnalysisData.firebase_doc_id]
        ) {
            setLoadingDataMap((prev: any) => ({
                ...prev,
                [semAnalysisData.firebase_doc_id]: { status: null, data: null },
            }));
            listenToMasksGenerationStatus();
        }

        return () => {
            uploadTaskStatus === "Completed" && unsub && unsub();
            dispatchExecutedRef.current = false;
        };
    }, [
        semAnalysisData.firebase_doc_id,
        uploadTaskStatus,
        dispatch,
        loadingDataMap,
        analysisTypes,
    ]);

    const handleCardClick = ({ fileId }: any) => {
        history.push(
            `/sem-analysis/${IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS}/files/${fileId}`
        );
    };

    const filteredSemAnalysisList = useMemo(
        () =>
            Array.isArray(semAnalysisList)
                ? semAnalysisList
                    ?.filter((item: any) => item.status === "completed")
                    ?.sort(
                        (x: any, y: any) =>
                            new Date(y.created_at).getTime() -
                            new Date(x.created_at).getTime()
                    )
                : [],
        [semAnalysisList]
    );

    const selectAll = useCallback(() => {
        if (selectedFileIds.length === filteredSemAnalysisList.length) setSelectedFileIds([]);
        else {
            setSelectedFileIds(filteredSemAnalysisList.map(s => s.file_id));
        }
    }, [filteredSemAnalysisList, selectedFileIds.length]);

    const downloadReport = useCallback(() => {
        const payload: any = {
            file_ids: selectedFileIds || [],
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS as keyof typeof analysisTypes
                ],
        };

        dispatch(downloadBatchReportRequest(payload));
    }, [analysisTypes, dispatch, selectedFileIds]);

    const deleteFiles = useCallback(() => {
        Modal.confirm({
            title: t("inventory.Areyousure?"),
            icon: <ExclamationCircleOutlined />,
            okText: t("common.delete"),
            okType: "danger",
            cancelText: t("common.Cancel"),
            onOk() {
                dispatch(
                    deleteSemAnalysisFilesRequest({
                        file_ids: selectedFileIds || [],
                        analysis_type:
                            analysisTypes[
                            IMAGE_ANALYSIS_TYPES.SEED_ANALYSIS as keyof typeof analysisTypes
                            ],
                    })
                );
            },
        });
    }, [analysisTypes, dispatch, selectedFileIds, t]);

    return (
        <Spin
            spinning={[deleteSemAnalysisFilesStatus, semAnalysisListStatus].includes(
                AsyncStates.LOADING
            )}
        >
            <SelectionsActions
                count={filteredSemAnalysisList?.length || 0}
                selectedIds={selectedFileIds}
                onSelectAll={selectAll}
                onDownloadReport={downloadReport}
                onDelete={deleteFiles}
                downloadReportStatus={downloadBatchReportStatus}
            />
            <ThumbnailList
                loadingDataMap={loadingDataMap}
                handleCardClick={handleCardClick}
                thumbnailList={filteredSemAnalysisList}
                loading={semAnalysisListStatus === AsyncStates.LOADING}
                selectable={true}
                setSelectedIds={setSelectedFileIds}
                selectedIds={selectedFileIds}
            />
        </Spin>
    );
};

export default SeedAnalysis;

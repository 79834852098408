import { AsyncStates } from "src/constants";
import { IStoreActionState } from "../interfaces";
import { STORE_ACTION_INITIAL_DATA } from "../constants";
import { handleActions } from "redux-actions";
import {
  CreateIngredientActionTypes,
  CreateInventoryMethodActionTypes,
  CreateMethodParameterActionTypes,
  CreateSupplierActionTypes,
  DeleteIngredientActionTypes,
  DeleteInventoryMethodActionTypes,
  DeleteMethodParameterActionTypes,
  DeleteSupplierActionTypes,
  GetMethodParametersActionTypes,
  GetInventoryMethodsLookupActionTypes,
  GetInventoryMethodsActionTypes,
  GetSupplierDetailsActionTypes,
  GetSuppliersActionTypes,
  UpdateIngredientActionTypes,
  UpdateInventoryMethodActionTypes,
  UpdateMethodParameterActionTypes,
  UpdateSupplierActionTypes,
  GetIngredientsActionTypes,
  GetIngredientDetailsActionTypes,
  GetInventoryPropertiesActionTypes,
  CreateInventoryPropertyActionTypes,
  UpdateInventoryPropertyActionTypes,
  DeleteInventoryPropertyActionTypes,
  CreateIngredientFromTrialActionTypes,
  GetIngredientsMetaActionTypes,
  UploadSmilesFileActionTypes,
  ResetDataSmilesFileActionTypes,
  GetIngredientUsedInWoActionTypes,
  GetIngredientFamilyList,
  GetIngredientFamily,
  UpdateIngredientFamily,
  DeleteIngredientFamily,
  CreateIngredientFamily,
  GetIngredientPropertiesActionTypes,
  GetPropertyMethodsActionTypes,
  AddPropertyToIngredientActionTypes,
  GetIngredientPropertyValuesActionTypes,
  GetPropertyMethodsListActionTypes,
  AddPropertyValueToPropertyActionTypes,
  UpdatePropertyValueToPropertyActionTypes,
  GetIngredientPropertiesDetailsActionTypes,
  DeleteIngredientPropertyActionTypes,
  DeleteIngredientPropertyValueActionTypes,
  GetIngredientAttachmentsActionTypes,
  AddIngredientAttachmentActionTypes,
  EditIngredientAttachmentActionTypes,
  DeleteIngredientAttachmentActionTypes,
  GetIngredientGradesActionTypes,
  SaveInventoryFiltersTypes,
  InventoryPreferencesActionTypes,
  UpdateInventoryPreferencesActionTypes,
} from "../actions/inventoryV2";
import { InventoryProperty } from "src/modules/InventoryV2/types";

export type InventoryV2State = {
  // Supplier
  getSuppliers: IStoreActionState & {
    suppliers: any[];
  };
  createSupplier: IStoreActionState;
  getSupplierDetails: IStoreActionState;
  updateSupplier: IStoreActionState;
  deleteSupplier: IStoreActionState;
  // Method
  getProperties: IStoreActionState;
  createProperty: IStoreActionState;
  updateProperty: IStoreActionState;
  deleteProperty: IStoreActionState;

  // Method
  getMethodsLookup: IStoreActionState & {
    methods: any[];
  };
  getMethods: IStoreActionState;
  createMethod: IStoreActionState;
  updateMethod: IStoreActionState;
  deleteMethod: IStoreActionState;
  // Method Parameter
  getMethodParameters: IStoreActionState;
  createMethodParameter: IStoreActionState;
  updateMethodParameter: IStoreActionState;
  deleteMethodParameter: IStoreActionState;
  // Ingredient
  getIngredients: IStoreActionState;
  getIngredientsMeta: IStoreActionState;
  getIngredientDetails: IStoreActionState;
  createIngredient: IStoreActionState;
  updateIngredient: IStoreActionState;
  deleteIngredient: IStoreActionState;
  createIngredientFromTrial: {
    createIngredientFromTrialStatus: AsyncStates;
    createIngredientFromTrialError: string | null;
  };
  smiles: {
    smilesFileUploadStatus: AsyncStates;
    smilesFileUploadError: string | null;
    deleteSmilesFileStatus: AsyncStates,
    deleteSmilesFileError: string | null
  },
  ingredientsUsedInWoData: { [key: string]: any[] }
  ingredientsUsedInWoStatus: AsyncStates,
  ingredientsUsedInWoErrorMessage: string | null,

  ingredientFamilyList: any[],
  ingredientFamilyListStatus: AsyncStates,
  ingredientFamilyListError: string,

  ingredientFamily: any,
  ingredientFamilyStatus: AsyncStates,
  ingredientFamilyError: string,

  ingredientFamilyUpdate: any,
  ingredientFamilyUpdateStatus: AsyncStates,
  ingredientFamilyUpdateError: string,

  ingredientFamilyDelete: any,
  ingredientFamilyDeleteStatus: AsyncStates,
  ingredientFamilyDeleteError: string

  ingredientFamilyCreate: any,
  ingredientFamilyCreateStatus: AsyncStates,
  ingredientFamilyCreateError: string,

  ingredientPropertiesDetailsStatus: AsyncStates,
  ingredientPropertiesDetails: any[],
  ingredientPropertiesDetailsError: string | null,

  addPropertyToIngredientStatus: AsyncStates,
  addPropertyToIngredientError: string | null,

  ingredientPropertyValuesStatus: {
    [key: string]: AsyncStates
  },
  ingredientPropertyValuesError: {
    [key: string]: string | null
  },

  propertyMethodsList: {
    [key: string]: {
      default_parameters: any[],
      methods: any[]
    }
  },
  propertyMethodsListStatus: {
    [key: string]: AsyncStates
  },
  propertyMethodsListError: {
    [key: string]: string | null
  },

  addPropertyValueToPropertyStatus: {
    [key: string]: AsyncStates
  },
  addPropertyValueToPropertyError: {
    [key: string]: string | null
  },

  updatePropertyValueToPropertyStatus: {
    [key: string]: AsyncStates
  },
  updatePropertyValueToPropertyError: {
    [key: string]: string | null
  },

  getIngredientPropertyStatus: any,
  getIngredientPropertyError: any,

  propertyMethods: any,

  deleteIngredientPropertyError: {
    [key: string]: AsyncStates
  },
  deleteIngredientPropertyStatus: {
    [key: string]: string | null
  },

  deleteIngredientPropertyValueStatus: {
    [key: string]: AsyncStates
  },
  deleteIngredientPropertyValueError: {
    [key: string]: string | null
  },

  getIngredientAttachments: IStoreActionState;

  addIngredientAttachment: IStoreActionState;

  deleteIngredientAttachment: IStoreActionState;

  editIngredientAttachment: IStoreActionState;

  ingredientGrades: [],
  ingredientGradesStatus: AsyncStates,
  ingredientGradesError: string,
  savedIngredientFilters: any,

  preferences: {
    data: { [key: string]: any },
    getPreferencesStatus: AsyncStates;
    updatePreferencesStatus: AsyncStates;
    updatePreferencesError: string | null;
    getPreferencesError: string | null;
  }
};

const defaultState: InventoryV2State = {
  // Supplier
  getSuppliers: {
    ...STORE_ACTION_INITIAL_DATA,
    suppliers: [],
  },
  createSupplier: STORE_ACTION_INITIAL_DATA,
  getSupplierDetails: STORE_ACTION_INITIAL_DATA,
  updateSupplier: STORE_ACTION_INITIAL_DATA,
  deleteSupplier: STORE_ACTION_INITIAL_DATA,

  // Property
  getProperties: STORE_ACTION_INITIAL_DATA,
  createProperty: STORE_ACTION_INITIAL_DATA,
  updateProperty: STORE_ACTION_INITIAL_DATA,
  deleteProperty: STORE_ACTION_INITIAL_DATA,

  // Method
  getMethodsLookup: {
    ...STORE_ACTION_INITIAL_DATA,
    methods: [],
  },
  getMethods: STORE_ACTION_INITIAL_DATA,
  createMethod: STORE_ACTION_INITIAL_DATA,
  updateMethod: STORE_ACTION_INITIAL_DATA,
  deleteMethod: STORE_ACTION_INITIAL_DATA,
  // Method Parameter
  getMethodParameters: STORE_ACTION_INITIAL_DATA,
  createMethodParameter: STORE_ACTION_INITIAL_DATA,
  updateMethodParameter: STORE_ACTION_INITIAL_DATA,
  deleteMethodParameter: STORE_ACTION_INITIAL_DATA,
  // Ingredient
  getIngredients: {
    status: AsyncStates.INITIAL,
    data: [],
    error: null,
    message: null,
  },
  getIngredientsMeta: {
    status: AsyncStates.INITIAL,
    data: {},
    error: null,
    message: null,
  },
  getIngredientDetails: STORE_ACTION_INITIAL_DATA,
  createIngredient: STORE_ACTION_INITIAL_DATA,
  updateIngredient: STORE_ACTION_INITIAL_DATA,
  deleteIngredient: STORE_ACTION_INITIAL_DATA,
  createIngredientFromTrial: {
    createIngredientFromTrialStatus: AsyncStates.INITIAL,
    createIngredientFromTrialError: null,
  },
  smiles: {
    smilesFileUploadStatus: AsyncStates.INITIAL,
    smilesFileUploadError: null,
    deleteSmilesFileStatus: AsyncStates.INITIAL,
    deleteSmilesFileError: null
  },
  ingredientsUsedInWoData: {},
  ingredientsUsedInWoStatus: AsyncStates.INITIAL,
  ingredientsUsedInWoErrorMessage: null,

  ingredientFamilyList: [],
  ingredientFamilyListStatus: AsyncStates.INITIAL,
  ingredientFamilyListError: '',

  ingredientFamily: {},
  ingredientFamilyStatus: AsyncStates.INITIAL,
  ingredientFamilyError: '',

  ingredientFamilyUpdate: {},
  ingredientFamilyUpdateStatus: AsyncStates.INITIAL,
  ingredientFamilyUpdateError: '',

  ingredientFamilyDelete: {},
  ingredientFamilyDeleteStatus: AsyncStates.INITIAL,
  ingredientFamilyDeleteError: '',

  ingredientFamilyCreate: {},
  ingredientFamilyCreateStatus: AsyncStates.INITIAL,
  ingredientFamilyCreateError: '',

  ingredientPropertiesDetailsStatus: AsyncStates.INITIAL,
  ingredientPropertiesDetails: [],
  ingredientPropertiesDetailsError: null,

  addPropertyToIngredientStatus: AsyncStates.INITIAL,
  addPropertyToIngredientError: null,

  ingredientPropertyValuesStatus: {},
  ingredientPropertyValuesError: {},

  propertyMethodsList: {},
  propertyMethodsListStatus: {},
  propertyMethodsListError: {},

  addPropertyValueToPropertyStatus: {},
  addPropertyValueToPropertyError: {},

  updatePropertyValueToPropertyStatus: {},
  updatePropertyValueToPropertyError: {},

  getIngredientPropertyStatus: {},
  getIngredientPropertyError: {},

  propertyMethods: {
    status: AsyncStates.INITIAL,
    data: [],
    error: null,
    message: null,
  },

  deleteIngredientPropertyError: {},
  deleteIngredientPropertyStatus: {},

  deleteIngredientPropertyValueStatus: {},
  deleteIngredientPropertyValueError: {},

  getIngredientAttachments: {
    status: AsyncStates.INITIAL,
    data: [],
    error: null,
  },
  addIngredientAttachment: {
    status: AsyncStates.INITIAL,
    data: [],
    error: null,
  },

  editIngredientAttachment: {
    status: AsyncStates.INITIAL,
    data: [],
    error: null,
  },

  deleteIngredientAttachment: {
    status: AsyncStates.INITIAL,
    data: [],
    error: null,
  },

  ingredientGrades: [],
  ingredientGradesStatus: AsyncStates.INITIAL,
  ingredientGradesError: "",
  savedIngredientFilters: null,

  preferences: {
    data: {
      ingredient_table: {
        category: true,
        costing: true,
        grade: true,
        lot_no: true,
        project_id: true,
        responsible_person: false,
        sub_category: true,
        supplier: true,
        version_date: false,
        sap_no: false
      }
    },
    getPreferencesStatus: AsyncStates.INITIAL,
    updatePreferencesStatus: AsyncStates.INITIAL,
    getPreferencesError: null,
    updatePreferencesError: null,
  }
};

const getProcessedIngredientData = (action: string, payload: any, ingredientDetails: any) => {
  const newIngredient: any = { ...ingredientDetails };

  const propertyIndex = newIngredient.properties.findIndex(
    (p: any) =>
      payload.reqPayload.from === 'family' ?
        p.property_id === payload.reqPayload.property_id :
        p.inventory_property_id === payload.reqPayload.property_id
  );

  const addProperty = () => {
    newIngredient.properties.push({
      ...payload.data,
      methods: [],
    });
  };

  const updateProperty = () => {
    if (propertyIndex !== -1) {
      const newProperty = {
        ...newIngredient.properties[propertyIndex],
        ...payload.data,
      };
      newIngredient.properties[propertyIndex] = newProperty;
    }
    return newIngredient;
  };

  const deleteProperty = () => {
    newIngredient.properties.splice(propertyIndex, 1);
  }

  const addMethod = () => {
    if (propertyIndex !== -1) {
      const newProperty = { ...newIngredient.properties[propertyIndex] };
      newProperty.methods.push({
        ...payload.data,
        conditions: [],
      });
      newIngredient.properties[propertyIndex] = newProperty;
    }
  };

  const updateMethod = () => {
    if (propertyIndex !== -1) {
      const newProperty = { ...newIngredient.properties[propertyIndex] };
      const methodIndex = newProperty.methods.findIndex(
        (m: any) => m.method_id === payload.reqPayload?.method_id
      );
      if (methodIndex !== -1) {
        const newMethod = {
          ...newProperty.methods[methodIndex],
          ...payload.reqPayload,
        };
        newProperty.methods[methodIndex] = newMethod;
        newIngredient.properties[propertyIndex] = newProperty;
      }
    }
  };

  const deleteMethod = () => {
    if (propertyIndex !== -1) {
      const newProperty: InventoryProperty = {
        ...newIngredient.properties[propertyIndex],
        methods: newIngredient.properties[propertyIndex].methods.filter(
          (m: any) => m.method_id !== payload.reqPayload?.method_id
        ),
      };
      newIngredient.properties[propertyIndex] = newProperty;
    }
  }

  const addMethodParameter = () => {
    if (propertyIndex !== -1) {
      const newProperty = { ...newIngredient.properties[propertyIndex] };
      const methodIndex = newProperty.methods.findIndex(
        (m: any) => m.method_id === payload.reqPayload.method_id
      );
      if (methodIndex !== -1) {
        const newMethod = { ...newProperty.methods[methodIndex] };
        newMethod.conditions.push(payload.data);
        newProperty.methods[methodIndex] = newMethod;
        newIngredient.properties[propertyIndex] = newProperty;
      }
    }
  };

  const updateMethodParameter = () => {
    if (propertyIndex !== -1) {
      const newProperty = { ...newIngredient.properties[propertyIndex] };
      const methodIndex = newProperty.methods.findIndex(
        (m: any) => m.method_id === payload.reqPayload.method_id
      );
      if (methodIndex !== -1) {
        const newMethod = { ...newProperty.methods[methodIndex] };
        const parameterIndex = newMethod.conditions.findIndex(
          (condition: any) =>
            condition.method_parameter_id ===
            payload.reqPayload?.method_parameter_id
        );
        if (parameterIndex !== -1) {
          const newParameter = {
            ...newMethod.conditions[parameterIndex],
            ...payload.data,
          };
          newMethod.conditions[parameterIndex] = newParameter;
          newProperty.methods[methodIndex] = newMethod;
          newIngredient.properties[propertyIndex] = newProperty;
        }
      }
    }
  }

  const deleteMethodParameter = () => {
    if (propertyIndex !== -1) {
      const newProperty = { ...newIngredient.properties[propertyIndex] };
      const methodIndex = newProperty.methods.findIndex(
        (m: any) => m.method_id === payload.reqPayload.method_id
      );
      if (methodIndex !== -1) {
        const newMethod = { ...newProperty.methods[methodIndex] };
        const parameterIndex = newMethod.conditions.findIndex(
          (condition: any) =>
            condition.method_parameter_id ===
            payload.reqPayload?.method_parameter_id
        );
        if (parameterIndex !== -1) {
          newMethod.conditions.splice(parameterIndex, 1);
          newProperty.methods[methodIndex] = newMethod;
          newIngredient.properties[propertyIndex] = newProperty;
        }
      }
    }
  }

  if (action === CreateInventoryPropertyActionTypes.SUCCESS) {
    addProperty();
  } else if (action === UpdateInventoryPropertyActionTypes.SUCCESS) {
    updateProperty();
  } else if (action === DeleteInventoryPropertyActionTypes.SUCCESS) {
    deleteProperty();
  } else if (action === CreateInventoryMethodActionTypes.SUCCESS) {
    addMethod();
  } else if (action === UpdateInventoryMethodActionTypes.SUCCESS) {
    updateMethod();
  } else if (action === DeleteInventoryMethodActionTypes.SUCCESS) {
    deleteMethod();
  } else if (action === CreateMethodParameterActionTypes.SUCCESS) {
    addMethodParameter();
  } else if (action === UpdateMethodParameterActionTypes.SUCCESS) {
    updateMethodParameter();
  } else if (action === DeleteMethodParameterActionTypes.SUCCESS) {
    deleteMethodParameter();
  }


  return newIngredient;
}

const deleteIngredientProperty = (propertyDetails: any[], category_id: string, inventory_property_id: string) => {
  let updatedPropertyDetails = [...propertyDetails];
  const categoryProperties = propertyDetails.find((item: any) => item.category_id === category_id)?.properties ?? [];
  if (categoryProperties.length === 1) {
    updatedPropertyDetails = propertyDetails.filter((item: any) => item.category_id !== category_id);
    return updatedPropertyDetails;
  } else {
    propertyDetails.forEach((item: any, index) => {
      if (item.category_id === category_id) {
        propertyDetails[index].properties = item.properties.filter((property: any) => property.inventory_property_id !== inventory_property_id);
      }
    });
    return propertyDetails;
  }
}

const inventoryV2Reducer = handleActions(
  {
    // Get Suppliers
    [GetSuppliersActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getSuppliers: {
          suppliers: [],
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetSuppliersActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getSuppliers: {
          ...state.getSuppliers,
          status: AsyncStates.SUCCESS,
          error: "",
          suppliers: action.payload,
        },
      };
    },
    [GetSuppliersActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getSuppliers: {
        ...state.getSuppliers,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetSuppliersActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getSuppliers: {
        ...STORE_ACTION_INITIAL_DATA,
        suppliers: [],
      },
    }),

    // Create Supplier
    [CreateSupplierActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createSupplier: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateSupplierActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createSupplier: {
          ...state.createSupplier,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        getSuppliers: {
          ...state.getSuppliers,
          data: [...state.getSuppliers.suppliers, action.payload.data],
        },
      };
    },
    [CreateSupplierActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createSupplier: {
        ...state.createSupplier,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateSupplierActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createSupplier: STORE_ACTION_INITIAL_DATA,
    }),

    // Get Supplier Details
    [GetSupplierDetailsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getSupplierDetails: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetSupplierDetailsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getSupplierDetails: {
          ...state.getSupplierDetails,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetSupplierDetailsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getSupplierDetails: {
        ...state.getSupplierDetails,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetSupplierDetailsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getSupplierDetails: STORE_ACTION_INITIAL_DATA,
    }),

    // Update Supplier
    [UpdateSupplierActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateSupplier: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UpdateSupplierActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateSupplier: {
          ...state.updateSupplier,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [UpdateSupplierActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateSupplier: {
        ...state.updateSupplier,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateSupplierActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      updateSupplier: STORE_ACTION_INITIAL_DATA,
    }),

    // Delete Supplier
    [DeleteSupplierActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        deleteSupplier: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DeleteSupplierActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteSupplier: {
          ...state.deleteSupplier,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [DeleteSupplierActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      deleteSupplier: {
        ...state.deleteSupplier,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DeleteSupplierActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      deleteSupplier: STORE_ACTION_INITIAL_DATA,
    }),

    // Get Properties
    [GetInventoryPropertiesActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getProperties: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetInventoryPropertiesActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getProperties: {
          ...state.getProperties,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetInventoryPropertiesActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getProperties: {
        ...state.getProperties,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetInventoryPropertiesActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getProperties: STORE_ACTION_INITIAL_DATA,
    }),

    // Create Property
    [CreateInventoryPropertyActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createProperty: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateInventoryPropertyActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createProperty: {
          ...state.createProperty,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(CreateInventoryPropertyActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(CreateInventoryPropertyActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [CreateInventoryPropertyActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createProperty: {
        ...state.createProperty,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateInventoryPropertyActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createProperty: STORE_ACTION_INITIAL_DATA,
    }),

    // Update Property
    [UpdateInventoryPropertyActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateProperty: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UpdateInventoryPropertyActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateProperty: {
          ...state.updateProperty,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(UpdateInventoryPropertyActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(UpdateInventoryPropertyActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [UpdateInventoryPropertyActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateProperty: {
        ...state.updateProperty,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateInventoryPropertyActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      updateProperty: STORE_ACTION_INITIAL_DATA,
    }),

    // Update Property
    [DeleteInventoryPropertyActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        deleteProperty: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DeleteInventoryPropertyActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteProperty: {
          ...state.deleteProperty,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(DeleteInventoryPropertyActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(DeleteInventoryPropertyActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [DeleteInventoryPropertyActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      deleteProperty: {
        ...state.deleteProperty,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DeleteInventoryPropertyActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      deleteProperty: STORE_ACTION_INITIAL_DATA,
    }),

    // Get Methods
    [GetInventoryMethodsLookupActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getMethodsLookup: {
          methods: [],
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetInventoryMethodsLookupActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getMethodsLookup: {
          ...state.getMethodsLookup,
          status: AsyncStates.SUCCESS,
          error: "",
          methods: action.payload,
        },
      };
    },
    [GetInventoryMethodsLookupActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getMethodsLookup: {
        ...state.getMethodsLookup,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetInventoryMethodsLookupActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getMethodsLookup: { ...STORE_ACTION_INITIAL_DATA, methods: [] },
    }),

    [GetInventoryMethodsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getMethods: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetInventoryMethodsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getMethods: {
          ...state.getMethods,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetInventoryMethodsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getMethods: {
        ...state.getMethods,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetInventoryMethodsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getMethods: STORE_ACTION_INITIAL_DATA,
    }),

    // Create Methods
    [CreateInventoryMethodActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createMethod: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateInventoryMethodActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createMethod: {
          ...state.createMethod,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        getMethods: {
          ...state.getMethods,
          data: {
            ...state.getMethods.data,
            // append from action action.payload
          },
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(CreateInventoryMethodActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(CreateInventoryMethodActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )

      };
    },
    [CreateInventoryMethodActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createMethod: {
        ...state.createMethod,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateInventoryMethodActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createMethod: STORE_ACTION_INITIAL_DATA,
    }),

    // Update Methods
    [UpdateInventoryMethodActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateMethod: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UpdateInventoryMethodActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateMethod: {
          ...state.updateMethod,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(UpdateInventoryMethodActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(UpdateInventoryMethodActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [UpdateInventoryMethodActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateMethod: {
        ...state.updateMethod,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateInventoryMethodActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      updateMethod: STORE_ACTION_INITIAL_DATA,
    }),

    // Delete Methods
    [DeleteInventoryMethodActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        deleteMethod: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DeleteInventoryMethodActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteMethod: {
          ...state.deleteMethod,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(DeleteInventoryMethodActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(DeleteInventoryMethodActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [DeleteInventoryMethodActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      deleteMethod: {
        ...state.deleteMethod,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DeleteInventoryMethodActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      deleteMethod: STORE_ACTION_INITIAL_DATA,
    }),

    // Get Method Parameters
    [GetMethodParametersActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getMethodParameters: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetMethodParametersActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getMethodParameters: {
          ...state.getMethodParameters,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetMethodParametersActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getMethodParameters: {
        ...state.getMethodParameters,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetMethodParametersActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getMethodParameters: STORE_ACTION_INITIAL_DATA,
    }),

    // Create Method Parameters
    [CreateMethodParameterActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createMethodParameter: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateMethodParameterActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createMethodParameter: {
          ...state.createMethodParameter,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(CreateMethodParameterActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(CreateMethodParameterActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [CreateMethodParameterActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createMethodParameter: {
        ...state.createMethodParameter,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateMethodParameterActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createMethodParameter: STORE_ACTION_INITIAL_DATA,
    }),

    // Update Method Parameters
    [UpdateMethodParameterActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateMethodParameter: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UpdateMethodParameterActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateMethodParameter: {
          ...state.updateMethodParameter,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(UpdateMethodParameterActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(UpdateMethodParameterActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [UpdateMethodParameterActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateMethodParameter: {
        ...state.updateMethodParameter,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateMethodParameterActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      updateMethodParameter: STORE_ACTION_INITIAL_DATA,
    }),

    // Delete Method Parameters
    [DeleteMethodParameterActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        deleteMethodParameter: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DeleteMethodParameterActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteMethodParameter: {
          ...state.deleteMethodParameter,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        ...(action.payload.reqPayload.from === 'family' ?
          { ingredientFamily: { ...getProcessedIngredientData(DeleteMethodParameterActionTypes.SUCCESS, action.payload, state.ingredientFamily) } } :
          { getIngredientDetails: { ...state.getIngredientDetails, data: getProcessedIngredientData(DeleteMethodParameterActionTypes.SUCCESS, action.payload, state.getIngredientDetails.data) } }
        )
      };
    },
    [DeleteMethodParameterActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      deleteMethodParameter: {
        ...state.deleteMethodParameter,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DeleteMethodParameterActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      deleteMethodParameter: STORE_ACTION_INITIAL_DATA,
    }),

    // Get Ingredients
    [GetIngredientsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getIngredients: {
          data: [],
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetIngredientsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getIngredients: {
          ...state.getIngredients,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetIngredientsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getIngredients: {
        ...state.getIngredients,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetIngredientsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getIngredients: STORE_ACTION_INITIAL_DATA,
    }),

    [GetIngredientsMetaActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getIngredientsMeta: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetIngredientsMetaActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getIngredientsMeta: {
          ...state.getIngredientsMeta,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetIngredientsMetaActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getIngredientsMeta: {
        ...state.getIngredientsMeta,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    // Get Ingredients
    [GetIngredientDetailsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getIngredientDetails: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetIngredientDetailsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getIngredientDetails: {
          ...state.getIngredientDetails,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetIngredientDetailsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getIngredientDetails: {
        ...state.getIngredientDetails,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetIngredientDetailsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getIngredientDetails: STORE_ACTION_INITIAL_DATA,
    }),

    // Create Ingredient
    [CreateIngredientActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createIngredient: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateIngredientActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createIngredient: {
          ...state.createIngredient,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [CreateIngredientActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createIngredient: {
        ...state.createIngredient,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateIngredientActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createIngredient: STORE_ACTION_INITIAL_DATA,
    }),
    // Update Ingredient
    [UpdateIngredientActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateIngredient: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UpdateIngredientActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateIngredient: {
          ...state.updateIngredient,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [UpdateIngredientActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateIngredient: {
        ...state.updateIngredient,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateIngredientActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      updateIngredient: STORE_ACTION_INITIAL_DATA,
    }),

    // Delete Ingredient
    [DeleteIngredientActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        deleteIngredient: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DeleteIngredientActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteIngredient: {
          ...state.deleteIngredient,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [DeleteIngredientActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      deleteIngredient: {
        ...state.deleteIngredient,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DeleteIngredientActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      deleteIngredient: STORE_ACTION_INITIAL_DATA,
    }),

    [CreateIngredientFromTrialActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createIngredientFromTrial: {
          createIngredientFromTrialStatus: AsyncStates.LOADING,
          createIngredientFromTrialError: "",
        },
      };
    },
    [CreateIngredientFromTrialActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createIngredientFromTrial: {
          ...state.createIngredientFromTrial,
          createIngredientFromTrialStatus: AsyncStates.SUCCESS,
          createIngredientFromTrialError: null,
        },
      };
    },
    [CreateIngredientFromTrialActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createIngredientFromTrial: {
        ...state.createIngredientFromTrial,
        createIngredientFromTrialStatus: AsyncStates.ERROR,
        createIngredientFromTrialError: action.payload,
      },
    }),
    [CreateIngredientFromTrialActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createIngredientFromTrial: {
        createIngredientFromTrialStatus: AsyncStates.INITIAL,
        createIngredientFromTrialError: null,
      },
    }),
    [UploadSmilesFileActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        smiles: {
          ...state.smiles,
          smilesFileUploadStatus: AsyncStates.LOADING,
          smilesFileUploadError: null,
        },
      };
    },
    [UploadSmilesFileActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        smiles: {
          ...state.smiles,
          smilesFileUploadStatus: AsyncStates.SUCCESS,
          smilesFileUploadError: null,
        },
      };
    },
    [UploadSmilesFileActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      smiles: {
        ...state.smiles,
        smilesFileUploadStatus: AsyncStates.ERROR,
        smilesFileUploadError: action.payload,
      },
    }),
    [UploadSmilesFileActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      smiles: {
        ...state.smiles,
        smilesFileUploadStatus: AsyncStates.INITIAL,
        smilesFileUploadError: null,
      }
    }),
    [ResetDataSmilesFileActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        smiles: {
          ...state.smiles,
          deleteSmilesFileStatus: AsyncStates.LOADING,
          deleteSmilesFileError: null
        },
      };
    },
    [ResetDataSmilesFileActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getIngredientDetails: {
          ...state.getIngredientDetails,
          data: { ...(state.getIngredientDetails?.data ?? {}), chemical_information: null },
        },
        smiles: {
          smilesFileUploadStatus: AsyncStates.INITIAL,
          smilesFileUploadError: null,
          deleteSmilesFileStatus: AsyncStates.SUCCESS,
          deleteSmilesFileError: null,
        },
      };
    },
    [ResetDataSmilesFileActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      smiles: {
        ...state.smiles,
        deleteSmilesFileStatus: AsyncStates.ERROR,
        deleteSmilesFileError: action.payload,
      },
    }),
    [ResetDataSmilesFileActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      smiles: {
        ...state.smiles,
        deleteSmilesFileStatus: AsyncStates.INITIAL,
        deleteSmilesFileError: null,
      }
    }),

    [GetIngredientUsedInWoActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        ingredientsUsedInWoStatus: AsyncStates.LOADING,
        ingredientsUsedInWoErrorMessage: null
      };
    },
    [GetIngredientUsedInWoActionTypes.SUCCESS]: (state, action: any) => {
      const apiResponse = action.payload ?? {}
      return {
        ...state,
        ingredientsUsedInWoData: { ...(state.ingredientsUsedInWoData ?? {}), ...apiResponse },
        ingredientsUsedInWoStatus: AsyncStates.SUCCESS,
        ingredientsUsedInWoErrorMessage: null
      };
    },
    [GetIngredientUsedInWoActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientsUsedInWoStatus: AsyncStates.ERROR,
      ingredientsUsedInWoErrorMessage: action.payload
    }),
    [GetIngredientUsedInWoActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      ingredientsUsedInWoData: {},
      ingredientsUsedInWoStatus: AsyncStates.INITIAL,
      ingredientsUsedInWoErrorMessage: null
    }),

    [GetIngredientFamilyList.REQUEST]: (state) => ({
      ...state,
      ingredientFamilyListStatus: AsyncStates.LOADING
    }),
    [GetIngredientFamilyList.SUCCESS]: (state, action: any) => ({
      ...state,
      ingredientFamilyListStatus: AsyncStates.SUCCESS,
      ingredientFamilyList: action.payload
    }),
    [GetIngredientFamilyList.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientFamilyListStatus: AsyncStates.ERROR,
      ingredientFamilyListError: action.payload
    }),
    [GetIngredientFamilyList.CLEAR]: (state) => ({
      ...state,
      ingredientFamilyList: [],
      ingredientFamilyListStatus: AsyncStates.INITIAL,
      ingredientFamilyListError: ''
    }),

    [GetIngredientFamily.REQUEST]: (state) => ({
      ...state,
      ingredientFamilyStatus: AsyncStates.LOADING
    }),
    [GetIngredientFamily.SUCCESS]: (state, action: any) => ({
      ...state,
      ingredientFamilyStatus: AsyncStates.SUCCESS,
      ingredientFamily: action.payload
    }),
    [GetIngredientFamily.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientFamilyStatus: AsyncStates.ERROR,
      ingredientFamilyError: action.payload
    }),
    [GetIngredientFamily.CLEAR]: (state) => ({
      ...state,
      ingredientFamily: {},
      ingredientFamilyStatus: AsyncStates.INITIAL,
      ingredientFamilyError: ''
    }),

    [UpdateIngredientFamily.REQUEST]: (state) => ({
      ...state,
      ingredientFamilyUpdateStatus: AsyncStates.LOADING
    }),
    [UpdateIngredientFamily.SUCCESS]: (state, action: any) => ({
      ...state,
      ingredientFamilyUpdateStatus: AsyncStates.SUCCESS,
      ingredientFamilyUpdate: action.payload
    }),
    [UpdateIngredientFamily.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientFamilyUpdateStatus: AsyncStates.ERROR,
      ingredientFamilyUpdateError: action.payload
    }),
    [UpdateIngredientFamily.CLEAR]: (state) => ({
      ...state,
      ingredientFamilyUpdate: {},
      ingredientFamilyUpdateStatus: AsyncStates.INITIAL,
      ingredientFamilyUpdateError: ''
    }),

    [DeleteIngredientFamily.REQUEST]: (state) => ({
      ...state,
      ingredientFamilyDeleteStatus: AsyncStates.LOADING
    }),
    [DeleteIngredientFamily.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        ingredientFamilyDeleteStatus: AsyncStates.SUCCESS,
        ingredientFamilyList: state.ingredientFamilyList?.filter((family: any) => family.family_id !== action.payload) ?? []
      }
    },
    [DeleteIngredientFamily.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientFamilyDeleteStatus: AsyncStates.ERROR,
      ingredientFamilyDeleteError: action.payload
    }),
    [DeleteIngredientFamily.CLEAR]: (state) => ({
      ...state,
      ingredientFamilyDelete: {},
      ingredientFamilyDeleteStatus: AsyncStates.INITIAL,
      ingredientFamilyDeleteError: ''
    }),

    [CreateIngredientFamily.REQUEST]: (state) => ({
      ...state,
      ingredientFamilyCreateStatus: AsyncStates.LOADING
    }),
    [CreateIngredientFamily.SUCCESS]: (state, action: any) => ({
      ...state,
      ingredientFamilyCreateStatus: AsyncStates.SUCCESS,
      ingredientFamilyCreate: action.payload
    }),
    [CreateIngredientFamily.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientFamilyCreateStatus: AsyncStates.ERROR,
      ingredientFamilyCreateError: action.payload
    }),
    [CreateIngredientFamily.CLEAR]: (state) => ({
      ...state,
      ingredientFamilyCreate: {},
      ingredientFamilyCreateStatus: AsyncStates.INITIAL,
      ingredientFamilyCreateError: ''
    }),
    [GetIngredientPropertiesActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        getIngredientPropertyStatus: {
          ...state.getIngredientPropertyStatus,
          ...(action.payload?.inventory_id && { [action.payload?.inventory_id]: AsyncStates.LOADING })
        },
        getIngredientPropertyError: {
          ...state.getIngredientPropertyError,
          ...(action.payload?.inventory_id && { [action.payload?.inventory_id]: null })
        }
      }
    },
    [GetIngredientPropertiesActionTypes.SUCCESS]: (state, action: any) => {
      const ingredientData = JSON.parse(JSON.stringify(state.getIngredients?.data?.data ?? []))
      const updatedIngredientData = ingredientData.map((ingredient: any) => {
        if (ingredient.inventory_id === action.payload.inventory_id) {
          return {
            ...ingredient,
            properties: action.payload?.data ?? []
          }
        }
        return ingredient
      })
      return {
        ...state,
        getIngredientPropertyStatus: {
          ...state.getIngredientPropertyStatus,
          [action.payload.inventory_id]: AsyncStates.SUCCESS
        },
        getIngredients: {
          ...state.getIngredients,
          data: {
            ...state.getIngredients.data,
            data: updatedIngredientData
          }
        },
        ...(action.payload.inventory_id === state.getIngredientDetails?.data?.inventory_id && {
          getIngredientDetails: {
            ...state.getIngredientDetails,
            data: {
              ...state.getIngredientDetails.data,
              properties: action.payload?.data ?? []
            }
          }
        })
      }
    },
    [GetIngredientPropertiesActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getIngredientPropertyStatus: {
        ...state.getIngredientPropertyStatus,
        [action.payload.inventory_id]: AsyncStates.ERROR
      },
      getIngredientPropertyError: {
        ...state.getIngredientPropertyError,
        [action.payload.inventory_id]: action.payload.message
      }
    }),
    [GetIngredientPropertiesActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getIngredientPropertyStatus: {},
      getIngredientPropertyError: {}
    }),

    [GetPropertyMethodsActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        propertyMethods: {
          ...state.propertyMethods,
          data: [],
          status: AsyncStates.LOADING
        }
      }
    },
    [GetPropertyMethodsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        propertyMethods: {
          ...state.propertyMethods,
          data: action.payload.data,
          status: AsyncStates.SUCCESS
        }
      }
    },
    [GetPropertyMethodsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      propertyMethods: {
        ...state.propertyMethods,
        status: AsyncStates.ERROR
      }
    }),
    [GetPropertyMethodsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      propertyMethods: {
        ...state.propertyMethods,
        data: [],
        status: AsyncStates.INITIAL
      }
    }),

    [GetIngredientPropertiesDetailsActionTypes.REQUEST]: (state) => ({
      ...state,
      ingredientPropertiesDetailsStatus: AsyncStates.LOADING,
      ingredientPropertiesDetailsError: null
    }),
    [GetIngredientPropertiesDetailsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        ingredientPropertiesDetailsStatus: AsyncStates.SUCCESS,
        ingredientPropertiesDetails: action.payload,
        ingredientPropertiesDetailsError: null
      }
    },
    [GetIngredientPropertiesDetailsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      ingredientPropertiesDetailsStatus: AsyncStates.ERROR,
      ingredientPropertiesDetailsError: action.payload
    }),
    [GetIngredientPropertiesDetailsActionTypes.CLEAR]: (state) => ({
      ...state,
      ingredientPropertiesDetailsStatus: AsyncStates.INITIAL,
      ingredientPropertiesDetails: [],
      ingredientPropertiesDetailsError: null
    }),
    [AddPropertyToIngredientActionTypes.REQUEST]: (state) => ({
      ...state,
      addPropertyToIngredientStatus: AsyncStates.LOADING,
      addPropertyToIngredientError: null
    }),
    [AddPropertyToIngredientActionTypes.SUCCESS]: (state, action: any) => {
      const apiResponse = action.payload ?? [];

      let ingredientPropertiesDetails = []
      if (!state.ingredientPropertiesDetails?.length) {
        ingredientPropertiesDetails = JSON.parse(JSON.stringify(apiResponse));
      } else {
        ingredientPropertiesDetails = apiResponse.map((newCategoryBasedPropertyData: any) => {
          const oldCategoryBasedProperty = state.ingredientPropertiesDetails?.find((oldItem) => oldItem.category_id === newCategoryBasedPropertyData.category_id)
          if (oldCategoryBasedProperty) {
            return {
              ...oldCategoryBasedProperty,
              properties: newCategoryBasedPropertyData.properties.map((newPropertyData: any) => {
                const oldProperty = oldCategoryBasedProperty.properties.find((oldProperty: any) => oldProperty.inventory_property_id === newPropertyData.inventory_property_id)
                if (oldProperty) {
                  return {
                    ...newPropertyData,
                    ...oldProperty
                  }
                }
                return newPropertyData
              })
            }
          }
          return newCategoryBasedPropertyData
        })
      }

      return {
        ...state,
        addPropertyToIngredientStatus: AsyncStates.SUCCESS,
        addPropertyToIngredientError: null,
        ingredientPropertiesDetails: ingredientPropertiesDetails.filter((item: any) => item)
      }
    },
    [AddPropertyToIngredientActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      addPropertyToIngredientStatus: AsyncStates.ERROR,
      addPropertyToIngredientError: action.payload
    }),
    [AddPropertyToIngredientActionTypes.CLEAR]: (state) => ({
      ...state,
      addPropertyToIngredientStatus: AsyncStates.INITIAL,
      addPropertyToIngredientError: null
    }),
    [GetIngredientPropertyValuesActionTypes.REQUEST]: (state, action: any) => {
      const inventory_property_id = action.payload.inventory_property_id;
      return {
        ...state,
        ingredientPropertyValuesStatus: {
          ...state.ingredientPropertyValuesStatus,
          [inventory_property_id]: AsyncStates.LOADING
        },
        ingredientPropertyValuesError: {
          ...state.ingredientPropertyValuesError,
          [inventory_property_id]: null
        }
      }
    },
    [GetIngredientPropertyValuesActionTypes.SUCCESS]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;
      const propertyValues = action.payload.data;
      const propertiesDetails = JSON.parse(JSON.stringify(state.ingredientPropertiesDetails));

      state.ingredientPropertiesDetails.forEach((item: any, index) => {
        if (item.category_id === action.payload.apiPayload.category_id) {
          item.properties.forEach((property: any, propertyIndex: number) => {
            if (property.inventory_property_id === inventory_property_id) {
              propertiesDetails[index].properties[propertyIndex].property_values = propertyValues;
            }
          })
        }
      })

      return {
        ...state,
        ingredientPropertyValuesStatus: {
          ...state.ingredientPropertyValuesStatus,
          [inventory_property_id]: AsyncStates.SUCCESS
        },
        ingredientPropertyValuesError: {
          ...state.ingredientPropertyValuesError,
          [inventory_property_id]: null
        },
        ingredientPropertiesDetails: propertiesDetails
      }
    },
    [GetIngredientPropertyValuesActionTypes.FAILURE]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;

      return {
        ...state,
        ingredientPropertyValuesStatus: {
          ...state.ingredientPropertyValuesStatus,
          [inventory_property_id]: AsyncStates.ERROR
        },
        ingredientPropertyValuesError: {
          ...state.ingredientPropertyValuesError,
          [inventory_property_id]: action.payload.message
        }
      }
    },
    [GetIngredientPropertyValuesActionTypes.CLEAR]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;

      return {
        ...state,
        ingredientPropertyValuesStatus: {
          ...state.ingredientPropertyValuesStatus,
          [inventory_property_id]: AsyncStates.INITIAL
        },
        ingredientPropertyValuesError: {
          ...state.ingredientPropertyValuesError,
          [inventory_property_id]: null
        }
      }
    },

    [GetPropertyMethodsListActionTypes.REQUEST]: (state, action: any) => {
      const property_id = action.payload.property_id;

      return {
        ...state,
        propertyMethodsListStatus: {
          ...state.propertyMethodsListStatus,
          [property_id]: AsyncStates.LOADING
        },
        propertyMethodsListError: {
          ...state.propertyMethodsListError,
          [property_id]: null
        }
      }
    },
    [GetPropertyMethodsListActionTypes.SUCCESS]: (state, action: any) => {
      const property_id = action.payload.apiPayload.property_id;
      return {
        ...state,
        propertyMethodsListStatus: {
          ...state.propertyMethodsListStatus,
          [property_id]: AsyncStates.SUCCESS
        },
        propertyMethodsList: {
          ...state.propertyMethodsList,
          [property_id]: action.payload.data
        },
        propertyMethodsListError: {
          ...state.propertyMethodsListError,
          [property_id]: null
        }
      }
    },
    [GetPropertyMethodsListActionTypes.FAILURE]: (state, action: any) => {
      const property_id = action.payload.apiPayload.property_id;

      return {
        ...state,
        propertyMethodsListStatus: {
          ...state.propertyMethodsListStatus,
          [property_id]: AsyncStates.ERROR
        },
        propertyMethodsListError: {
          ...state.propertyMethodsListError,
          [property_id]: action.payload.message
        }
      }
    },
    [GetPropertyMethodsListActionTypes.CLEAR]: (state) => ({
      ...state,
      propertyMethodsListStatus: {},
      propertyMethodsList: {},
      propertyMethodsListError: {}
    }),
    [AddPropertyValueToPropertyActionTypes.REQUEST]: (state, action: any) => {
      const inventory_property_id = action.payload.inventory_property_id;

      return {
        ...state,
        addPropertyValueToPropertyStatus: {
          ...state.addPropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.LOADING
        },
        addPropertyValueToPropertyError: {
          ...state.addPropertyValueToPropertyError,
          [inventory_property_id]: null
        }
      }
    },
    [AddPropertyValueToPropertyActionTypes.SUCCESS]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;
      const propertyValues = action.payload.data;
      const propertyDetails = JSON.parse(JSON.stringify(state.ingredientPropertiesDetails));

      state.ingredientPropertiesDetails.forEach((item: any, index) => {
        if (item.category_id === action.payload.apiPayload.category_id) {
          item.properties.forEach((property: any, propertyIndex: number) => {
            if (property.inventory_property_id === inventory_property_id) {
              propertyDetails[index].properties[propertyIndex].property_values = [...propertyDetails[index].properties[propertyIndex].property_values, { ...propertyValues, property_name: propertyDetails[index].properties[propertyIndex]?.property_name }];
            }
          })
        }
      });

      return {
        ...state,
        addPropertyValueToPropertyStatus: {
          ...state.addPropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.SUCCESS
        },
        addPropertyValueToPropertyError: {
          ...state.addPropertyValueToPropertyError,
          [inventory_property_id]: null
        },
        ingredientPropertiesDetails: propertyDetails
      }
    },
    [AddPropertyValueToPropertyActionTypes.FAILURE]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;

      return {
        ...state,
        addPropertyValueToPropertyStatus: {
          ...state.addPropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.ERROR
        },
        addPropertyValueToPropertyError: {
          ...state.addPropertyValueToPropertyError,
          existing_method_name: action?.payload?.existing_method_name ?? null,
          [inventory_property_id]: action.payload.message
        },
      }
    },
    [AddPropertyValueToPropertyActionTypes.CLEAR]: (state, action: any) => {
      const inventory_property_id = action.payload.inventory_property_id;
      return {
        ...state,
        addPropertyValueToPropertyStatus: {
          ...state.addPropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.INITIAL
        },
        addPropertyValueToPropertyError: {
          ...state.addPropertyValueToPropertyError,
          existing_method_name: null,
          [inventory_property_id]: null
        }
      }
    },

    [UpdatePropertyValueToPropertyActionTypes.REQUEST]: (state, action: any) => {
      const inventory_property_id = action.payload.inventory_property_id;
      return {
        ...state,
        updatePropertyValueToPropertyStatus: {
          ...state.updatePropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.LOADING
        },
        updatePropertyValueToPropertyError: {
          ...state.updatePropertyValueToPropertyError,
          [inventory_property_id]: null
        }
      }
    },
    [UpdatePropertyValueToPropertyActionTypes.SUCCESS]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;
      const propertyValues = action.payload.data;
      const propertyDetails = JSON.parse(JSON.stringify(state.ingredientPropertiesDetails));

      state.ingredientPropertiesDetails.forEach((item: any, index) => {
        if (item.category_id === action.payload.apiPayload.category_id) {
          item.properties.forEach((property: any, propertyIndex: number) => {
            if (property.inventory_property_id === inventory_property_id) {
              propertyDetails[index].properties[propertyIndex].property_values = propertyValues;
            }
          })
        }
      });

      return {
        ...state,
        updatePropertyValueToPropertyStatus: {
          ...state.updatePropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.SUCCESS
        },
        updatePropertyValueToPropertyError: {
          ...state.updatePropertyValueToPropertyError,
          [inventory_property_id]: null
        },
        ingredientPropertiesDetails: propertyDetails
      }
    },
    [UpdatePropertyValueToPropertyActionTypes.FAILURE]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;

      return {
        ...state,
        updatePropertyValueToPropertyStatus: {
          ...state.updatePropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.ERROR
        },
        updatePropertyValueToPropertyError: {
          ...state.updatePropertyValueToPropertyError,
          [inventory_property_id]: action.payload.message
        },
      }
    },
    [UpdatePropertyValueToPropertyActionTypes.CLEAR]: (state, action: any) => {
      const inventory_property_id = action.payload.inventory_property_id;
      return {
        ...state,
        updatePropertyValueToPropertyStatus: {
          ...state.updatePropertyValueToPropertyStatus,
          [inventory_property_id]: AsyncStates.INITIAL
        },
        updatePropertyValueToPropertyError: {
          ...state.updatePropertyValueToPropertyError,
          [inventory_property_id]: null
        }
      }
    },
    [DeleteIngredientPropertyActionTypes.REQUEST]: (state, action: any) => {
      const inventory_property_id = action.payload.inventory_property_id;
      return {
        ...state,
        deleteIngredientPropertyStatus: {
          ...state.deleteIngredientPropertyStatus,
          [inventory_property_id]: AsyncStates.LOADING
        },
        deleteIngredientPropertyError: {
          ...state.deleteIngredientPropertyError,
          [inventory_property_id]: null
        }
      }
    },
    [DeleteIngredientPropertyActionTypes.SUCCESS]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;
      const category_id = action.payload.apiPayload.category_id;

      return {
        ...state,
        deleteIngredientPropertyStatus: {
          ...state.deleteIngredientPropertyStatus,
          [inventory_property_id]: AsyncStates.SUCCESS
        },
        deleteIngredientPropertyError: {
          ...state.deleteIngredientPropertyError,
          [inventory_property_id]: null
        },
        ingredientPropertiesDetails: deleteIngredientProperty(state.ingredientPropertiesDetails, category_id, inventory_property_id),
        getIngredientDetails: {
          ...state.getIngredientDetails,
          data: {
            ...state.getIngredientDetails.data,
            properties: deleteIngredientProperty(state.getIngredientDetails.data.properties, category_id, inventory_property_id),
          }
        }
      }
    },

    [DeleteIngredientPropertyActionTypes.FAILURE]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;
      return {
        ...state,
        deleteIngredientPropertyStatus: {
          ...state.deleteIngredientPropertyStatus,
          [inventory_property_id]: AsyncStates.ERROR
        },
        deleteIngredientPropertyError: {
          ...state.deleteIngredientPropertyError,
          [inventory_property_id]: action.payload.message
        }
      }
    },

    [DeleteIngredientPropertyActionTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        deleteIngredientPropertyStatus: {},
        deleteIngredientPropertyError: {}
      }
    },

    // Delete Ingredient Property Value
    [DeleteIngredientPropertyValueActionTypes.REQUEST]: (state, action: any) => {
      const inventory_property_value_id = action.payload.inventory_property_value_id;
      return {
        ...state,
        deleteIngredientPropertyValueStatus: {
          ...state.deleteIngredientPropertyValueStatus,
          [inventory_property_value_id]: AsyncStates.LOADING
        },
        deleteIngredientPropertyValueError: {
          ...state.deleteIngredientPropertyValueError,
          [inventory_property_value_id]: null
        }
      }
    },
    [DeleteIngredientPropertyValueActionTypes.SUCCESS]: (state, action: any) => {
      const inventory_property_id = action.payload.apiPayload.inventory_property_id;
      const category_id = action.payload.apiPayload.category_id;
      const inventory_property_value_id = action.payload.apiPayload.inventory_property_value_id;
      let propertyDetails = JSON.parse(JSON.stringify(state.ingredientPropertiesDetails));

      state.ingredientPropertiesDetails.forEach((item: any, index) => {
        if (item.category_id === category_id) {
          item.properties.forEach((property: any, propertyIndex: number) => {
            if (property.inventory_property_id === inventory_property_id) {
              propertyDetails[index].properties[propertyIndex].property_values = property.property_values?.filter((propertyValue: any) => propertyValue.inventory_property_value_id !== inventory_property_value_id);
            }
          })
        }
      });

      return {
        ...state,
        deleteIngredientPropertyValueStatus: {
          ...state.deleteIngredientPropertyValueStatus,
          [inventory_property_value_id]: AsyncStates.LOADING
        },
        deleteIngredientPropertyValueError: {
          ...state.deleteIngredientPropertyValueError,
          [inventory_property_value_id]: null
        },
        ingredientPropertiesDetails: propertyDetails
      }
    },
    [DeleteIngredientPropertyValueActionTypes.FAILURE]: (state, action: any) => {
      const inventory_property_value_id = action.payload.inventory_property_value_id;
      return {
        ...state,
        deleteIngredientPropertyValueStatus: {
          ...state.deleteIngredientPropertyValueStatus,
          [inventory_property_value_id]: AsyncStates.ERROR
        },
        deleteIngredientPropertyValueError: {
          ...state.deleteIngredientPropertyValueError,
          [inventory_property_value_id]: action.payload.message
        }
      }
    },
    [DeleteIngredientPropertyValueActionTypes.CLEAR]: (state, action: any) => {
      const inventory_property_value_id = action.payload.inventory_property_value_id;
      return {
        ...state,
        deleteIngredientPropertyValueStatus: {
          ...state.deleteIngredientPropertyValueStatus,
          [inventory_property_value_id]: AsyncStates.INITIAL
        },
        deleteIngredientPropertyValueError: {
          ...state.deleteIngredientPropertyValueError,
          [inventory_property_value_id]: null
        }
      }
    },

    [GetIngredientAttachmentsActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          status: AsyncStates.LOADING,
          message: null
        }
      }
    },
    [GetIngredientAttachmentsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          status: AsyncStates.SUCCESS,
          data: action.payload,
          error: null
        }
      }
    },
    [GetIngredientAttachmentsActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          status: AsyncStates.ERROR,
          error: action.payload
        }
      }
    },
    [GetIngredientAttachmentsActionTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          status: AsyncStates.INITIAL,
          data: [],
          error: null
        }
      }
    },
    [AddIngredientAttachmentActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        addIngredientAttachment: {
          ...state.addIngredientAttachment,
          status: AsyncStates.LOADING,
          message: null
        }
      }
    },
    [AddIngredientAttachmentActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        addIngredientAttachment: {
          ...state.addIngredientAttachment,
          status: AsyncStates.SUCCESS,
          error: null
        },
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          data: [...state.getIngredientAttachments.data, action.payload]
        }
      }
    },
    [AddIngredientAttachmentActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        addIngredientAttachment: {
          ...state.addIngredientAttachment,
          status: AsyncStates.ERROR,
          error: action.payload
        }
      }
    },
    [AddIngredientAttachmentActionTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        addIngredientAttachment: {
          ...state.addIngredientAttachment,
          status: AsyncStates.INITIAL,
          error: null
        }
      }
    },
    [EditIngredientAttachmentActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        editIngredientAttachment: {
          ...state.editIngredientAttachment,
          status: AsyncStates.LOADING,
          message: null
        }
      }
    },
    [EditIngredientAttachmentActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        editIngredientAttachment: {
          ...state.editIngredientAttachment,
          status: AsyncStates.SUCCESS,
          error: null
        },
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          data: state.getIngredientAttachments.data.map((attachment: any) => {
            if (attachment.attachment_id === action.payload.attachment_id) {
              return action.payload
            }
            return attachment
          })

        }
      }
    },
    [EditIngredientAttachmentActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        editIngredientAttachment: {
          ...state.editIngredientAttachment,
          status: AsyncStates.ERROR,
          error: action.payload
        }
      }
    },
    [EditIngredientAttachmentActionTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        editIngredientAttachment: {
          ...state.editIngredientAttachment,
          status: AsyncStates.INITIAL,
          error: null
        }
      }
    },
    [DeleteIngredientAttachmentActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        deleteIngredientAttachment: {
          ...state.deleteIngredientAttachment,
          status: AsyncStates.LOADING,
          message: null
        }
      }
    },
    [DeleteIngredientAttachmentActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteIngredientAttachment: {
          ...state.deleteIngredientAttachment,
          status: AsyncStates.SUCCESS,
          error: null
        },
        getIngredientAttachments: {
          ...state.getIngredientAttachments,
          data: state.getIngredientAttachments.data.filter((attachment: any) => attachment.attachment_id !== action.payload)
        }
      }
    },
    [DeleteIngredientAttachmentActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        deleteIngredientAttachment: {
          ...state.deleteIngredientAttachment,
          status: AsyncStates.ERROR,
          error: action.payload
        }
      }
    },
    [DeleteIngredientAttachmentActionTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        deleteIngredientAttachment: {
          ...state.deleteIngredientAttachment,
          status: AsyncStates.INITIAL,
          error: null
        }
      }
    },

    [GetIngredientGradesActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        ingredientGradesStatus: AsyncStates.LOADING
      }

    },
    [GetIngredientGradesActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        ingredientGradesStatus: AsyncStates.SUCCESS,
        ingredientGrades: action.payload
      }
    },
    [GetIngredientGradesActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        ingredientGradesStatus: AsyncStates.ERROR,
        ingredientGradesError: action.payload
      }
    },
    [GetIngredientGradesActionTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        ingredientGradesStatus: AsyncStates.INITIAL
      }
    },
    [SaveInventoryFiltersTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        savedIngredientFilters: action.payload
      }
    },
    [SaveInventoryFiltersTypes.CLEAR]: (state, action: any) => {
      return {
        ...state,
        savedIngredientFilters: null
      }
    },

    [InventoryPreferencesActionTypes.REQUEST]: (state) => ({
      ...state,
      preferences: {
        ...state.preferences,
        getPreferencesStatus: AsyncStates.LOADING,
        getPreferencesError: null
      },
    }),
    [InventoryPreferencesActionTypes.SUCCESS]: (state, action: any) => {
      const apiResponse = !!Object.keys(action?.payload ?? {}).length ? action?.payload : state?.preferences?.data?.ingredient_table
      return {
        ...state,
        preferences: {
          ...state.preferences,
          getPreferencesStatus: AsyncStates.SUCCESS,
          data: {
            ...state.preferences.data,
            ingredient_table: apiResponse
          }
        },
      }
    },
    [InventoryPreferencesActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      preferences: {
        ...state.preferences,
        getPreferencesStatus: AsyncStates.ERROR,
        getPreferencesError: action.payload,
      },
    }),

    [UpdateInventoryPreferencesActionTypes.REQUEST]: (state, action: any) => ({
      ...state,
      preferences: {
        ...state.preferences,
        updatePreferencesStatus: AsyncStates.LOADING,
        data: {
          ...state.preferences.data,
          ingredient_table: action.payload.ingredient_table
        },
        updatePreferencesError: null
      },
    }),
    [UpdateInventoryPreferencesActionTypes.SUCCESS]: (state, action: any) => {
      const apiResponse = !!Object.keys(action?.payload ?? {}).length ? action?.payload : state?.preferences?.data?.ingredient_table
      return {
        ...state,
        preferences: {
          ...state.preferences,
          updatePreferencesStatus: AsyncStates.SUCCESS,
          data: {
            ...state.preferences.data,
            ingredient_table: apiResponse
          }
        },
      }
    },
    [UpdateInventoryPreferencesActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      preferences: {
        ...state.preferences,
        updatePreferencesStatus: AsyncStates.ERROR,
        updatePreferencesError: action.payload,
      },
    }),
  },
  defaultState
);

export default inventoryV2Reducer;

import { Collapse, Table } from "antd";
import React, { FC, useMemo } from "react";
import useTranslate from "src/utils/useTranslate";
const { Panel } = Collapse;

const PointsTable: FC<any> = ({ data }: any) => {
  const [t] = useTranslate();

  const columns = [
    {
      title: t("sem.mask"),
      dataIndex: "mask",
      key: "mask",
      render: (item: any) => <>{item?.replace("mask_id_", "Mask ")}</>,
      width: "10%",
    },
    {
      title: t("sem.area"),
      dataIndex: "area",
      key: "area",
      width: "10%",
    },
    {
      title: t("sem.height"),
      dataIndex: "height",
      key: "height",
      width: "10%",
    },
    {
      title: t("sem.width"),
      dataIndex: "width",
      key: "width",
      width: "10%",
    },
  ];

  const unit = useMemo(() => {
    return (Object.values(data?.[Object.keys(data)?.[0] || '']?.points || {})?.[0] as any)?.unit;
  }, [data])

  return (
    <Collapse style={{ marginBottom: 20 }}>
      {Object.keys(data).map((key, index) => (
        <Panel header={key} key={index}>
          <Table dataSource={
            [...Object.values(data[key]?.points)?.map((item: any, index: number) => {
              return {
                key: index,
                mask: item?.mask,
                area: <>{item?.area} {item?.unit && <>{item.unit}<sup>2</sup></>}</>,
                height: `${item?.height} ${item?.unit}`,
                width: `${item?.width} ${item?.unit}`,
              };
            }),
            {
              key: "Total",
              mask: "Total",
              area: <>{data[key]?.Total?.total_area} {unit && <>{unit}<sup>2</sup></>}</>,
              height: `${data[key]?.Total?.total_height} ${unit}`,
              width: `${data[key]?.Total?.total_width} ${unit}`,
            },
            {
              key: "Average",
              mask: "Average",
              area: <>{data[key]?.Average?.average_area} {unit && <>{unit}<sup>2</sup></>}</>,
              height: `${data[key]?.Average?.average_height} ${unit}`,
              width: `${data[key]?.Average?.average_width} ${unit}`,
            }
            ]
          }
            columns={columns}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

export default React.memo(PointsTable);

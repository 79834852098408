import { Col, Form, Image, Input, Modal, Row, Select } from "antd";
import { SCALE_UNITS } from "./ScaleUnits";
import { useEffect } from "react";
import { StoreState } from "src/store/configureStore";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";

export default function ReCalibrateModal({
  reCalibrateModalOpen,
  setReCalibrateModalOpen,
  handleReCalibrate,
  selectedBatchInfo
}: any) {
  const [t] = useTranslate();
  const [form] = Form.useForm();
  const { paintFilmRecalibrateScaleStatus, fiberRecalibrateScaleStatus } = useSelector((state: StoreState) => state.semAnalysis);

  useEffect(() => {
    if (selectedBatchInfo) {
      form.setFieldValue("scale", selectedBatchInfo?.scale?.scale);
      form.setFieldValue("unit", selectedBatchInfo?.scale?.unit);
    }
  }, [selectedBatchInfo, form]);

  useEffect(() => {
    if ([paintFilmRecalibrateScaleStatus, fiberRecalibrateScaleStatus].includes(AsyncStates.SUCCESS)) setReCalibrateModalOpen(false);
  }, [paintFilmRecalibrateScaleStatus, fiberRecalibrateScaleStatus, setReCalibrateModalOpen]);

  const handleOk = () => {
    const values = form.getFieldsValue();
    if (values["scale"] || values["unit"])
      handleReCalibrate(values["scale"], values["unit"]);
  };

  return (
    <Modal
      title={t("sem.calibrate")}
      open={reCalibrateModalOpen}
      width={600}
      onCancel={() => setReCalibrateModalOpen(false)}
      onOk={handleOk}
      okButtonProps={{ loading: [paintFilmRecalibrateScaleStatus, fiberRecalibrateScaleStatus].includes(AsyncStates.LOADING) }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Image
              width={250}
              src={selectedBatchInfo?.scale_image_url}
            />
          </Col>
          <Col span={12}>
            <div style={{ paddingBottom: "8px" }}><b>{t("sem.scaleDetection")}:</b> {`${selectedBatchInfo?.scale?.scale} ${selectedBatchInfo?.scale?.unit} (${Math.round(selectedBatchInfo?.scale?.scale/selectedBatchInfo?.scale?.value)} px)`}</div>
            <Form.Item label={t("sem.scaleLength")} name="scale">
              <Input type="number" placeholder="0" />
            </Form.Item>
            <Form.Item label={t("common.unit")} name="unit">
              <Select
                placeholder={t("common.selectAUnit")}
                options={SCALE_UNITS.map((unit) => ({
                  label: unit,
                  value: unit,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
